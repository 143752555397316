import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import VisuallyHidden from "@reach/visually-hidden";
import { Button, CancelButton } from "components/Button";
import { FormModalInput } from "components/Input";
import { FormProvider } from "react-hook-form";

const EditProfileDetails = ({
	showDialog,
	onSubmit,
	closeEditDetails,
	data,
	methods,
}) => {
	return (
		<Dialog
			isOpen={showDialog}
			onDismiss={closeEditDetails}
			aria-label="Edit Personal details modal"
		>
			<div className="flex justify-end ">
				<button className="close-button" onClick={closeEditDetails}>
					<VisuallyHidden>Close</VisuallyHidden>
					<span aria-hidden>×</span>
				</button>
			</div>
			<h2 className="text-center h3">Edit Details</h2>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>
					<FormModalInput
						title="First Name"
						inputName="firstName"
						defaultValue={data.firstName}
						placeholder="eg: John"
						required
					/>

					<FormModalInput
						title="Last Name"
						inputName="lastName"
						defaultValue={data.lastName}
						placeholder="eg: Doe"
						required
					/>

					<FormModalInput
						title="Email"
						inputName="email"
						placeholder="eg: john@exapmle.com"
						defaultValue={data.email}
						disabled
					/>
					<FormModalInput
						title="Phone Number"
						inputName="mobileNumber"
						defaultValue={data.mobileNumber}
						placeholder="+17895662666"
						inputType="number"
					/>

					<CancelButton onClick={closeEditDetails}>Cancel</CancelButton>
					<Button type="submit">Submit</Button>
				</form>
			</FormProvider>
		</Dialog>
	);
};

export default EditProfileDetails;
