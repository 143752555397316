import { Button } from "components/Button";
import { Input, Textarea } from "components/Input";
import { errorToast } from "helpers/Toast";
import {
	addBuyYourselfToOrder,
	createBuyYourself,
	orderPut,
} from "queries/orderQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { merchantDetails } from "utils/data";

const BuyYourselfAddItemInputs = () => {
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		watch,
	} = useForm();
	const [hasItem, setHasItem] = useState(false);

	const { orderId } = useParams();
	// const location = useLocation();
	const navigate = useNavigate();
	// console.log("location.state");
	// console.log(location.state);
	const queryClient = useQueryClient();

	// Draft mentioned in query keys is status which is sent as url parameter

	const watchMerchant = watch("merchant");

	// const resetHandler = () => {
	//   reset();
	// };

	const mutationSuccessHandler = (data) => {
		console.log(data);
		reset();

		queryClient.invalidateQueries("orders");
		queryClient.invalidateQueries("allOrders");

		navigate(`/user/buy-yourself/${data._id}`);
		// setAddItem(false);
	};

	const createBuyYourselfOrderMutation = useMutation(createBuyYourself, {
		onSuccess: (data) => {
			mutationSuccessHandler(data);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	const addBuyYourselfToOrdermutation = useMutation(addBuyYourselfToOrder, {
		onSuccess: (data) => {
			mutationSuccessHandler(data);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	const convertToWeBuyMutation = useMutation(orderPut, {
		onSuccess: (data) => {
			reset();

			queryClient.invalidateQueries("orders");
			queryClient.invalidateQueries("allOrders");

			navigate(`/user/we-buy-4-u/${data._id}`);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	const isNewOrder = orderId === null || orderId === undefined ? true : false;
	// let existingOrderData = data && data.filter((item) => !item.isMedicine);
	const onSubmitHandler = (formData) => {
		isNewOrder
			? (formData["orderType"] = "buyYourself")
			: (formData["orderId"] = orderId);
		formData["itemType"] = "default";

		isNewOrder
			? createBuyYourselfOrderMutation.mutate(formData)
			: addBuyYourselfToOrdermutation.mutate({
					data: { ...formData },
					query: "add-new-buy-yourself",
			  });
	};

	const onSubmit = async (formData) => {
		console.log("formData");
		console.log(formData);
		// tertiary function to facilitate push and put request based on condition
		formData = { ...formData };
		onSubmitHandler(formData);
	};

	const convertToWeBuyButtonHandler = () => {
		reset();
		orderId === null || orderId === undefined
			? navigate("/user/we-buy-4-u")
			: convertToWeBuyMutation.mutate({
					data: { orderId, orderType: "weBuy" },
					query: "convert-to-we-buy",
			  });
	};

	return (
		<div className=" flex flex-col justify-start">
			<h3 className="h3 mb-4 inline-block">Buy Yourself</h3>
			<div className="inline-block">
				<div className="flex flex-col-reverse lg:flex-row justify-start bg-teal-100 py-8 lg:flex-wrap-reverse mr-4 flex-shrink scrollbar">
					<div>
						<form onSubmit={handleSubmit(onSubmit)} className="ml-4">
							<div className="flex flex-wrap ">
								<div className="flex flex-col mr-4 mb-2 ">
									<div className="text-xs mt-2">Select your Online Store</div>
									<select
										{...register("merchant", {
											required: {
												value: true,
												message: "This field is required",
											},
										})}
										className="px-2 lg:px-4 border-2 bg-blue-100 rounded-md py-1 min-w-[100px]"
									>
										<option value="">Select Store</option>
										{merchantDetails.map((item) => (
											<option value={item.name} key={item.id}>
												{item.name}
											</option>
										))}
										<option value="others">Others</option>
									</select>

									{errors.merchant && (
										<span className="text-red-500">
											{errors.merchant.message}
										</span>
									)}
									{merchantDetails.find(
										(item) => item.name === watchMerchant
									) && (
										<p className="text-xs">
											<a
												href={
													merchantDetails.find(
														(item) => item.name === watchMerchant
													).url
												}
												target="blank"
												className="text-cyan-500 underline text-xs"
											>
												Click here
											</a>{" "}
											to visit{" "}
											<span className="capitalize text-xs">
												{watchMerchant}
											</span>
										</p>
									)}
								</div>
								<div className="flex flex-col mr-4 flex-grow">
									<Input
										title="Product URL/Link"
										inputName="productURL"
										placeholder="eg: https://www.amazon.in/robert-ludloom-7520"
										register={register}
										errors={errors}
										required
										maxLength={2250}
									/>
								</div>
								<div className="flex flex-col mr-4 flex-grow">
									<Input
										className="min-w-[80px] lg:min-w-[80px] "
										title="Quantity"
										inputName="quantity"
										placeholder="eg:50"
										register={register}
										errors={errors}
										inputType="number"
										required
										max={1000}
										min={1}
									/>
								</div>
							</div>
							{watchMerchant === "others" && (
								<div className="flex flex-wrap ">
									<div className="mr-4 flex-grow">
										<Input
											title="Online Store Name"
											inputName="otherMerchantName"
											placeholder="eg: firstCry"
											register={register}
											errors={errors}
											required
											maxLength={100}
										/>
									</div>
								</div>
							)}
							<div className="flex flex-wrap ">
								<div className="mr-4 flex-grow">
									<Input
										title="Product Name"
										inputName="name"
										placeholder="eg: Books"
										register={register}
										errors={errors}
										required
										maxLength={1000}
									/>
								</div>

								<div className="mr-4 flex-grow">
									<Input
										title="Tracking Id"
										inputName="trackingId"
										register={register}
										errors={errors}
										required
									/>
								</div>
							</div>
							<div className="flex flex-wrap ">
								<div className="mr-4 flex-grow">
									<Textarea
										title="Description"
										inputName="description"
										register={register}
										errors={errors}
										maxLength={4000}
									/>
								</div>

								<div className="flex flex-col flex-grow">
									<Input
										title="Invoices"
										inputName="invoiceImages"
										inputType="file"
										register={register}
										errors={errors}
										multiple
										required
									/>
								</div>
							</div>
							<div className="flex flex-wrap "></div>

							<div className="flex">
								{/* <CancelButton
          className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-4"
          onClick={() => {
            reset();
            setSelectedItemType("");
            setAddItem(false);
          }}
        >
          cancel
        </CancelButton> */}

								<Button
									type="submit"
									disabled={
										createBuyYourselfOrderMutation.isLoading ||
										addBuyYourselfToOrdermutation.isLoading
									}
								>
									{createBuyYourselfOrderMutation.isLoading ||
									addBuyYourselfToOrdermutation.isLoading
										? "Processing..."
										: hasItem === true
										? "Add More Items"
										: "Add Item"}
								</Button>
							</div>
						</form>
						<Outlet context={[hasItem, setHasItem]} />
					</div>
					<div className="xl:max-w-[350px] mx-4 flex flex-row flex-wrap md:flex-nowrap xl:flex-col">
						<div>
							<div className="bg-[#B7D9E2] text-black p-4 mb-6 mr-4 min-w-[200px]">
								<p className="mb-4">
									Avail mulitple benifits with our services{" "}
								</p>
								<Button onClick={convertToWeBuyButtonHandler}>
									Switch to We Buy 4 U
								</Button>
							</div>
						</div>
						<div className="bg-[#B7D9E2] text-black p-4 mb-4 mr-4">
							<h4 className="font-bold text-xl">Warehouse Address</h4>

							<span>
								<strong>Name: </strong>
							</span>
							<span>Neethu S</span>
							<br />
							<span>
								<strong>Address Line 1 :</strong>
							</span>
							<span>C-1804, BRIGADE MEADOWS</span>
							<br />
							<span>
								<strong>Address Line 2 :</strong>
							</span>
							<span>KANAKAPURA ROAD</span>
							<br />
							<span>
								<strong>City :</strong>
							</span>
							<span>Bengaluru - 560082,</span>
							<br />
							<span>
								<strong>State :</strong>
							</span>
							<span>Karnataka</span>
							<br />
							<span>
								<strong>Country :</strong>
							</span>
							<span>India</span>
							<br />
							<span>
								<strong>Phone :</strong>
							</span>
							<span>+9198745632251</span>
							<br />
							<span>
								* The above number is only for logistics and courier. No
								enquires will be entertained.
							</span>
						</div>
					</div>
				</div>
				{/* <div>
					<Outlet />
				</div> */}
			</div>
		</div>
	);
};

export default BuyYourselfAddItemInputs;
