import { errorToast, successToast } from "helpers/Toast";
import { putEditDetails } from "queries/userQueries";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

const useEditProflieDetails = () => {
	const [showDialog, setShowDialog] = React.useState(false);
	const queryClient = useQueryClient();
	const methods = useForm();
	const openEditDetails = () => {
		setShowDialog(true);
	};

	const closeEditDetails = () => {
		methods.reset();
		setShowDialog(false);
	};

	const editDetails = useMutation(putEditDetails, {
		onSuccess: (data) => {
			queryClient.invalidateQueries("user");
			methods.reset();
			closeEditDetails();
			successToast("Successfully updated details");
		},
		onError: (error) => {
			console.error("edit details", error);
			errorToast("OOPS! Something went wrong!!");
		},
	});
	const onSubmit = (formData) => {
		console.log(formData);
		editDetails.mutate(formData);
	};
	return {
		showDialog,
		openEditDetails,
		closeEditDetails,
		onSubmit,
		methods,
	};
};

export default useEditProflieDetails;
