import { useFormContext } from "react-hook-form";
const Input = ({
	title,
	inputName,
	inputType,
	defaultValue,
	placeholder,
	required,
	register,
	errors,
	max,
	maxMessage,
	isEmail,
	maxLength,
	maxLengthMessage,
	min,
	methods,
	select,
	options,
	minLength,
	multiple,
	autoComplete,
	value,
	disabled,
	validate,
	zIndex,
	className = "",
}) => {
	const validatitonInput = {};
	const today = new Date();
	const oneYearFromNow = new Date();
	oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
	if (required) {
		validatitonInput.required = {
			value: true,
			message: "This field is required",
		};
	}
	if (max > 0) {
		validatitonInput.max = {
			value: max,
			message: maxMessage || `The value cannot be greater than ${max}`,
		};
	}
	if (inputType === "date") {
		validatitonInput.max = {
			value: oneYearFromNow,
			message: "can not add future date of more than an year",
		};
	}
	if (maxLength > 0) {
		validatitonInput.maxLength = {
			value: maxLength,
			message:
				maxLengthMessage || `The maximum characters allowed is ${maxLength}`,
		};
	}
	if (minLength) {
		validatitonInput.minLength = {
			value: minLength,
			message: `The minimum characters allowed is ${minLength}`,
		};
	}
	if (min) {
		validatitonInput.min = {
			value: inputType === "date" ? today : 1,
			message:
				inputType === "date"
					? "The date cannot be lesser than today"
					: "The value cannot be lesser than 1",
		};
	}
	if (inputType === "date") {
		validatitonInput.valueAsDate = true;
	}
	if (isEmail) {
		validatitonInput.pattern = {
			value: /\S+@\S+\.\S+/,
			message: "Enter a valid email",
		};
	}
	if (validate) {
		validatitonInput.validate = validate;
	}
	// console.log("optionst in input is:", inputName, options);
	return (
		<>
			<label htmlFor={inputName} className="text-left mt-2 text-xs">
				{title}
			</label>
			{select ? (
				<select
					className={`input ${className}`}
					{...register(inputName, validatitonInput)}
				>
					<option disabled selected className="text-gray" value="">
						Select a country
					</option>
					{options.map((item) => (
						<option value={item.value} key={item.value}>
							{item.label}
						</option>
					))}
				</select>
			) : (
				<input
					className={
						inputType === "file"
							? `border-none mt-2 ${zIndex && "z-[60]"} ${className}`
							: `input ${className}`
					}
					type={inputType || "text"}
					multiple={multiple}
					value={value}
					disabled={disabled}
					autoComplete={autoComplete || ""}
					accept={inputType === "file" ? "image/png, image/jpeg,image/jpg" : ""}
					defaultValue={defaultValue || ""}
					placeholder={placeholder || `Enter ${title}`}
					{...register(inputName, validatitonInput)}
				/>
			)}
			{errors[`${inputName}`] && (
				<span className="text-red-500">{errors[`${inputName}`].message}</span>
			)}
		</>
	);
};

const FormModalInput = ({
	title,
	inputName,
	inputType,
	defaultValue,
	placeholder,
	required,
	max,
	maxMessage,
	isEmail,
	maxLength,
	maxLengthMessage,
	minLength,
	min,
	multiple,
	autoComplete,
	value,
	disabled,
	validate,
	zIndex,
	className = "",
}) => {
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const validatitonInput = {};
	const today = new Date();
	const oneYearFromNow = new Date();
	oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
	if (required) {
		validatitonInput.required = {
			value: true,
			message: "This field is required",
		};
	}
	if (max > 0) {
		validatitonInput.max = {
			value: max,
			message: maxMessage || `The value cannot be greater than ${max}`,
		};
	}
	if (inputType === "date") {
		validatitonInput.max = {
			value: oneYearFromNow,
			message: "can not add future date of more than an year",
		};
	}
	if (maxLength > 0) {
		validatitonInput.maxLength = {
			value: maxLength,
			message:
				maxLengthMessage || `The maximum characters allowed is ${maxLength}`,
		};
	}
	if (minLength) {
		validatitonInput.minLength = {
			value: minLength,
			message: `The minimum characters allowed is ${minLength}`,
		};
	}
	if (min) {
		validatitonInput.min = {
			value: inputType === "date" ? today : 1,
			message:
				inputType === "date"
					? "The date cannot be lesser than today"
					: "The value cannot be lesser than 1",
		};
	}
	if (inputType === "date") {
		validatitonInput.valueAsDate = true;
	}
	if (isEmail) {
		validatitonInput.pattern = {
			value: /\S+@\S+\.\S+/,
			message: "Enter a valid email",
		};
	}
	if (validate) {
		validatitonInput.validate = validate;
	}
	return (
		<div>
			<label htmlFor={inputName} className="text-left text-xs">
				{title}
			</label>
			<input
				className={
					inputType === "file"
						? `border-none mt-6 ${zIndex && "z-[60]"}`
						: `input  ${className}`
				}
				type={inputType || "text"}
				multiple={multiple}
				value={value}
				disabled={disabled}
				autoComplete={autoComplete || ""}
				accept={inputType === "file" ? "image/png, image/jpeg,image/jpg" : ""}
				defaultValue={defaultValue || ""}
				placeholder={placeholder || `Enter ${title}`}
				{...register(inputName, validatitonInput)}
			/>
			{errors[`${inputName}`] && (
				<span className="text-red-500">{errors[`${inputName}`].message}</span>
			)}
		</div>
	);
};

const Textarea = ({
	title,
	inputName,
	className = "",
	inputType,
	defaultValue,
	placeholder,
	required,
	register,
	errors,
	max,
	maxMessage,
	maxLength,
	maxLengthMessage,
	min,
	rows,
}) => {
	const validatitonInput = {};
	if (required) {
		validatitonInput.required = {
			value: true,
			message: "This field is required",
		};
	}
	if (max > 0) {
		validatitonInput.max = {
			value: max,
			message: maxMessage || `The value cannot be greater than ${max}`,
		};
	}
	if (maxLength > 0) {
		validatitonInput.maxLength = {
			value: maxLength,
			message:
				maxLengthMessage || `The maximum characters allowed is ${maxLength}`,
		};
	}
	if (min) {
		validatitonInput.min = {
			value: 1,
			message: "The value cannot be lesser than 1",
		};
	}

	return (
		<>
			<label htmlFor={inputName} className="text-left text-xs">
				{title}
			</label>
			<textarea
				className={`textarea ${className}`}
				type={inputType || "text"}
				rows={rows || "3"}
				defaultValue={defaultValue || ""}
				placeholder={placeholder || `Enter ${title}`}
				{...register(inputName, validatitonInput)}
			/>
			{errors[`${inputName}`] && (
				<span className="text-red-500">{errors[`${inputName}`].message}</span>
			)}
		</>
	);
};

export { Input, Textarea, FormModalInput };
