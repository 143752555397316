import {
	userAckonwledgedReviewed,
	userLogisticsPayment,
} from "queries/orderQueries";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Button } from "components/Button";
import Loading from "components/Loading";
import { successToast } from "helpers/Toast";
import { verifyPaymentIntent } from "queries/userQueries";

export default function PaymentsHandlerWrapper() {
	const { orderId, type } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const redirect_status = searchParams.get("redirect_status");
	const payment_intent_client_secret = searchParams.get(
		"payment_intent_client_secret"
	);
	const payment_intent = searchParams.get("payment_intent");
	console.log(orderId);
	const queryData = {
		paymentClientSecret: payment_intent_client_secret,
		paymentIntent: payment_intent,
		orderId,
		type,
	};
	const { data, isLoading, isError, error } = useQuery(
		["verifyPayment", queryData],
		() => verifyPaymentIntent(queryData),
		{
			onSuccess: (data) => {
				console.log(data);
				if (data.status === "succeeded" && type === "shipping") {
					userAcknowledgedMutation.mutate(orderId);
				} else if (data.status === "succeeded" && type === "cost") {
					const itemData = {
						orderId,
						orderCost: data.orderCost,
					};
					userAcknowledgedReviewedMutation.mutate(itemData);
				}
			},
		}
	);

	const userAcknowledgedReviewedMutation = useMutation(
		userAckonwledgedReviewed,
		{
			onSuccess: (data) => {
				console.log(data);

				successToast(
					"Successfully completed Payment. The items will be procured soon"
				);
				queryClient.invalidateQueries("allOrders");
				queryClient.invalidateQueries("orders");
			},
			onError: (error) => {
				console.log(error);
			},
		}
	);

	const userAcknowledgedMutation = useMutation(userLogisticsPayment, {
		onSuccess: (data) => {
			console.log(data);
			successToast(
				"Successfully completed Payment. The items will be Shipped soon"
			);
		},
		onError: (error) => {
			console.log(error);
		},
	});
	if (isLoading) {
		return <Loading />;
	}
	if (data.status === "succeeded") {
		return (
			<div className="h-screen">
				<div className="bg-white p-6  md:mx-auto">
					<svg
						viewBox="0 0 24 24"
						className="text-green-600 w-16 h-16 mx-auto my-6"
					>
						<path
							fill="currentColor"
							d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
						></path>
					</svg>
					<div className="text-center">
						<h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
							Payment Done!
						</h3>
						<p className="text-gray-600 my-2">
							Thank you for completing your secure online payment.
						</p>
						<p> Have a great day! </p>
						<div className="py-10 text-center">
							<Button
								className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
								onClick={() => navigate("/user/orders")}
							>
								GO BACK
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="h-screen">
			<div className="bg-white p-6  md:mx-auto">
				<div className="text-center">
					<h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
						OOPS Something went wrong!
					</h3>
					<p className="text-gray-600 my-2">
						We could not process your payment.
					</p>

					<div className="py-10 text-center">
						<a
							href="#"
							className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
						>
							GO BACK
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
