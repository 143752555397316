import Loading from "components/Loading";
import { successToast } from "helpers/Toast";
import {
	adminConfirmedDelivered,
	adminConfirmReviewed,
	getOrderHistory,
	userAckonwledgedReviewed,
} from "queries/orderQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import OrdersTable from "./orders/OrdersTable";

export default function OrderHistory() {
	const {
		handleSubmit,
		formState: { errors },
		register,
		reset,
	} = useForm();
	const { data, isLoading, isError, error } = useQuery(
		"history",
		getOrderHistory
	);

	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}

	return (
		<>
			<h1 class=" h3 font-bold  mb-10">Order History</h1>

			<div>
				{/* delete Modal */}

				{data.length !== 0 ? (
					<div className="mb-4">
						<h2 className="h3 mt-4 uppercase">Orders In Progress</h2>
						<OrdersTable data={data} completed />
					</div>
				) : (
					<div className="">No Previous Orders</div>
				)}
			</div>
		</>
	);
}
