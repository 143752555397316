import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";

export async function login({ data }) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			username: data.email,
			password: data.password,
		}),
	};

	const res = await fetch(`${baseURL}/auth/login`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);

	return resjson;
}

export async function googleLogin(data) {
	console.log("data");

	console.log(data);
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/auth/google`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}
export async function authSignUp({ data }) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			password: data.password,
		}),
	};

	const res = await fetch(`${baseURL}/auth/register`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function refresh({ refreshToken }) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			refreshToken,
		}),
	};

	const res = await fetch(`${baseURL}/auth/refresh-token`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function forgotpassword({ data }) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			email: data.email,
		}),
	};

	const res = await fetch(`${baseURL}/auth/forgotpassword`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function resetPasswordByEmail({ data }) {
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			email: data.email,
			resetPasswordToken: data.resetPasswordToken,
			password: data.password,
		}),
	};

	const res = await fetch(
		`${baseURL}/auth/updatepasswordbyemail`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}
