import Loading from "components/Loading";
import DraftOrderActionButtons from "components/orderItems/DraftOrderActionButtons";
import UserDeleteButton from "components/UserDeleteButton";
import { getSingleOrder } from "queries/orderQueries";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

const MedicineDraftPages = () => {
	const { orderId } = useParams();

	const navigate = useNavigate();

	const { data, isLoading, isError, error } = useQuery(
		["orders", orderId],
		() => getSingleOrder(orderId)
	);

	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}
	if (data === null) {
		return navigate("/user/orders");
	}
	return (
		<div className="flex justify-start bg-teal-100 pl-4 pb-4">
			{data.orderStatus === "draft" && data.orderType === "medicine" ? (
				<div className="max-w-[90%] overflow-x-auto ">
					<table className="block overflow-x-scroll whitespace-nowrap mt-4">
						<thead>
							<tr>
								<th className="px-2 text-center">Sl No</th>
								<th className="px-2 text-center">Name</th>

								<th className="px-2 text-center">Description</th>

								<th className="px-2 text-center">Actions</th>
							</tr>
						</thead>
						<tbody>
							{data.items?.map((item, index) => (
								<tr>
									<td className="px-2 text-center">{index + 1}</td>
									<td className="px-2 text-center">{item.name}</td>

									<td className="px-2 text-center max-w-[100px]">
										{item.description}
									</td>

									<td className="px-2 text-center">
										<UserDeleteButton order={data} itemId={item._id} />
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{/* <div className="mt-6">
            <Button>Add In House Products</Button>
          </div> */}
					<div className="flex flex-wrap mt-6">
						<DraftOrderActionButtons order={data} />
					</div>
				</div>
			) : (
				navigate("/user/medicine")
			)}
		</div>
	);
};

export default MedicineDraftPages;
