import Loading from "components/Loading";
import { getCountriesList } from "queries/countryQueries";
import React from "react";
import { useQuery } from "react-query";

function CountriesList() {
	const { data, isLoading, isError, error } = useQuery(
		"countryList",
		getCountriesList,
		{
			onSuccess: (data) => console.log("country list data is:", data),
			onError: (error) => console.log("error of country list data is :", error),
		}
	);
	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		return <div>Error has occured in getting response: {error.message} </div>;
	}
	return (
		<div>
			<h1 className="text-center font-bold text-4xl mb-10">Countries List</h1>
			<div>
				{data.map((item, index) => (
					<div key={index}>
						<div>
							{item.Destination} : {item.Logistics}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default CountriesList;
