import { Link } from "react-router-dom";

export default function Footer() {
	return (
		<div className="footer-colors">
			<div className="flex flex-col justify-between container mx-auto pt-20  px-12 md:flex-row lg:px-24">
				<div className="flex flex-col gap-6 mb-6">
					<h3 className="font-bold text-4xl mb-4">4inExpo</h3>
					<p>
						<a href="tel:+918891909071" className="">
							+918891909071
						</a>
					</p>
					<p>
						<a href="mailto:sales.4inExpo@gmail.com" className="">
							sales.4inExpo@gmail.com
						</a>
					</p>
				</div>
				<div className="flex flex-col gap-6">
					<h3 className="font-bold text-2xl mb-4">Quick Links</h3>
					<div className="flex flex-col flex-wrap">
						<Link to="/terms" className="mb-6">
							Terms and Conditions
						</Link>
						<Link to="/prohibited-items">Prohibited Items</Link>
					</div>
				</div>
			</div>
			<hr className="container h-[2px] bg-gray-500 my-10 mx-auto" />
			<p className="text-center pb-10">
				All Rights Reserved. © 4inExpo a subsidery of 360.AI
			</p>
		</div>
	);
}
