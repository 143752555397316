import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import useChangePassword from "components/hooks/useChangePassword";
import useEditProfileDetails from "components/hooks/useEditProfileDetails";
import Loading from "components/Loading";
import ChangePassword from "components/profile/ChangePassword";
import EditProfileDetails from "components/profile/EditProfileDetails";
import { getUser } from "queries/userQueries";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function Profile() {
	const {
		showDialog,
		openEditDetails,
		closeEditDetails,
		onSubmit,
		methods: editMethods,
		openEditAddress,
	} = useEditProfileDetails();

	const {
		showPasswordDialog,
		openPasswordDetails,
		closePasswordDetails,
		onPasswordSubmit,
		methods,
	} = useChangePassword();
	const navigate = useNavigate();

	const { isLoading, isError, data } = useQuery("user", getUser);

	console.log(data);

	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		return <div>Oops! Something went wrong!!!</div>;
	}
	return (
		<div className="flex flex-col ">
			<h1 className="h3  mb-12">My Profile</h1>
			<div className="flex items-center flex-col sm:flex-row">
				<div className="rounded-full h-24 w-24 bg-[#0A2B42] text-[#F0FEFC] flex justify-center items-center mr-6 mb-4">
					<p className="uppercase">
						{data.firstName?.charAt(0)}
						{data.lastName?.charAt(0)}
					</p>
				</div>
				<div>
					<div className="mb-4">
						Name: {data.firstName} {data.lastName}
					</div>
					<div className="mb-4">Email:{data.email}</div>
				</div>
			</div>
			<div className="flex flex-wrap gap-3">
				<div>
					<div className="underline mt-4 mb-2 font-bold">Shipping Address</div>
					<div className="mb-4">
						Recipient Name: {data.shippingAddress.name}
					</div>
					<div className="mb-4">
						Address:{data.shippingAddress.addressline1},
						{data.shippingAddress.addressline2},{data.shippingAddress.city},
						{data.shippingAddress.state},{data.shippingAddress.country}
					</div>
					<div className="mb-4">
						Pincode/Zipcode:{data.shippingAddress.pincode}
					</div>
					<div className="mb-4">Phone: {data.shippingAddress.telephone}</div>
				</div>

				<div>
					<div className="underline mt-4 mb-2 font-bold">Indian Address</div>
					<div className="mb-4">Recipient Name: {data.localAddress.name}</div>
					<div className="mb-4">
						Address:{data.localAddress.addressline1},
						{data.localAddress.addressline2},{data.localAddress.city},
						{data.localAddress.state},{data.localAddress.country}
					</div>
					<div className="mb-4">
						Pincode/Zipcode:{data.localAddress.pincode}
					</div>
					<div className="mb-4">Phone: {data.localAddress.telephone}</div>
				</div>
			</div>
			<div className="flex  mt-6">
				<Button onClick={openEditDetails}>Edit Details</Button>
				<Button onClick={() => navigate("/edit-profile-address")}>
					Edit Address
				</Button>
				<Button onClick={openPasswordDetails}>Change Password</Button>
			</div>
			<EditProfileDetails
				showDialog={showDialog}
				closeEditDetails={closeEditDetails}
				onSubmit={onSubmit}
				data={data}
				methods={editMethods}
			/>
			<ChangePassword
				showPasswordDialog={showPasswordDialog}
				closePasswordDetails={closePasswordDetails}
				onPasswordSubmit={onPasswordSubmit}
				methods={methods}
			/>
		</div>
	);
}
