import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import VisuallyHidden from "@reach/visually-hidden";
import { SignUpButton } from "components/Button";
import { Input, Textarea } from "components/Input";
import { contactus } from "queries/miscellaneousQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiMessageRoundedDots } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { useMutation } from "react-query";

export default function ContactUs() {
	const [activeState, setActiveState] = useState(1);
	const [callbackForm, setCallbackForm] = useState(false);
	const open = () => setCallbackForm(true);
	const close = () => {
		reset();
		setCallbackForm(false);
	};
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
	} = useForm();

	const mutation = useMutation(contactus, {
		onSuccess: (data) => {
			console.log("successfully sent the mail ", data);
			close();
		},
		onError: (error) => console.log("error", error),
	});

	const onSubmit = (formData) => {
		console.log("test form data", formData);
		mutation.mutate(formData);
	};
	return (
		<section className="max-w-[1920] mx-auto my-24">
			<h2 className="contact-us-heading text-center h2">Connect with us</h2>
			<div className="flex flex-col lg:flex-row gap-4 lg:gap-2 mt-8 px-12 md:mt-12 justify-center items-center">
				<div
					className={`contact-us-card-colors p-4 flex flex-col justify-center ${
						activeState === 0 && "contact-us-card-active-state"
					}`}
					onMouseEnter={(e) => {
						e.preventDefault();
						setActiveState(0);
					}}
				>
					<h3 className="font-bold text-center text-xl">Request a callback</h3>
					<p className="mt-8 text-center">
						Happy to support you over a call, Thank you very much.Have a great
						day !
					</p>
					<div className="flex justify-center">
						<button
							className="contact-us-button-colors py-2 px-8 mt-8 rounded-full"
							onClick={open}
						>
							Click Here
						</button>

						<Dialog
							isOpen={callbackForm}
							onDismiss={close}
							className="container max-w-[600px]"
							aria-label="Read More Information"
						>
							<div className="flex">
								<button className="close-button ml-auto" onClick={close}>
									<VisuallyHidden>Close</VisuallyHidden>
									<span aria-hidden>×</span>
								</button>
							</div>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<Input
										title="Name"
										className="min-w-[150px]"
										inputName="name"
										placeholder="Enter your name"
										register={register}
										errors={errors}
										required
									/>
								</div>
								<div>
									<Input
										title="Email"
										inputName="email"
										className="min-w-[150px]"
										placeholder="Enter your email"
										register={register}
										errors={errors}
										isEmail
										required
									/>{" "}
								</div>
								<div>
									<Input
										title="Phone Number/Mobile"
										inputName="mobile"
										className="min-w-[150px]"
										placeholder="your contact number"
										inputType="number"
										register={register}
										errors={errors}
										required
										maxLength={100}
									/>{" "}
								</div>
								<div>
									<Textarea
										title="Message"
										className="min-w-[150px]"
										inputName="message"
										placeholder="What's on your mind?'"
										inputType="number"
										register={register}
										errors={errors}
									/>
								</div>
								<SignUpButton type="submit">submit</SignUpButton>
							</form>
						</Dialog>
					</div>
				</div>
				<div
					className={`contact-us-card-colors p-4 flex flex-col justify-center ${
						activeState === 1 && "contact-us-card-active-state"
					}`}
					onMouseEnter={(e) => {
						e.preventDefault();
						setActiveState(1);
					}}
				>
					<h3 className="font-bold text-center text-xl">Connect on Whatsapp</h3>
					<div className="flex justify-center mt-8">
						<div className="flex flex-col mr-4">
							<div className="flex items-center">
								<BiMessageRoundedDots />
								<p className="ml-2">Live Chat</p>
							</div>

							<div className="flex items-center">
								<FaWhatsapp />
								<p className="ml-2">+918891909071</p>
							</div>
						</div>
						<div className="h-12 w-[2px]  bg-[#ffffff] mr-4" />
						<div>
							<p>Mon-Fri</p>
							<p>10AM - 6PM</p>
						</div>
					</div>

					<div className="flex justify-center">
						<a
							href="https://wa.me/+918891909071"
							target="blank"
							className="contact-us-button-colors py-4 px-8 mt-8 rounded-full hover:contact-us-button-colors"
						>
							Click Here
						</a>
					</div>
				</div>
				<div
					className={`contact-us-card-colors p-4 flex flex-col justify-center ${
						activeState === 2 && "contact-us-card-active-state"
					}`}
					onMouseEnter={(e) => {
						e.preventDefault();
						setActiveState(2);
					}}
				>
					<h3 className="font-bold text-center text-xl">Talk to us</h3>
					<p className="mt-8">
						Call us on <a href="tel:+918891909071" className="">
							+918891909071
						</a>
							 <br /> or email us
					</p>
					<div className="flex justify-center">
						<a
							href="mailto:support@4inExpo.com"
							className="contact-us-button-colors py-4 px-8 mt-8 rounded-full hover:contact-us-button-colors "
						>
							support@4inExpo.com
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}
