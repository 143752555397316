import Loading from "components/Loading";
import ReadyToShipActionButtons from "components/orderItems/ReadyToShipActionButtons";
import ReviewOrderActionButtons from "components/orderItems/ReviewOrderActionButtons";
import { getSingleOrder } from "queries/orderQueries";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import OrderActionsTable from "./OrderActionsTable";
import LeftArrow from "assets/arrow-left-solid.svg";

const OrderActions = () => {
	const { orderId } = useParams();
	const navigate = useNavigate();
	const { data, isLoading, isError, error } = useQuery(
		["orders", orderId],
		() => getSingleOrder(orderId)
	);
	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}
	if (data === null) {
		return navigate("/user/orders");
	}
	return (
		<section className="bg-[#F0FEFC]">
			<div className="container mx-auto">
				<div className="flex flex-row items-center mb-4">
					<div className="mr-4" onClick={() => navigate(-1)}>
						<img src={LeftArrow} className="w-6 h-6" />
					</div>
					<h2 className="h3 ">Order Actions</h2>
				</div>
				<div className="flex justify-start">
					<OrderActionsTable data={data} />
				</div>
				<div className="flex justify-start">
					{data.orderStatus === "review" && (
						<ReviewOrderActionButtons order={data} />
					)}
					{data.orderStatus === "readyToShip" && (
						<div className="flex flex-col">
							<div>
								<div>The weight of the package is : {data.weight} kg</div>
								<div>
									The shipping cost to{" "}
									<i>{data.userId.shippingAddress.country}</i> is ₹
									{data.totalShippingCost}{" "}
									{data.isDiscount && (
										<>
											<p>
												You have availed a discount of ₹{data.discountAmount}
											</p>
											<p>
												The payable amount is: ₹
												{data.totalShippingCost - data.discountAmount}
											</p>
										</>
									)}
								</div>
							</div>
							<ReadyToShipActionButtons order={data} />
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default OrderActions;
