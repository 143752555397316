import { useRef } from "react";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import VisuallyHidden from "@reach/visually-hidden";
import { Button, CancelButton } from "components/Button";
import { FormModalInput } from "components/Input";
import { FormProvider } from "react-hook-form";

const ChangePassword = ({
	showPasswordDialog,
	closePasswordDetails,
	onPasswordSubmit,
	methods,
}) => {
	const password = useRef({});
	password.current = methods.watch("newPassword", "");
	return (
		<Dialog
			isOpen={showPasswordDialog}
			onDismiss={closePasswordDetails}
			aria-label="Change Password Modal"
		>
			<div className="flex justify-end ">
				<button className="close-button" onClick={closePasswordDetails}>
					<VisuallyHidden>Close</VisuallyHidden>
					<span aria-hidden>×</span>
				</button>
			</div>
			<h3 className="h3 text-center">Change Password</h3>

			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onPasswordSubmit)}>
					<FormModalInput
						title="Current Password"
						inputName="currentPassword"
						placeholder="current password"
						inputType="password"
						required
						autoComplete="current-password"
						minLength={8}
					/>
					<FormModalInput
						title="New Password"
						inputName="newPassword"
						placeholder="new password"
						inputType="password"
						autoComplete="new-password"
						required
						minLength={8}
					/>
					<FormModalInput
						title="Repeat New Password"
						inputName="repeatNewPassword"
						placeholder="repeat new password"
						autoComplete="new-password"
						inputType="password"
						validate={(value) =>
							value === password.current || "The password does not match"
						}
					/>

					<CancelButton onClick={closePasswordDetails}>Cancel</CancelButton>
					<Button type="submit">Submit</Button>
				</form>
			</FormProvider>
		</Dialog>
	);
};

export default ChangePassword;
