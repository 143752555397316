import LeftArrow from "assets/arrow-left-solid.svg";
import Loading from "components/Loading";
import ItemDetails from "pages/ItemDetails";
import { getSingleOrderWithItemId } from "queries/orderQueries";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

const ViewDetailsPage = () => {
	const { itemId } = useParams();
	const navigate = useNavigate();
	console.log(itemId);
	const { data, isLoading, isError, error } = useQuery(
		["item", itemId],
		() => getSingleOrderWithItemId(itemId),
		{
			onSuccess: (data) => {
				const filteredData = data.items.find((item) => item._id === itemId);
				data.filteredItem = filteredData;
			},
		}
	);

	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}

	return (
		<section>
			<div className="flex items-center">
				<div className="mr-4" onClick={() => navigate(-1)}>
					<img src={LeftArrow} className="w-6 h-6" />
				</div>

				<h2 className="h3 ">Item details</h2>
			</div>

			<div className=" my-4 p-4 bg-[#B7D9E2] max-w-[600px]">
				<div className="flex mb-4 ">
					<h3 className="font-bold mr-2">Name:</h3>
					<p>{data.filteredItem.name}</p>
				</div>

				<ItemDetails
					product={data.filteredItem}
					orderId={data._id}
					isReviewedEmailSent={data.isReviewedEmailSent}
					orderStatus={data.orderStatus}
					isMedicine={data.isMedicine}
					orderType={data.orderType}
				/>
			</div>
		</section>
	);
};

export default ViewDetailsPage;
