import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createPaymentIntent } from "queries/userQueries";
import { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import useUserStore from "stores/UserStore";
import { countryCodeQbject } from "utils/countryData";
import { currency } from "utils/currency";
import "../Payment.css";
import Payment from "./Payment";
const stripePromise = loadStripe(
	"pk_live_51MqcVbSBcWm9jjLNc2Rv88HqWUt7CX5zF03vYrUOBzlz7bTwRe57UfIxAM2tLyPTrHZUnOVP3fHSAIMHwAiPyFBb006LH7yw5d"
);
export default function PaymentHandler() {
	const [clientSecret, setClientSecret] = useState("");
	const email = useUserStore((state) => state.email);
	const { state } = useLocation();
	const { orderId } = useParams();

	const [searchParams, setSearchParams] = useSearchParams();

	const redirect_status = searchParams.get("redirect_status");
	const payment_intent_client_secret = searchParams.get(
		"payment_intent_client_secret"
	);
	const payment_intent = searchParams.get("payment_intent");

	const cost = state?.cost;
	const country = state?.country;
	const type = state?.type;

	const currencyCode = country && countryCodeQbject[country].currency_code;

	const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

	const costCalculatedToLocalCurrency =
		currencyCode && currencyCode === "INR"
			? cost
			: cost * currency[currencyCode]?.rate;
	console.log("currency code is", currencyCode, costCalculatedToLocalCurrency);
	const paymentInetnt = useQuery(
		"paymentIntent",
		() => createPaymentIntent({ orderId, cost, country, type }),
		{
			onSuccess: (data) => {
				console.log("payment intent created", data);
				setClientSecret(data.clientSecret);
			},
			onError: (error) => {
				console.log("error is:", error);
			},
		}
	);

	const appearance = {
		theme: "stripe",
	};
	const options = {
		clientSecret,
		appearance,
	};
	if (paymentInetnt.error) {
		return (
			<div>
				<div className="text-red-500">OOPS: {paymentInetnt.error.message} </div>
			</div>
		);
	}
	return (
		<div className="max-w-[600px] mx-auto mt-8">
			{clientSecret ? (
				<Elements options={options} stripe={stripePromise}>
					<Payment
						userEmail={email}
						country={currencyCode}
						cost={costCalculatedToLocalCurrency}
						orderId={orderId}
						type={type}
					/>
				</Elements>
			) : (
				<div> Payment is loading</div>
			)}
		</div>
	);
}
