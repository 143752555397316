import { checkAuth } from "hooks/checkAuth";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";

export async function getCountriesList() {
	await checkAuth();

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log(`get all countries list`);
	const res = await fetch(`${baseURL}/country`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}
