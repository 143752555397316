export const countryCodeQbject = {
	Afghanistan: {
		country: "Afghanistan",
		currency_code: "AFN",
		alphaCode: "AF",
	},
	Albania: {
		country: "Albania",
		currency_code: "ALL",
		alphaCode: "AL",
	},
	Algeria: {
		country: "Algeria",
		currency_code: "DZD",
		alphaCode: "DZ",
	},
	"American Samoa": {
		country: "American Samoa",
		currency_code: "USD",
		alphaCode: "AS",
	},
	Andorra: {
		country: "Andorra",
		currency_code: "EUR",
		alphaCode: "AD",
	},
	Angola: {
		country: "Angola",
		currency_code: "AOA",
		alphaCode: "AO",
	},
	Anguilla: {
		country: "Anguilla",
		currency_code: "XCD",
		alphaCode: "AI",
	},
	Antarctica: {
		country: "Antarctica",
		currency_code: "XCD",
		alphaCode: "AQ",
	},
	"Antigua and Barbuda": {
		country: "Antigua and Barbuda",
		currency_code: "XCD",
		alphaCode: "AG",
	},
	Argentina: {
		country: "Argentina",
		currency_code: "ARS",
		alphaCode: "AR",
	},
	Armenia: {
		country: "Armenia",
		currency_code: "AMD",
		alphaCode: "AM",
	},
	Aruba: {
		country: "Aruba",
		currency_code: "AWG",
		alphaCode: "AW",
	},
	Australia: {
		country: "Australia",
		currency_code: "AUD",
		alphaCode: "AU",
	},
	Austria: {
		country: "Austria",
		currency_code: "EUR",
		alphaCode: "AT",
	},
	Azerbaijan: {
		country: "Azerbaijan",
		currency_code: "AZN",
		alphaCode: "AZ",
	},
	Bahamas: {
		country: "Bahamas",
		currency_code: "BSD",
		alphaCode: "BS",
	},
	Bahrain: {
		country: "Bahrain",
		currency_code: "BHD",
		alphaCode: "BH",
	},
	Bangladesh: {
		country: "Bangladesh",
		currency_code: "BDT",
		alphaCode: "BD",
	},
	Barbados: {
		country: "Barbados",
		currency_code: "BBD",
		alphaCode: "BB",
	},
	Belarus: {
		country: "Belarus",
		currency_code: "BYR",
		alphaCode: "BY",
	},
	Belgium: {
		country: "Belgium",
		currency_code: "EUR",
		alphaCode: "BE",
	},
	Belize: {
		country: "Belize",
		currency_code: "BZD",
		alphaCode: "BZ",
	},
	Benin: {
		country: "Benin",
		currency_code: "XOF",
		alphaCode: "BJ",
	},
	Bermuda: {
		country: "Bermuda",
		currency_code: "BMD",
		alphaCode: "BM",
	},
	Bhutan: {
		country: "Bhutan",
		currency_code: "BTN",
		alphaCode: "BT",
	},
	Bolivia: {
		country: "Bolivia",
		currency_code: "BOB",
		alphaCode: "BO",
	},
	"Bosnia and Herzegovina": {
		country: "Bosnia and Herzegovina",
		currency_code: "BAM",
		alphaCode: "BA",
	},
	Botswana: {
		country: "Botswana",
		currency_code: "BWP",
		alphaCode: "BW",
	},
	"Bouvet Island": {
		country: "Bouvet Island",
		currency_code: "NOK",
		alphaCode: "BV",
	},
	Brazil: {
		country: "Brazil",
		currency_code: "BRL",
		alphaCode: "BR",
	},
	"British Indian Ocean Territory": {
		country: "British Indian Ocean Territory",
		currency_code: "USD",
		alphaCode: "IO",
	},
	Brunei: {
		country: "Brunei",
		currency_code: "BND",
		alphaCode: "BN",
	},
	Bulgaria: {
		country: "Bulgaria",
		currency_code: "BGN",
		alphaCode: "BG",
	},
	"Burkina Faso": {
		country: "Burkina Faso",
		currency_code: "XOF",
		alphaCode: "BF",
	},
	Burundi: {
		country: "Burundi",
		currency_code: "BIF",
		alphaCode: "BI",
	},
	Cambodia: {
		country: "Cambodia",
		currency_code: "KHR",
		alphaCode: "KH",
	},
	Cameroon: {
		country: "Cameroon",
		currency_code: "XAF",
		alphaCode: "CM",
	},
	Canada: {
		country: "Canada",
		currency_code: "CAD",
		alphaCode: "CA",
	},
	"Cape Verde": {
		country: "Cape Verde",
		currency_code: "CVE",
		alphaCode: "CV",
	},
	"Cayman Islands": {
		country: "Cayman Islands",
		currency_code: "KYD",
		alphaCode: "KY",
	},
	"Central African Republic": {
		country: "Central African Republic",
		currency_code: "XAF",
		alphaCode: "CF",
	},
	Chad: {
		country: "Chad",
		currency_code: "XAF",
		alphaCode: "TD",
	},
	Chile: {
		country: "Chile",
		currency_code: "CLP",
		alphaCode: "CL",
	},
	China: {
		country: "China",
		currency_code: "CNY",
		alphaCode: "CN",
	},
	"Christmas Island": {
		country: "Christmas Island",
		currency_code: "AUD",
		alphaCode: "CX",
	},
	"Cocos (Keeling) Islands": {
		country: "Cocos (Keeling) Islands",
		currency_code: "AUD",
		alphaCode: "CC",
	},
	Colombia: {
		country: "Colombia",
		currency_code: "COP",
		alphaCode: "CO",
	},
	Comoros: {
		country: "Comoros",
		currency_code: "KMF",
		alphaCode: "KM",
	},
	Congo: {
		country: "Congo",
		currency_code: "XAF",
		alphaCode: "CG",
	},
	"Cook Islands": {
		country: "Cook Islands",
		currency_code: "NZD",
		alphaCode: "CK",
	},
	"Costa Rica": {
		country: "Costa Rica",
		currency_code: "CRC",
		alphaCode: "CR",
	},
	Croatia: {
		country: "Croatia",
		currency_code: "HRK",
		alphaCode: "HR",
	},
	Cuba: {
		country: "Cuba",
		currency_code: "CUP",
		alphaCode: "CU",
	},
	Cyprus: {
		country: "Cyprus",
		currency_code: "EUR",
		alphaCode: "CY",
	},
	"Czech Republic": {
		country: "Czech Republic",
		currency_code: "CZK",
		alphaCode: "CZ",
	},
	Denmark: {
		country: "Denmark",
		currency_code: "DKK",
		alphaCode: "DK",
	},
	Djibouti: {
		country: "Djibouti",
		currency_code: "DJF",
		alphaCode: "DJ",
	},
	Dominica: {
		country: "Dominica",
		currency_code: "XCD",
		alphaCode: "DM",
	},
	"Dominican Republic": {
		country: "Dominican Republic",
		currency_code: "DOP",
		alphaCode: "DO",
	},
	"East Timor": {
		country: "East Timor",
		currency_code: "USD",
		alphaCode: "TL",
	},
	Ecuador: {
		country: "Ecuador",
		currency_code: "ECS",
		alphaCode: "EC",
	},
	Egypt: {
		country: "Egypt",
		currency_code: "EGP",
		alphaCode: "EG",
	},
	"El Salvador": {
		country: "El Salvador",
		currency_code: "SVC",
		alphaCode: "SV",
	},
	England: {
		country: "England",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	"Equatorial Guinea": {
		country: "Equatorial Guinea",
		currency_code: "XAF",
		alphaCode: "GQ",
	},
	Eritrea: {
		country: "Eritrea",
		currency_code: "ERN",
		alphaCode: "ER",
	},
	Estonia: {
		country: "Estonia",
		currency_code: "EUR",
		alphaCode: "EE",
	},
	Ethiopia: {
		country: "Ethiopia",
		currency_code: "ETB",
		alphaCode: "ET",
	},
	"Falkland Islands": {
		country: "Falkland Islands",
		currency_code: "FKP",
		alphaCode: "FK",
	},
	"Faroe Islands": {
		country: "Faroe Islands",
		currency_code: "DKK",
		alphaCode: "FO",
	},
	"Fiji Islands": {
		country: "Fiji Islands",
		currency_code: "FJD",
		alphaCode: "FJ",
	},
	Finland: {
		country: "Finland",
		currency_code: "EUR",
		alphaCode: "FI",
	},
	France: {
		country: "France",
		currency_code: "EUR",
		alphaCode: "FR",
	},
	"French Guiana": {
		country: "French Guiana",
		currency_code: "EUR",
		alphaCode: "GF",
	},
	"French Polynesia": {
		country: "French Polynesia",
		currency_code: "XPF",
		alphaCode: "PF",
	},
	"French Southern territories": {
		country: "French Southern territories",
		currency_code: "EUR",
		alphaCode: "TF",
	},
	Gabon: {
		country: "Gabon",
		currency_code: "XAF",
		alphaCode: "GA",
	},
	Gambia: {
		country: "Gambia",
		currency_code: "GMD",
		alphaCode: "GM",
	},
	Georgia: {
		country: "Georgia",
		currency_code: "GEL",
		alphaCode: "GE",
	},
	Germany: {
		country: "Germany",
		currency_code: "EUR",
		alphaCode: "DE",
	},
	Ghana: {
		country: "Ghana",
		currency_code: "GHS",
		alphaCode: "GH",
	},
	Gibraltar: {
		country: "Gibraltar",
		currency_code: "GIP",
		alphaCode: "GI",
	},
	Greece: {
		country: "Greece",
		currency_code: "EUR",
		alphaCode: "GR",
	},
	Greenland: {
		country: "Greenland",
		currency_code: "DKK",
		alphaCode: "GL",
	},
	Grenada: {
		country: "Grenada",
		currency_code: "XCD",
		alphaCode: "GD",
	},
	Guadeloupe: {
		country: "Guadeloupe",
		currency_code: "EUR",
		alphaCode: "GP",
	},
	Guam: {
		country: "Guam",
		currency_code: "USD",
		alphaCode: "GU",
	},
	Guatemala: {
		country: "Guatemala",
		currency_code: "QTQ",
		alphaCode: "GT",
	},
	Guinea: {
		country: "Guinea",
		currency_code: "GNF",
		alphaCode: "GN",
	},
	"Guinea-Bissau": {
		country: "Guinea-Bissau",
		currency_code: "CFA",
		alphaCode: "GW",
	},
	Guyana: {
		country: "Guyana",
		currency_code: "GYD",
		alphaCode: "GY",
	},
	Haiti: {
		country: "Haiti",
		currency_code: "HTG",
		alphaCode: "HT",
	},
	"Heard Island and McDonald Islands": {
		country: "Heard Island and McDonald Islands",
		currency_code: "AUD",
		alphaCode: "HM",
	},
	"Holy See (Vatican City State)": {
		country: "Holy See (Vatican City State)",
		currency_code: "EUR",
		alphaCode: "VA",
	},
	Honduras: {
		country: "Honduras",
		currency_code: "HNL",
		alphaCode: "HN",
	},
	"Hong Kong": {
		country: "Hong Kong",
		currency_code: "HKD",
		alphaCode: "HK",
	},
	Hungary: {
		country: "Hungary",
		currency_code: "HUF",
		alphaCode: "HU",
	},
	Iceland: {
		country: "Iceland",
		currency_code: "ISK",
		alphaCode: "IS",
	},
	India: {
		country: "India",
		currency_code: "INR",
		alphaCode: "IN",
	},
	Indonesia: {
		country: "Indonesia",
		currency_code: "IDR",
		alphaCode: "ID",
	},
	Iran: {
		country: "Iran",
		currency_code: "IRR",
		alphaCode: "IR",
	},
	Iraq: {
		country: "Iraq",
		currency_code: "IQD",
		alphaCode: "IQ",
	},
	Ireland: {
		country: "Ireland",
		currency_code: "EUR",
		alphaCode: "IE",
	},
	Israel: {
		country: "Israel",
		currency_code: "ILS",
		alphaCode: "IL",
	},
	Italy: {
		country: "Italy",
		currency_code: "EUR",
		alphaCode: "IT",
	},
	"Ivory Coast": {
		country: "Ivory Coast",
		currency_code: "XOF",
		alphaCode: "CI",
	},
	Jamaica: {
		country: "Jamaica",
		currency_code: "JMD",
		alphaCode: "JM",
	},
	Japan: {
		country: "Japan",
		currency_code: "JPY",
		alphaCode: "JP",
	},
	Jordan: {
		country: "Jordan",
		currency_code: "JOD",
		alphaCode: "JO",
	},
	Kazakhstan: {
		country: "Kazakhstan",
		currency_code: "KZT",
		alphaCode: "KZ",
	},
	Kenya: {
		country: "Kenya",
		currency_code: "KES",
		alphaCode: "KE",
	},
	Kiribati: {
		country: "Kiribati",
		currency_code: "AUD",
		alphaCode: "KI",
	},
	Kuwait: {
		country: "Kuwait",
		currency_code: "KWD",
		alphaCode: "KW",
	},
	Kyrgyzstan: {
		country: "Kyrgyzstan",
		currency_code: "KGS",
		alphaCode: "KG",
	},
	Laos: {
		country: "Laos",
		currency_code: "LAK",
		alphaCode: "LA",
	},
	Latvia: {
		country: "Latvia",
		currency_code: "LVL",
		alphaCode: "LV",
	},
	Lebanon: {
		country: "Lebanon",
		currency_code: "LBP",
		alphaCode: "LB",
	},
	Lesotho: {
		country: "Lesotho",
		currency_code: "LSL",
		alphaCode: "LS",
	},
	Liberia: {
		country: "Liberia",
		currency_code: "LRD",
		alphaCode: "LR",
	},
	"Libyan Arab Jamahiriya": {
		country: "Libyan Arab Jamahiriya",
		currency_code: "LYD",
		alphaCode: "LY",
	},
	Liechtenstein: {
		country: "Liechtenstein",
		currency_code: "CHF",
		alphaCode: "LI",
	},
	Lithuania: {
		country: "Lithuania",
		currency_code: "LTL",
		alphaCode: "LT",
	},
	Luxembourg: {
		country: "Luxembourg",
		currency_code: "EUR",
		alphaCode: "LU",
	},
	Macau: {
		country: "Macau",
		currency_code: "MOP",
		alphaCode: "MO",
	},
	"North Macedonia": {
		country: "North Macedonia",
		currency_code: "MKD",
		alphaCode: "MK",
	},
	Madagascar: {
		country: "Madagascar",
		currency_code: "MGF",
		alphaCode: "MG",
	},
	Malawi: {
		country: "Malawi",
		currency_code: "MWK",
		alphaCode: "MW",
	},
	Malaysia: {
		country: "Malaysia",
		currency_code: "MYR",
		alphaCode: "MY",
	},
	Maldives: {
		country: "Maldives",
		currency_code: "MVR",
		alphaCode: "MV",
	},
	Mali: {
		country: "Mali",
		currency_code: "XOF",
		alphaCode: "ML",
	},
	Malta: {
		country: "Malta",
		currency_code: "EUR",
		alphaCode: "MT",
	},
	"Marshall Islands": {
		country: "Marshall Islands",
		currency_code: "USD",
		alphaCode: "MH",
	},
	Martinique: {
		country: "Martinique",
		currency_code: "EUR",
		alphaCode: "MQ",
	},
	Mauritania: {
		country: "Mauritania",
		currency_code: "MRO",
		alphaCode: "MR",
	},
	Mauritius: {
		country: "Mauritius",
		currency_code: "MUR",
		alphaCode: "MU",
	},
	Mayotte: {
		country: "Mayotte",
		currency_code: "EUR",
		alphaCode: "YT",
	},
	Mexico: {
		country: "Mexico",
		currency_code: "MXN",
		alphaCode: "MX",
	},
	"Micronesia, Federated States of": {
		country: "Micronesia, Federated States of",
		currency_code: "USD",
		alphaCode: "FM",
	},
	Moldova: {
		country: "Moldova",
		currency_code: "MDL",
		alphaCode: "MD",
	},
	Monaco: {
		country: "Monaco",
		currency_code: "EUR",
		alphaCode: "MC",
	},
	Mongolia: {
		country: "Mongolia",
		currency_code: "MNT",
		alphaCode: "MN",
	},
	Montserrat: {
		country: "Montserrat",
		currency_code: "XCD",
		alphaCode: "MS",
	},
	Morocco: {
		country: "Morocco",
		currency_code: "MAD",
		alphaCode: "MA",
	},
	Mozambique: {
		country: "Mozambique",
		currency_code: "MZN",
		alphaCode: "MZ",
	},
	Myanmar: {
		country: "Myanmar",
		currency_code: "MMR",
		alphaCode: "MM",
	},
	Namibia: {
		country: "Namibia",
		currency_code: "NAD",
		alphaCode: "NA",
	},
	Nauru: {
		country: "Nauru",
		currency_code: "AUD",
		alphaCode: "NR",
	},
	Nepal: {
		country: "Nepal",
		currency_code: "NPR",
		alphaCode: "NP",
	},
	Netherlands: {
		country: "Netherlands",
		currency_code: "EUR",
		alphaCode: "NL",
	},
	"Netherlands Antilles": {
		country: "Netherlands Antilles",
		currency_code: "ANG",
		alphaCode: "AN",
	},
	"New Caledonia": {
		country: "New Caledonia",
		currency_code: "XPF",
		alphaCode: "NC",
	},
	"New Zealand": {
		country: "New Zealand",
		currency_code: "NZD",
		alphaCode: "NZ",
	},
	Nicaragua: {
		country: "Nicaragua",
		currency_code: "NIO",
		alphaCode: "NI",
	},
	Niger: {
		country: "Niger",
		currency_code: "XOF",
		alphaCode: "NE",
	},
	Nigeria: {
		country: "Nigeria",
		currency_code: "NGN",
		alphaCode: "NG",
	},
	Niue: {
		country: "Niue",
		currency_code: "NZD",
		alphaCode: "NU",
	},
	"Norfolk Island": {
		country: "Norfolk Island",
		currency_code: "AUD",
		alphaCode: "NF",
	},
	"North Korea": {
		country: "North Korea",
		currency_code: "KPW",
		alphaCode: "KP",
	},
	"Northern Ireland": {
		country: "Northern Ireland",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	"Northern Mariana Islands": {
		country: "Northern Mariana Islands",
		currency_code: "USD",
		alphaCode: "MP",
	},
	Norway: {
		country: "Norway",
		currency_code: "NOK",
		alphaCode: "NO",
	},
	Oman: {
		country: "Oman",
		currency_code: "OMR",
		alphaCode: "OM",
	},
	Pakistan: {
		country: "Pakistan",
		currency_code: "PKR",
		alphaCode: "PK",
	},
	Palau: {
		country: "Palau",
		currency_code: "USD",
		alphaCode: "PW",
	},
	Panama: {
		country: "Panama",
		currency_code: "PAB",
		alphaCode: "PA",
	},
	"Papua New Guinea": {
		country: "Papua New Guinea",
		currency_code: "PGK",
		alphaCode: "PG",
	},
	Paraguay: {
		country: "Paraguay",
		currency_code: "PYG",
		alphaCode: "PY",
	},
	Peru: {
		country: "Peru",
		currency_code: "PEN",
		alphaCode: "PE",
	},
	Philippines: {
		country: "Philippines",
		currency_code: "PHP",
		alphaCode: "PH",
	},

	Poland: {
		country: "Poland",
		currency_code: "PLN",
		alphaCode: "PL",
	},
	Portugal: {
		country: "Portugal",
		currency_code: "EUR",
		alphaCode: "PT",
	},
	"Puerto Rico": {
		country: "Puerto Rico",
		currency_code: "USD",
		alphaCode: "PR",
	},
	Qatar: {
		country: "Qatar",
		currency_code: "QAR",
		alphaCode: "QA",
	},
	Reunion: {
		country: "Reunion",
		currency_code: "EUR",
		alphaCode: "RE",
	},
	Romania: {
		country: "Romania",
		currency_code: "RON",
		alphaCode: "RO",
	},
	"Russian Federation": {
		country: "Russian Federation",
		currency_code: "RUB",
		alphaCode: "RU",
	},
	Rwanda: {
		country: "Rwanda",
		currency_code: "RWF",
		alphaCode: "RW",
	},
	"Saint Helena": {
		country: "Saint Helena",
		currency_code: "SHP",
		alphaCode: "SH",
	},
	"Saint Lucia": {
		country: "Saint Lucia",
		currency_code: "XCD",
		alphaCode: "LC",
	},

	Samoa: {
		country: "Samoa",
		currency_code: "WST",
		alphaCode: "WS",
	},
	"San Marino": {
		country: "San Marino",
		currency_code: "EUR",
		alphaCode: "SM",
	},

	"Saudi Arabia": {
		country: "Saudi Arabia",
		currency_code: "SAR",
		alphaCode: "SA",
	},
	Scotland: {
		country: "Scotland",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	Senegal: {
		country: "Senegal",
		currency_code: "XOF",
		alphaCode: "SN",
	},
	Serbia: {
		country: "Serbia",
		currency_code: "RSD",
		alphaCode: "RS",
	},
	Seychelles: {
		country: "Seychelles",
		currency_code: "SCR",
		alphaCode: "SC",
	},
	"Sierra Leone": {
		country: "Sierra Leone",
		currency_code: "SLL",
		alphaCode: "SL",
	},
	Singapore: {
		country: "Singapore",
		currency_code: "SGD",
		alphaCode: "SG",
	},
	Slovakia: {
		country: "Slovakia",
		currency_code: "EUR",
		alphaCode: "SK",
	},
	Slovenia: {
		country: "Slovenia",
		currency_code: "EUR",
		alphaCode: "SI",
	},
	"Solomon Islands": {
		country: "Solomon Islands",
		currency_code: "SBD",
		alphaCode: "SB",
	},
	Somalia: {
		country: "Somalia",
		currency_code: "SOS",
		alphaCode: "SO",
	},
	"South Africa": {
		country: "South Africa",
		currency_code: "ZAR",
		alphaCode: "ZA",
	},
	"South Georgia and the South Sandwich Islands": {
		country: "South Georgia and the South Sandwich Islands",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	"South Korea": {
		country: "South Korea",
		currency_code: "KRW",
		alphaCode: "KR",
	},
	"South Sudan": {
		country: "South Sudan",
		currency_code: "SSP",
		alphaCode: "SS",
	},
	Spain: {
		country: "Spain",
		currency_code: "EUR",
		alphaCode: "ES",
	},
	"Sri Lanka": {
		country: "Sri Lanka",
		currency_code: "LKR",
		alphaCode: "LK",
	},
	Sudan: {
		country: "Sudan",
		currency_code: "SDG",
		alphaCode: "SD",
	},
	Suriname: {
		country: "Suriname",
		currency_code: "SRD",
		alphaCode: "SR",
	},
	"Svalbard and Jan Mayen": {
		country: "Svalbard and Jan Mayen",
		currency_code: "NOK",
		alphaCode: "NN",
	},
	Swaziland: {
		country: "Swaziland",
		currency_code: "SZL",
		alphaCode: "SZ",
	},
	Sweden: {
		country: "Sweden",
		currency_code: "SEK",
		alphaCode: "SE",
	},
	Switzerland: {
		country: "Switzerland",
		currency_code: "CHF",
		alphaCode: "CH",
	},

	Tajikistan: {
		country: "Tajikistan",
		currency_code: "TJS",
		alphaCode: "TJ",
	},
	Tanzania: {
		country: "Tanzania",
		currency_code: "TZS",
		alphaCode: "TZ",
	},
	Thailand: {
		country: "Thailand",
		currency_code: "THB",
		alphaCode: "TH",
	},
	"The Democratic Republic of Congo": {
		country: "The Democratic Republic of Congo",
		currency_code: "CDF",
		alphaCode: "CD",
	},
	Togo: {
		country: "Togo",
		currency_code: "XOF",
		alphaCode: "TG",
	},
	Tokelau: {
		country: "Tokelau",
		currency_code: "NZD",
		alphaCode: "TK",
	},
	Tonga: {
		country: "Tonga",
		currency_code: "TOP",
		alphaCode: "TO",
	},
	"Trinidad and Tobago": {
		country: "Trinidad and Tobago",
		currency_code: "TTD",
		alphaCode: "TT",
	},
	Tunisia: {
		country: "Tunisia",
		currency_code: "TND",
		alphaCode: "TN",
	},
	Turkey: {
		country: "Turkey",
		currency_code: "TRY",
		alphaCode: "TR",
	},
	Turkmenistan: {
		country: "Turkmenistan",
		currency_code: "TMT",
		alphaCode: "TM",
	},
	"Turks and Caicos Islands": {
		country: "Turks and Caicos Islands",
		currency_code: "USD",
		alphaCode: "TC",
	},
	Tuvalu: {
		country: "Tuvalu",
		currency_code: "AUD",
		alphaCode: "TV",
	},
	Uganda: {
		country: "Uganda",
		currency_code: "UGX",
		alphaCode: "UG",
	},
	Ukraine: {
		country: "Ukraine",
		currency_code: "UAH",
		alphaCode: "UA",
	},
	"United Arab Emirates": {
		country: "United Arab Emirates",
		currency_code: "AED",
		alphaCode: "AE",
	},
	"United Kingdom": {
		country: "United Kingdom",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	"United States": {
		country: "United States",
		currency_code: "USD",
		alphaCode: "US",
	},
	"United States Minor Outlying Islands": {
		country: "United States Minor Outlying Islands",
		currency_code: "USD",
		alphaCode: "UM",
	},
	Uruguay: {
		country: "Uruguay",
		currency_code: "UYU",
		alphaCode: "UY",
	},
	Uzbekistan: {
		country: "Uzbekistan",
		currency_code: "UZS",
		alphaCode: "UZ",
	},
	Vanuatu: {
		country: "Vanuatu",
		currency_code: "VUV",
		alphaCode: "VU",
	},
	Venezuela: {
		country: "Venezuela",
		currency_code: "VEF",
		alphaCode: "VE",
	},
	Vietnam: {
		country: "Vietnam",
		currency_code: "VND",
		alphaCode: "VN",
	},
	"Virgin Islands, British": {
		country: "Virgin Islands, British",
		currency_code: "USD",
		alphaCode: "VG",
	},
	"Virgin Islands, U.S.": {
		country: "Virgin Islands, U.S.",
		currency_code: "USD",
		alphaCode: "VI",
	},
	Wales: {
		country: "Wales",
		currency_code: "GBP",
		alphaCode: "GB",
	},

	"Western Sahara": {
		country: "Western Sahara",
		currency_code: "MAD",
		alphaCode: "EH",
	},
	Yemen: {
		country: "Yemen",
		currency_code: "YER",
		alphaCode: "YE",
	},
	Zambia: {
		country: "Zambia",
		currency_code: "ZMW",
		alphaCode: "ZM",
	},
	Zimbabwe: {
		country: "Zimbabwe",
		currency_code: "ZWD",
		alphaCode: "ZW",
	},
};

export const countryCodeArray = [
	{
		country: "Afghanistan",
		label: "Afghanistan",
		value: "Afghanistan",
		currency_code: "AFN",
		alphaCode: "AF",
	},
	{
		country: "Albania",
		label: "Albania",
		value: "Albania",
		currency_code: "ALL",
		alphaCode: "AL",
	},
	{
		country: "Algeria",
		label: "Algeria",
		value: "Algeria",
		currency_code: "DZD",
		alphaCode: "DZ",
	},
	{
		country: "American Samoa",
		label: "American Samoa",
		value: "American Samoa",
		currency_code: "USD",
		alphaCode: "AS",
	},
	{
		country: "Andorra",
		label: "Andorra",
		value: "Andorra",
		currency_code: "EUR",
		alphaCode: "AD",
	},
	{
		country: "Angola",
		label: "Angola",
		value: "Angola",
		currency_code: "AOA",
		alphaCode: "AO",
	},
	{
		country: "Anguilla",
		label: "Anguilla",
		value: "Anguilla",
		currency_code: "XCD",
		alphaCode: "AI",
	},
	{
		country: "Antarctica",
		label: "Antarctica",
		value: "Antarctica",
		currency_code: "XCD",
		alphaCode: "AQ",
	},
	{
		country: "Antigua and Barbuda",
		label: "Antigua and Barbuda",
		value: "Antigua and Barbuda",
		currency_code: "XCD",
		alphaCode: "AG",
	},
	{
		country: "Argentina",
		label: "Argentina",
		value: "Argentina",
		currency_code: "ARS",
		alphaCode: "AR",
	},
	{
		country: "Armenia",
		label: "Armenia",
		value: "Armenia",
		currency_code: "AMD",
		alphaCode: "AM",
	},
	{
		country: "Aruba",
		label: "Aruba",
		value: "Aruba",
		currency_code: "AWG",
		alphaCode: "AW",
	},
	{
		country: "Australia",
		label: "Australia",
		value: "Australia",
		currency_code: "AUD",
		alphaCode: "AU",
	},
	{
		country: "Austria",
		label: "Austria",
		value: "Austria",
		currency_code: "EUR",
		alphaCode: "AT",
	},
	{
		country: "Azerbaijan",
		label: "Azerbaijan",
		value: "Azerbaijan",
		currency_code: "AZN",
		alphaCode: "AZ",
	},
	{
		country: "Bahamas",
		label: "Bahamas",
		value: "Bahamas",
		currency_code: "BSD",
		alphaCode: "BS",
	},
	{
		country: "Bahrain",
		label: "Bahrain",
		value: "Bahrain",
		currency_code: "BHD",
		alphaCode: "BH",
	},
	{
		country: "Bangladesh",
		label: "Bangladesh",
		value: "Bangladesh",
		currency_code: "BDT",
		alphaCode: "BD",
	},
	{
		country: "Barbados",
		label: "Barbados",
		value: "Barbados",
		currency_code: "BBD",
		alphaCode: "BB",
	},
	{
		country: "Belarus",
		label: "Belarus",
		value: "Belarus",
		currency_code: "BYR",
		alphaCode: "BY",
	},
	{
		country: "Belgium",
		label: "Belgium",
		value: "Belgium",
		currency_code: "EUR",
		alphaCode: "BE",
	},
	{
		country: "Belize",
		label: "Belize",
		value: "Belize",
		currency_code: "BZD",
		alphaCode: "BZ",
	},
	{
		country: "Benin",
		label: "Benin",
		value: "Benin",
		currency_code: "XOF",
		alphaCode: "BJ",
	},
	{
		country: "Bermuda",
		label: "Bermuda",
		value: "Bermuda",
		currency_code: "BMD",
		alphaCode: "BM",
	},
	{
		country: "Bhutan",
		label: "Bhutan",
		value: "Bhutan",
		currency_code: "BTN",
		alphaCode: "BT",
	},
	{
		country: "Bolivia",
		label: "Bolivia",
		value: "Bolivia",
		currency_code: "BOB",
		alphaCode: "BO",
	},
	{
		country: "Bosnia and Herzegovina",
		label: "Bosnia and Herzegovina",
		value: "Bosnia and Herzegovina",
		currency_code: "BAM",
		alphaCode: "BA",
	},
	{
		country: "Botswana",
		label: "Botswana",
		value: "Botswana",
		currency_code: "BWP",
		alphaCode: "BW",
	},
	{
		country: "Bouvet Island",
		label: "Bouvet Island",
		value: "Bouvet Island",
		currency_code: "NOK",
		alphaCode: "BV",
	},
	{
		country: "Brazil",
		label: "Brazil",
		value: "Brazil",
		currency_code: "BRL",
		alphaCode: "BR",
	},
	{
		country: "British Indian Ocean Territory",
		label: "British Indian Ocean Territory",
		value: "British Indian Ocean Territory",
		currency_code: "USD",
		alphaCode: "IO",
	},
	{
		country: "Brunei",
		label: "Brunei",
		value: "Brunei",
		currency_code: "BND",
		alphaCode: "BN",
	},
	{
		country: "Bulgaria",
		label: "Bulgaria",
		value: "Bulgaria",
		currency_code: "BGN",
		alphaCode: "BG",
	},
	{
		country: "Burkina Faso",
		label: "Burkina Faso",
		value: "Burkina Faso",
		currency_code: "XOF",
		alphaCode: "BF",
	},
	{
		country: "Burundi",
		label: "Burundi",
		value: "Burundi",
		currency_code: "BIF",
		alphaCode: "BI",
	},
	{
		country: "Cambodia",
		label: "Cambodia",
		value: "Cambodia",
		currency_code: "KHR",
		alphaCode: "KH",
	},
	{
		country: "Cameroon",
		label: "Cameroon",
		value: "Cameroon",
		currency_code: "XAF",
		alphaCode: "CM",
	},
	{
		country: "Canada",
		label: "Canada",
		value: "Canada",
		currency_code: "CAD",
		alphaCode: "CA",
	},
	{
		country: "Cape Verde",
		label: "Cape Verde",
		value: "Cape Verde",
		currency_code: "CVE",
		alphaCode: "CV",
	},
	{
		country: "Cayman Islands",
		label: "Cayman Islands",
		value: "Cayman Islands",
		currency_code: "KYD",
		alphaCode: "KY",
	},
	{
		country: "Central African Republic",
		label: "Central African Republic",
		value: "Central African Republic",
		currency_code: "XAF",
		alphaCode: "CF",
	},
	{
		country: "Chad",
		label: "Chad",
		value: "Chad",
		currency_code: "XAF",
		alphaCode: "TD",
	},
	{
		country: "Chile",
		label: "Chile",
		value: "Chile",
		currency_code: "CLP",
		alphaCode: "CL",
	},
	{
		country: "China",
		label: "China",
		value: "China",
		currency_code: "CNY",
		alphaCode: "CN",
	},
	{
		country: "Christmas Island",
		label: "Christmas Island",
		value: "Christmas Island",
		currency_code: "AUD",
		alphaCode: "CX",
	},
	{
		country: "Cocos (Keeling) Islands",
		label: "Cocos (Keeling) Islands",
		value: "Cocos (Keeling) Islands",
		currency_code: "AUD",
		alphaCode: "CC",
	},
	{
		country: "Colombia",
		label: "Colombia",
		value: "Colombia",
		currency_code: "COP",
		alphaCode: "CO",
	},
	{
		country: "Comoros",
		label: "Comoros",
		value: "Comoros",
		currency_code: "KMF",
		alphaCode: "KM",
	},
	{
		country: "Congo",
		label: "Congo",
		value: "Congo",
		currency_code: "XAF",
		alphaCode: "CG",
	},
	{
		country: "Cook Islands",
		label: "Cook Islands",
		value: "Cook Islands",
		currency_code: "NZD",
		alphaCode: "CK",
	},
	{
		country: "Costa Rica",
		label: "Costa Rica",
		value: "Costa Rica",
		currency_code: "CRC",
		alphaCode: "CR",
	},
	{
		country: "Croatia",
		label: "Croatia",
		value: "Croatia",
		currency_code: "HRK",
		alphaCode: "HR",
	},
	{
		country: "Cuba",
		label: "Cuba",
		value: "Cuba",
		currency_code: "CUP",
		alphaCode: "CU",
	},
	{
		country: "Cyprus",
		label: "Cyprus",
		value: "Cyprus",
		currency_code: "EUR",
		alphaCode: "CY",
	},
	{
		country: "Czech Republic",
		label: "Czech Republic",
		value: "Czech Republic",
		currency_code: "CZK",
		alphaCode: "CZ",
	},
	{
		country: "Denmark",
		label: "Denmark",
		value: "Denmark",
		currency_code: "DKK",
		alphaCode: "DK",
	},
	{
		country: "Djibouti",
		label: "Djibouti",
		value: "Djibouti",
		currency_code: "DJF",
		alphaCode: "DJ",
	},
	{
		country: "Dominica",
		label: "Dominica",
		value: "Dominica",
		currency_code: "XCD",
		alphaCode: "DM",
	},
	{
		country: "Dominican Republic",
		label: "Dominican Republic",
		value: "Dominican Republic",
		currency_code: "DOP",
		alphaCode: "DO",
	},
	{
		country: "East Timor",
		label: "East Timor",
		value: "East Timor",
		currency_code: "USD",
		alphaCode: "TL",
	},
	{
		country: "Ecuador",
		label: "Ecuador",
		value: "Ecuador",
		currency_code: "ECS",
		alphaCode: "EC",
	},
	{
		country: "Egypt",
		label: "Egypt",
		value: "Egypt",
		currency_code: "EGP",
		alphaCode: "EG",
	},
	{
		country: "El Salvador",
		label: "El Salvador",
		value: "El Salvador",
		currency_code: "SVC",
		alphaCode: "SV",
	},
	{
		country: "England",
		label: "England",
		value: "England",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	{
		country: "Equatorial Guinea",
		label: "Equatorial Guinea",
		value: "Equatorial Guinea",
		currency_code: "XAF",
		alphaCode: "GQ",
	},
	{
		country: "Eritrea",
		label: "Eritrea",
		value: "Eritrea",
		currency_code: "ERN",
		alphaCode: "ER",
	},
	{
		country: "Estonia",
		label: "Estonia",
		value: "Estonia",
		currency_code: "EUR",
		alphaCode: "EE",
	},
	{
		country: "Ethiopia",
		label: "Ethiopia",
		value: "Ethiopia",
		currency_code: "ETB",
		alphaCode: "ET",
	},
	{
		country: "Falkland Islands",
		label: "Falkland Islands",
		value: "Falkland Islands",
		currency_code: "FKP",
		alphaCode: "FK",
	},
	{
		country: "Faroe Islands",
		label: "Faroe Islands",
		value: "Faroe Islands",
		currency_code: "DKK",
		alphaCode: "FO",
	},
	{
		country: "Fiji Islands",
		label: "Fiji Islands",
		value: "Fiji Islands",
		currency_code: "FJD",
		alphaCode: "FJ",
	},
	{
		country: "Finland",
		label: "Finland",
		value: "Finland",
		currency_code: "EUR",
		alphaCode: "FI",
	},
	{
		country: "France",
		label: "France",
		value: "France",
		currency_code: "EUR",
		alphaCode: "FR",
	},
	{
		country: "French Guiana",
		label: "French Guiana",
		value: "French Guiana",
		currency_code: "EUR",
		alphaCode: "GF",
	},
	{
		country: "French Polynesia",
		label: "French Polynesia",
		value: "French Polynesia",
		currency_code: "XPF",
		alphaCode: "PF",
	},
	{
		country: "French Southern territories",
		label: "French Southern territories",
		value: "French Southern territories",
		currency_code: "EUR",
		alphaCode: "TF",
	},
	{
		country: "Gabon",
		label: "Gabon",
		value: "Gabon",
		currency_code: "XAF",
		alphaCode: "GA",
	},
	{
		country: "Gambia",
		label: "Gambia",
		value: "Gambia",
		currency_code: "GMD",
		alphaCode: "GM",
	},
	{
		country: "Georgia",
		label: "Georgia",
		value: "Georgia",
		currency_code: "GEL",
		alphaCode: "GE",
	},
	{
		country: "Germany",
		label: "Germany",
		value: "Germany",
		currency_code: "EUR",
		alphaCode: "DE",
	},
	{
		country: "Ghana",
		label: "Ghana",
		value: "Ghana",
		currency_code: "GHS",
		alphaCode: "GH",
	},
	{
		country: "Gibraltar",
		label: "Gibraltar",
		value: "Gibraltar",
		currency_code: "GIP",
		alphaCode: "GI",
	},
	{
		country: "Greece",
		label: "Greece",
		value: "Greece",
		currency_code: "EUR",
		alphaCode: "GR",
	},
	{
		country: "Greenland",
		label: "Greenland",
		value: "Greenland",
		currency_code: "DKK",
		alphaCode: "GL",
	},
	{
		country: "Grenada",
		label: "Grenada",
		value: "Grenada",
		currency_code: "XCD",
		alphaCode: "GD",
	},
	{
		country: "Guadeloupe",
		label: "Guadeloupe",
		value: "Guadeloupe",
		currency_code: "EUR",
		alphaCode: "GP",
	},
	{
		country: "Guam",
		label: "Guam",
		value: "Guam",
		currency_code: "USD",
		alphaCode: "GU",
	},
	{
		country: "Guatemala",
		label: "Guatemala",
		value: "Guatemala",
		currency_code: "QTQ",
		alphaCode: "GT",
	},
	{
		country: "Guinea",
		label: "Guinea",
		value: "Guinea",
		currency_code: "GNF",
		alphaCode: "GN",
	},
	{
		country: "Guinea-Bissau",
		label: "Guinea-Bissau",
		value: "Guinea-Bissau",
		currency_code: "CFA",
		alphaCode: "GW",
	},
	{
		country: "Guyana",
		label: "Guyana",
		value: "Guyana",
		currency_code: "GYD",
		alphaCode: "GY",
	},
	{
		country: "Haiti",
		label: "Haiti",
		value: "Haiti",
		currency_code: "HTG",
		alphaCode: "HT",
	},
	{
		country: "Heard Island and McDonald Islands",
		label: "Heard Island and McDonald Islands",
		value: "Heard Island and McDonald Islands",
		currency_code: "AUD",
		alphaCode: "HM",
	},
	{
		country: "Holy See (Vatican City State)",
		label: "Holy See (Vatican City State)",
		value: "Holy See (Vatican City State)",
		currency_code: "EUR",
		alphaCode: "VA",
	},
	{
		country: "Honduras",
		label: "Honduras",
		value: "Honduras",
		currency_code: "HNL",
		alphaCode: "HN",
	},
	{
		country: "Hong Kong",
		label: "Hong Kong",
		value: "Hong Kong",
		currency_code: "HKD",
		alphaCode: "HK",
	},
	{
		country: "Hungary",
		label: "Hungary",
		value: "Hungary",
		currency_code: "HUF",
		alphaCode: "HU",
	},
	{
		country: "Iceland",
		label: "Iceland",
		value: "Iceland",
		currency_code: "ISK",
		alphaCode: "IS",
	},
	{
		country: "India",
		label: "India",
		value: "India",
		currency_code: "INR",
		alphaCode: "IN",
	},
	{
		country: "Indonesia",
		label: "Indonesia",
		value: "Indonesia",
		currency_code: "IDR",
		alphaCode: "ID",
	},
	{
		country: "Iran",
		label: "Iran",
		value: "Iran",
		currency_code: "IRR",
		alphaCode: "IR",
	},
	{
		country: "Iraq",
		label: "Iraq",
		value: "Iraq",
		currency_code: "IQD",
		alphaCode: "IQ",
	},
	{
		country: "Ireland",
		label: "Ireland",
		value: "Ireland",
		currency_code: "EUR",
		alphaCode: "IE",
	},
	{
		country: "Israel",
		label: "Israel",
		value: "Israel",
		currency_code: "ILS",
		alphaCode: "IL",
	},
	{
		country: "Italy",
		label: "Italy",
		value: "Italy",
		currency_code: "EUR",
		alphaCode: "IT",
	},
	{
		country: "Ivory Coast",
		label: "Ivory Coast",
		value: "Ivory Coast",
		currency_code: "XOF",
		alphaCode: "CI",
	},
	{
		country: "Jamaica",
		label: "Jamaica",
		value: "Jamaica",
		currency_code: "JMD",
		alphaCode: "JM",
	},
	{
		country: "Japan",
		label: "Japan",
		value: "Japan",
		currency_code: "JPY",
		alphaCode: "JP",
	},
	{
		country: "Jordan",
		label: "Jordan",
		value: "Jordan",
		currency_code: "JOD",
		alphaCode: "JO",
	},
	{
		country: "Kazakhstan",
		label: "Kazakhstan",
		value: "Kazakhstan",
		currency_code: "KZT",
		alphaCode: "KZ",
	},
	{
		country: "Kenya",
		label: "Kenya",
		value: "Kenya",
		currency_code: "KES",
		alphaCode: "KE",
	},
	{
		country: "Kiribati",
		label: "Kiribati",
		value: "Kiribati",
		currency_code: "AUD",
		alphaCode: "KI",
	},
	{
		country: "Kuwait",
		label: "Kuwait",
		value: "Kuwait",
		currency_code: "KWD",
		alphaCode: "KW",
	},
	{
		country: "Kyrgyzstan",
		label: "Kyrgyzstan",
		value: "Kyrgyzstan",
		currency_code: "KGS",
		alphaCode: "KG",
	},
	{
		country: "Laos",
		label: "Laos",
		value: "Laos",
		currency_code: "LAK",
		alphaCode: "LA",
	},
	{
		country: "Latvia",
		label: "Latvia",
		value: "Latvia",
		currency_code: "LVL",
		alphaCode: "LV",
	},
	{
		country: "Lebanon",
		label: "Lebanon",
		value: "Lebanon",
		currency_code: "LBP",
		alphaCode: "LB",
	},
	{
		country: "Lesotho",
		label: "Lesotho",
		value: "Lesotho",
		currency_code: "LSL",
		alphaCode: "LS",
	},
	{
		country: "Liberia",
		label: "Liberia",
		value: "Liberia",
		currency_code: "LRD",
		alphaCode: "LR",
	},
	{
		country: "Libyan Arab Jamahiriya",
		label: "Libyan Arab Jamahiriya",
		value: "Libyan Arab Jamahiriya",
		currency_code: "LYD",
		alphaCode: "LY",
	},
	{
		country: "Liechtenstein",
		label: "Liechtenstein",
		value: "Liechtenstein",
		currency_code: "CHF",
		alphaCode: "LI",
	},
	{
		country: "Lithuania",
		label: "Lithuania",
		value: "Lithuania",
		currency_code: "LTL",
		alphaCode: "LT",
	},
	{
		country: "Luxembourg",
		label: "Luxembourg",
		value: "Luxembourg",
		currency_code: "EUR",
		alphaCode: "LU",
	},
	{
		country: "Macau",
		label: "Macau",
		value: "Macau",
		currency_code: "MOP",
		alphaCode: "MO",
	},
	{
		country: "North Macedonia",
		label: "North Macedonia",
		value: "North Macedonia",
		currency_code: "MKD",
		alphaCode: "MK",
	},
	{
		country: "Madagascar",
		label: "Madagascar",
		value: "Madagascar",
		currency_code: "MGF",
		alphaCode: "MG",
	},
	{
		country: "Malawi",
		label: "Malawi",
		value: "Malawi",
		currency_code: "MWK",
		alphaCode: "MW",
	},
	{
		country: "Malaysia",
		label: "Malaysia",
		value: "Malaysia",
		currency_code: "MYR",
		alphaCode: "MY",
	},
	{
		country: "Maldives",
		label: "Maldives",
		value: "Maldives",
		currency_code: "MVR",
		alphaCode: "MV",
	},
	{
		country: "Mali",
		label: "Mali",
		value: "Mali",
		currency_code: "XOF",
		alphaCode: "ML",
	},
	{
		country: "Malta",
		label: "Malta",
		value: "Malta",
		currency_code: "EUR",
		alphaCode: "MT",
	},
	{
		country: "Marshall Islands",
		label: "Marshall Islands",
		value: "Marshall Islands",
		currency_code: "USD",
		alphaCode: "MH",
	},
	{
		country: "Martinique",
		label: "Martinique",
		value: "Martinique",
		currency_code: "EUR",
		alphaCode: "MQ",
	},
	{
		country: "Mauritania",
		label: "Mauritania",
		value: "Mauritania",
		currency_code: "MRO",
		alphaCode: "MR",
	},
	{
		country: "Mauritius",
		label: "Mauritius",
		value: "Mauritius",
		currency_code: "MUR",
		alphaCode: "MU",
	},
	{
		country: "Mayotte",
		label: "Mayotte",
		value: "Mayotte",
		currency_code: "EUR",
		alphaCode: "YT",
	},
	{
		country: "Mexico",
		label: "Mexico",
		value: "Mexico",
		currency_code: "MXN",
		alphaCode: "MX",
	},
	{
		country: "Micronesia, Federated States of",
		label: "Micronesia, Federated States of",
		value: "Micronesia, Federated States of",
		currency_code: "USD",
		alphaCode: "FM",
	},
	{
		country: "Moldova",
		label: "Moldova",
		value: "Moldova",
		currency_code: "MDL",
		alphaCode: "MD",
	},
	{
		country: "Monaco",
		label: "Monaco",
		value: "Monaco",
		currency_code: "EUR",
		alphaCode: "MC",
	},
	{
		country: "Mongolia",
		label: "Mongolia",
		value: "Mongolia",
		currency_code: "MNT",
		alphaCode: "MN",
	},
	{
		country: "Montserrat",
		label: "Montserrat",
		value: "Montserrat",
		currency_code: "XCD",
		alphaCode: "MS",
	},
	{
		country: "Morocco",
		label: "Morocco",
		value: "Morocco",
		currency_code: "MAD",
		alphaCode: "MA",
	},
	{
		country: "Mozambique",
		label: "Mozambique",
		value: "Mozambique",
		currency_code: "MZN",
		alphaCode: "MZ",
	},
	{
		country: "Myanmar",
		label: "Myanmar",
		value: "Myanmar",
		currency_code: "MMR",
		alphaCode: "MM",
	},
	{
		country: "Namibia",
		label: "Namibia",
		value: "Namibia",
		currency_code: "NAD",
		alphaCode: "NA",
	},
	{
		country: "Nauru",
		label: "Nauru",
		value: "Nauru",
		currency_code: "AUD",
		alphaCode: "NR",
	},
	{
		country: "Nepal",
		label: "Nepal",
		value: "Nepal",
		currency_code: "NPR",
		alphaCode: "NP",
	},
	{
		country: "Netherlands",
		label: "Netherlands",
		value: "Netherlands",
		currency_code: "EUR",
		alphaCode: "NL",
	},
	{
		country: "Netherlands Antilles",
		label: "Netherlands Antilles",
		value: "Netherlands Antilles",
		currency_code: "ANG",
		alphaCode: "AN",
	},
	{
		country: "New Caledonia",
		label: "New Caledonia",
		value: "New Caledonia",
		currency_code: "XPF",
		alphaCode: "NC",
	},
	{
		country: "New Zealand",
		label: "New Zealand",
		value: "New Zealand",
		currency_code: "NZD",
		alphaCode: "NZ",
	},
	{
		country: "Nicaragua",
		label: "Nicaragua",
		value: "Nicaragua",
		currency_code: "NIO",
		alphaCode: "NI",
	},
	{
		country: "Niger",
		label: "Niger",
		value: "Niger",
		currency_code: "XOF",
		alphaCode: "NE",
	},
	{
		country: "Nigeria",
		label: "Nigeria",
		value: "Nigeria",
		currency_code: "NGN",
		alphaCode: "NG",
	},
	{
		country: "Niue",
		label: "Niue",
		value: "Niue",
		currency_code: "NZD",
		alphaCode: "NU",
	},
	{
		country: "Norfolk Island",
		label: "Norfolk Island",
		value: "Norfolk Island",
		currency_code: "AUD",
		alphaCode: "NF",
	},
	{
		country: "North Korea",
		label: "North Korea",
		value: "North Korea",
		currency_code: "KPW",
		alphaCode: "KP",
	},
	{
		country: "Northern Ireland",
		label: "Northern Ireland",
		value: "Northern Ireland",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	{
		country: "Northern Mariana Islands",
		label: "Northern Mariana Islands",
		value: "Northern Mariana Islands",
		currency_code: "USD",
		alphaCode: "MP",
	},
	{
		country: "Norway",
		label: "Norway",
		value: "Norway",
		currency_code: "NOK",
		alphaCode: "NO",
	},
	{
		country: "Oman",
		label: "Oman",
		value: "Oman",
		currency_code: "OMR",
		alphaCode: "OM",
	},
	{
		country: "Pakistan",
		label: "Pakistan",
		value: "Pakistan",
		currency_code: "PKR",
		alphaCode: "PK",
	},
	{
		country: "Palau",
		label: "Palau",
		value: "Palau",
		currency_code: "USD",
		alphaCode: "PW",
	},
	{
		country: "Panama",
		label: "Panama",
		value: "Panama",
		currency_code: "PAB",
		alphaCode: "PA",
	},
	{
		country: "Papua New Guinea",
		label: "Papua New Guinea",
		value: "Papua New Guinea",
		currency_code: "PGK",
		alphaCode: "PG",
	},
	{
		country: "Paraguay",
		label: "Paraguay",
		value: "Paraguay",
		currency_code: "PYG",
		alphaCode: "PY",
	},
	{
		country: "Peru",
		label: "Peru",
		value: "Peru",
		currency_code: "PEN",
		alphaCode: "PE",
	},
	{
		country: "Philippines",
		label: "Philippines",
		value: "Philippines",
		currency_code: "PHP",
		alphaCode: "PH",
	},

	{
		country: "Poland",
		label: "Poland",
		value: "Poland",
		currency_code: "PLN",
		alphaCode: "PL",
	},
	{
		country: "Portugal",
		label: "Portugal",
		value: "Portugal",
		currency_code: "EUR",
		alphaCode: "PT",
	},
	{
		country: "Puerto Rico",
		label: "Puerto Rico",
		value: "Puerto Rico",
		currency_code: "USD",
		alphaCode: "PR",
	},
	{
		country: "Qatar",
		label: "Qatar",
		value: "Qatar",
		currency_code: "QAR",
		alphaCode: "QA",
	},
	{
		country: "Reunion",
		label: "Reunion",
		value: "Reunion",
		currency_code: "EUR",
		alphaCode: "RE",
	},
	{
		country: "Romania",
		label: "Romania",
		value: "Romania",
		currency_code: "RON",
		alphaCode: "RO",
	},
	{
		country: "Russian Federation",
		label: "Russian Federation",
		value: "Russian Federation",
		currency_code: "RUB",
		alphaCode: "RU",
	},
	{
		country: "Rwanda",
		label: "Rwanda",
		value: "Rwanda",
		currency_code: "RWF",
		alphaCode: "RW",
	},
	{
		country: "Saint Helena",
		label: "Saint Helena",
		value: "Saint Helena",
		currency_code: "SHP",
		alphaCode: "SH",
	},
	{
		country: "Saint Lucia",
		label: "Saint Lucia",
		value: "Saint Lucia",
		currency_code: "XCD",
		alphaCode: "LC",
	},

	{
		country: "Samoa",
		label: "Samoa",
		value: "Samoa",
		currency_code: "WST",
		alphaCode: "WS",
	},
	{
		country: "San Marino",
		label: "San Marino",
		value: "San Marino",
		currency_code: "EUR",
		alphaCode: "SM",
	},

	{
		country: "Saudi Arabia",
		label: "Saudi Arabia",
		value: "Saudi Arabia",
		currency_code: "SAR",
		alphaCode: "SA",
	},
	{
		country: "Scotland",
		label: "Scotland",
		value: "Scotland",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	{
		country: "Senegal",
		label: "Senegal",
		value: "Senegal",
		currency_code: "XOF",
		alphaCode: "SN",
	},
	{
		country: "Serbia",
		label: "Serbia",
		value: "Serbia",
		currency_code: "RSD",
		alphaCode: "RS",
	},
	{
		country: "Seychelles",
		label: "Seychelles",
		value: "Seychelles",
		currency_code: "SCR",
		alphaCode: "SC",
	},
	{
		country: "Sierra Leone",
		label: "Sierra Leone",
		value: "Sierra Leone",
		currency_code: "SLL",
		alphaCode: "SL",
	},
	{
		country: "Singapore",
		label: "Singapore",
		value: "Singapore",
		currency_code: "SGD",
		alphaCode: "SG",
	},
	{
		country: "Slovakia",
		label: "Slovakia",
		value: "Slovakia",
		currency_code: "EUR",
		alphaCode: "SK",
	},
	{
		country: "Slovenia",
		label: "Slovenia",
		value: "Slovenia",
		currency_code: "EUR",
		alphaCode: "SI",
	},
	{
		country: "Solomon Islands",
		label: "Solomon Islands",
		value: "Solomon Islands",
		currency_code: "SBD",
		alphaCode: "SB",
	},
	{
		country: "Somalia",
		label: "Somalia",
		value: "Somalia",
		currency_code: "SOS",
		alphaCode: "SO",
	},
	{
		country: "South Africa",
		label: "South Africa",
		value: "South Africa",
		currency_code: "ZAR",
		alphaCode: "ZA",
	},
	{
		country: "South Georgia and the South Sandwich Islands",
		label: "South Georgia and the South Sandwich Islands",
		value: "South Georgia and the South Sandwich Islands",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	{
		country: "South Korea",
		label: "South Korea",
		value: "South Korea",
		currency_code: "KRW",
		alphaCode: "KR",
	},
	{
		country: "South Sudan",
		label: "South Sudan",
		value: "South Sudan",
		currency_code: "SSP",
		alphaCode: "SS",
	},
	{
		country: "Spain",
		label: "Spain",
		value: "Spain",
		currency_code: "EUR",
		alphaCode: "ES",
	},
	{
		country: "Sri Lanka",
		label: "Sri Lanka",
		value: "Sri Lanka",
		currency_code: "LKR",
		alphaCode: "LK",
	},
	{
		country: "Sudan",
		label: "Sudan",
		value: "Sudan",
		currency_code: "SDG",
		alphaCode: "SD",
	},
	{
		country: "Suriname",
		label: "Suriname",
		value: "Suriname",
		currency_code: "SRD",
		alphaCode: "SR",
	},
	{
		country: "Svalbard and Jan Mayen",
		label: "Svalbard and Jan Mayen",
		value: "Svalbard and Jan Mayen",
		currency_code: "NOK",
		alphaCode: "NN",
	},
	{
		country: "Swaziland",
		label: "Swaziland",
		value: "Swaziland",
		currency_code: "SZL",
		alphaCode: "SZ",
	},
	{
		country: "Sweden",
		label: "Sweden",
		value: "Sweden",
		currency_code: "SEK",
		alphaCode: "SE",
	},
	{
		country: "Switzerland",
		label: "Switzerland",
		value: "Switzerland",
		currency_code: "CHF",
		alphaCode: "CH",
	},

	{
		country: "Tajikistan",
		label: "Tajikistan",
		value: "Tajikistan",
		currency_code: "TJS",
		alphaCode: "TJ",
	},
	{
		country: "Tanzania",
		label: "Tanzania",
		value: "Tanzania",
		currency_code: "TZS",
		alphaCode: "TZ",
	},
	{
		country: "Thailand",
		label: "Thailand",
		value: "Thailand",
		currency_code: "THB",
		alphaCode: "TH",
	},
	{
		country: "The Democratic Republic of Congo",
		label: "The Democratic Republic of Congo",
		value: "The Democratic Republic of Congo",
		currency_code: "CDF",
		alphaCode: "CD",
	},
	{
		country: "Togo",
		label: "Togo",
		value: "Togo",
		currency_code: "XOF",
		alphaCode: "TG",
	},
	{
		country: "Tokelau",
		label: "Tokelau",
		value: "Tokelau",
		currency_code: "NZD",
		alphaCode: "TK",
	},
	{
		country: "Tonga",
		label: "Tonga",
		value: "Tonga",
		currency_code: "TOP",
		alphaCode: "TO",
	},
	{
		country: "Trinidad and Tobago",
		label: "Trinidad and Tobago",
		value: "Trinidad and Tobago",
		currency_code: "TTD",
		alphaCode: "TT",
	},
	{
		country: "Tunisia",
		label: "Tunisia",
		value: "Tunisia",
		currency_code: "TND",
		alphaCode: "TN",
	},
	{
		country: "Turkey",
		label: "Turkey",
		value: "Turkey",
		currency_code: "TRY",
		alphaCode: "TR",
	},
	{
		country: "Turkmenistan",
		label: "Turkmenistan",
		value: "Turkmenistan",
		currency_code: "TMT",
		alphaCode: "TM",
	},
	{
		country: "Turks and Caicos Islands",
		label: "Turks and Caicos Islands",
		value: "Turks and Caicos Islands",
		currency_code: "USD",
		alphaCode: "TC",
	},
	{
		country: "Tuvalu",
		label: "Tuvalu",
		value: "Tuvalu",
		currency_code: "AUD",
		alphaCode: "TV",
	},
	{
		country: "Uganda",
		label: "Uganda",
		value: "Uganda",
		currency_code: "UGX",
		alphaCode: "UG",
	},
	{
		country: "Ukraine",
		label: "Ukraine",
		value: "Ukraine",
		currency_code: "UAH",
		alphaCode: "UA",
	},
	{
		country: "United Arab Emirates",
		label: "United Arab Emirates",
		value: "United Arab Emirates",
		currency_code: "AED",
		alphaCode: "AE",
	},
	{
		country: "United Kingdom",
		label: "United Kingdom",
		value: "United Kingdom",
		currency_code: "GBP",
		alphaCode: "GB",
	},
	{
		country: "United States",
		label: "United States",
		value: "United States",
		currency_code: "USD",
		alphaCode: "US",
	},
	{
		country: "United States Minor Outlying Islands",
		label: "United States Minor Outlying Islands",
		value: "United States Minor Outlying Islands",
		currency_code: "USD",
		alphaCode: "UM",
	},
	{
		country: "Uruguay",
		label: "Uruguay",
		value: "Uruguay",
		currency_code: "UYU",
		alphaCode: "UY",
	},
	{
		country: "Uzbekistan",
		label: "Uzbekistan",
		value: "Uzbekistan",
		currency_code: "UZS",
		alphaCode: "UZ",
	},
	{
		country: "Vanuatu",
		label: "Vanuatu",
		value: "Vanuatu",
		currency_code: "VUV",
		alphaCode: "VU",
	},
	{
		country: "Venezuela",
		label: "Venezuela",
		value: "Venezuela",
		currency_code: "VEF",
		alphaCode: "VE",
	},
	{
		country: "Vietnam",
		label: "Vietnam",
		value: "Vietnam",
		currency_code: "VND",
		alphaCode: "VN",
	},
	{
		country: "Virgin Islands, British",
		label: "Virgin Islands, British",
		value: "Virgin Islands, British",
		currency_code: "USD",
		alphaCode: "VG",
	},
	{
		country: "Virgin Islands, U.S.",
		label: "Virgin Islands, U.S.",
		value: "Virgin Islands, U.S.",
		currency_code: "USD",
		alphaCode: "VI",
	},
	{
		country: "Wales",
		label: "Wales",
		value: "Wales",
		currency_code: "GBP",
		alphaCode: "GB",
	},

	{
		country: "Western Sahara",
		label: "Western Sahara",
		value: "Western Sahara",
		currency_code: "MAD",
		alphaCode: "EH",
	},
	{
		country: "Yemen",
		label: "Yemen",
		value: "Yemen",
		currency_code: "YER",
		alphaCode: "YE",
	},
	{
		country: "Zambia",
		label: "Zambia",
		value: "Zambia",
		currency_code: "ZMW",
		alphaCode: "ZM",
	},
	{
		country: "Zimbabwe",
		label: "Zimbabwe",
		value: "Zimbabwe",
		currency_code: "ZWD",
		alphaCode: "ZW",
	},
];

export const alphaConutryCode = {
	Afghanistan: "AF",
	"Aland Islands": "AX",
	Albania: "AL",
	Algeria: "DZ",
	"American Samoa": "AS",
	Andorra: "AD",
	Angola: "AO",
	Anguilla: "AI",
	Antarctica: "AQ",
	"Antigua And Barbuda": "AG",
	Argentina: "AR",
	Armenia: "AM",
	Aruba: "AW",
	Australia: "AU",
	Austria: "AT",
	Azerbaijan: "AZ",
	Bahamas: "BS",
	Bahrain: "BH",
	Bangladesh: "BD",
	Barbados: "BB",
	Belarus: "BY",
	Belgium: "BE",
	Belize: "BZ",
	Benin: "BJ",
	Bermuda: "BM",
	Bhutan: "BT",
	Bolivia: "BO",
	"Bosnia And Herzegovina": "BA",
	Botswana: "BW",
	"Bouvet Island": "BV",
	Brazil: "BR",
	"British Indian Ocean Territory": "IO",
	"Brunei Darussalam": "BN",
	Bulgaria: "BG",
	"Burkina Faso": "BF",
	Burundi: "BI",
	Cambodia: "KH",
	Cameroon: "CM",
	Canada: "CA",
	"Cape Verde": "CV",
	"Cayman Islands": "KY",
	"Central African Republic": "CF",
	Chad: "TD",
	Chile: "CL",
	China: "CN",
	"Christmas Island": "CX",
	"Cocos (Keeling) Islands": "CC",
	Colombia: "CO",
	Comoros: "KM",
	Congo: "CG",
	"Congo, Democratic Republic": "CD",
	"Cook Islands": "CK",
	"Costa Rica": "CR",
	CI: 'Cote D"Ivoire',
	Croatia: "HR",
	Cuba: "CU",
	Cyprus: "CY",
	"Czech Republic": "CZ",
	Denmark: "DK",
	Djibouti: "DJ",
	Dominica: "DM",
	"Dominican Republic": "DO",
	Ecuador: "EC",
	Egypt: "EG",
	"El Salvador": "SV",
	"Equatorial Guinea": "GQ",
	Eritrea: "ER",
	Estonia: "EE",
	Ethiopia: "ET",
	"Falkland Islands (Malvinas)": "FK",
	"Faroe Islands": "FO",
	Fiji: "FJ",
	Finland: "FI",
	France: "FR",
	"French Guiana": "GF",
	"French Polynesia": "PF",
	"French Southern Territories": "TF",
	Gabon: "GA",
	Gambia: "GM",
	Georgia: "GE",
	Germany: "DE",
	Ghana: "GH",
	Gibraltar: "GI",
	Greece: "GR",
	Greenland: "GL",
	Grenada: "GD",
	Guadeloupe: "GP",
	Guam: "GU",
	Guatemala: "GT",
	Guernsey: "GG",
	Guinea: "GN",
	"Guinea-Bissau": "GW",
	Guyana: "GY",
	Haiti: "HT",
	"Heard Island & Mcdonald Islands": "HM",
	"Holy See (Vatican City State)": "VA",
	Honduras: "HN",
	"Hong Kong": "HK",
	Hungary: "HU",
	Iceland: "IS",
	India: "IN",
	Indonesia: "ID",
	"Iran, Islamic Republic Of": "IR",
	Iraq: "IQ",
	Ireland: "IE",
	"Isle Of Man": "IM",
	Israel: "IL",
	Italy: "IT",
	Jamaica: "JM",
	Japan: "JP",
	Jersey: "JE",
	Jordan: "JO",
	Kazakhstan: "KZ",
	Kenya: "KE",
	Kiribati: "KI",
	Korea: "KR",
	"North Korea": "KP",
	Kuwait: "KW",
	Kyrgyzstan: "KG",
	LA: 'Lao People"s Democratic Republic',
	Latvia: "LV",
	Lebanon: "LB",
	Lesotho: "LS",
	Liberia: "LR",
	"Libyan Arab Jamahiriya": "LY",
	Liechtenstein: "LI",
	Lithuania: "LT",
	Luxembourg: "LU",
	Macao: "MO",
	Macedonia: "MK",
	Madagascar: "MG",
	Malawi: "MW",
	Malaysia: "MY",
	Maldives: "MV",
	Mali: "ML",
	Malta: "MT",
	"Marshall Islands": "MH",
	Martinique: "MQ",
	Mauritania: "MR",
	Mauritius: "MU",
	Mayotte: "YT",
	Mexico: "MX",
	"Micronesia, Federated States Of": "FM",
	Moldova: "MD",
	Monaco: "MC",
	Mongolia: "MN",
	Montenegro: "ME",
	Montserrat: "MS",
	Morocco: "MA",
	Mozambique: "MZ",
	Myanmar: "MM",
	Namibia: "NA",
	Nauru: "NR",
	Nepal: "NP",
	Netherlands: "NL",
	"Netherlands Antilles": "AN",
	"New Caledonia": "NC",
	"New Zealand": "NZ",
	Nicaragua: "NI",
	Niger: "NE",
	Nigeria: "NG",
	Niue: "NU",
	"Norfolk Island": "NF",
	"Northern Mariana Islands": "MP",
	Norway: "NO",
	Oman: "OM",
	Pakistan: "PK",
	Palau: "PW",
	"Palestinian Territory, Occupied": "PS",
	Panama: "PA",
	"Papua New Guinea": "PG",
	Paraguay: "PY",
	Peru: "PE",
	Philippines: "PH",
	Pitcairn: "PN",
	Poland: "PL",
	Portugal: "PT",
	"Puerto Rico": "PR",
	Qatar: "QA",
	Reunion: "RE",
	Romania: "RO",
	"Russian Federation": "RU",
	Rwanda: "RW",
	"Saint Barthelemy": "BL",
	"Saint Helena": "SH",
	"Saint Kitts And Nevis": "KN",
	"Saint Lucia": "LC",
	"Saint Martin": "MF",
	"Saint Pierre And Miquelon": "PM",
	"Saint Vincent And Grenadines": "VC",
	Samoa: "WS",
	"San Marino": "SM",
	"Sao Tome And Principe": "ST",
	"Saudi Arabia": "SA",
	Senegal: "SN",
	Serbia: "RS",
	Seychelles: "SC",
	"Sierra Leone": "SL",
	Singapore: "SG",
	Slovakia: "SK",
	Slovenia: "SI",
	"Solomon Islands": "SB",
	Somalia: "SO",
	"South Africa": "ZA",
	"South Georgia And Sandwich Isl.": "GS",
	Spain: "ES",
	"Sri Lanka": "LK",
	Sudan: "SD",
	Suriname: "SR",
	"Svalbard And Jan Mayen": "SJ",
	Swaziland: "SZ",
	Sweden: "SE",
	Switzerland: "CH",
	"Syrian Arab Republic": "SY",
	Taiwan: "TW",
	Tajikistan: "TJ",
	Tanzania: "TZ",
	Thailand: "TH",
	"Timor-Leste": "TL",
	Togo: "TG",
	Tokelau: "TK",
	Tonga: "TO",
	"Trinidad And Tobago": "TT",
	Tunisia: "TN",
	Turkey: "TR",
	Turkmenistan: "TM",
	"Turks And Caicos Islands": "TC",
	Tuvalu: "TV",
	Uganda: "UG",
	Ukraine: "UA",
	"United Arab Emirates": "AE",
	"United Kingdom": "GB",
	"United States": "US",
	"United States Outlying Islands": "UM",
	Uruguay: "UY",
	Uzbekistan: "UZ",
	Vanuatu: "VU",
	Venezuela: "VE",
	Vietnam: "VN",
	"Virgin Islands, British": "VG",
	"Virgin Islands, U.S.": "VI",
	"Wallis And Futuna": "WF",
	"Western Sahara": "EH",
	Yemen: "YE",
	Zambia: "ZM",
	Zimbabwe: "ZW",
};
