import { Input, Textarea } from "components/Input";
import Loading from "components/Loading";
import DeleteModal from "components/modals/DeleteModal";
import { getCategories } from "queries/categoryQueries";
import {
	addOrEditProductImages,
	createProduct,
	deleteProduct,
	editProduct,
	getProducts,
} from "queries/productQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function AdminProducts() {
	const queryClient = useQueryClient();
	const categories = useQuery("categories", getCategories);
	const { data, isLoading, isError, error } = useQuery("products", getProducts);
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
	} = useForm();

	const [addProductModalOpen, setAddProductModalOpen] = useState(false);
	const [editProductModalOpen, setEditProductModalOpen] = useState(false);
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [imagesView, setImagesView] = useState(false);
	const [addImageView, setAddImageView] = useState(false);

	const [product, setProduct] = useState("");

	const onSubmit = async (formData) => {
		createProductMutation.mutate(formData);
	};
	const onEditSubmit = async (formData) => {
		editProductMutation.mutate({ ...formData, productId: product._id });
	};
	const onImageSubmit = async (formData) => {
		const data = { productId: imagesView._id };
		data.images = imagesView.images;
		mutation.mutate({
			data,
			processing: { images: formData.images },
			query: "addImages",
		});
	};

	const imageArrayHandler = (val, images, imageArrayKey) => {
		const imageArray = images.filter((item) => item !== val && item);
		console.log(imageArray);
		const splitVal = imageArray.length > 0 && imageArray[0].split("/");
		console.log(splitVal);
		const data = {};
		data[`${imageArrayKey}`] = imageArray;
		return data;
	};

	const deleteImage = (val) => {
		const data = imageArrayHandler(val, imagesView.images, "images");
		data.productId = imagesView._id;
		console.log("images view", imagesView);
		console.log("data in delete image", data);
		mutation.mutate({ data, query: "deleteProcuredImage" });
	};

	const onCancelHandler = () => {
		console.log("cancel handler");
		setAddImageView(false);
	};

	const mutation = useMutation(addOrEditProductImages, {
		onSuccess: (data) => {
			console.log(data);
			reset();
			queryClient.invalidateQueries("products");
			setAddImageView("");
			setImagesView("");
		},
	});

	const createProductMutation = useMutation(createProduct, {
		onSuccess: (data) => {
			reset();
			setProduct("");
			setAddProductModalOpen(false);
			queryClient.invalidateQueries("products");
		},
	});
	const editProductMutation = useMutation(editProduct, {
		onSuccess: (data) => {
			reset();
			setProduct("");
			setEditProductModalOpen(false);
			queryClient.invalidateQueries("products");
		},
	});
	const deleteProductMutation = useMutation(deleteProduct, {
		onSuccess: (data) => {
			reset();
			setDeleteModalOpen(false);
			queryClient.invalidateQueries("products");
		},
	});

	const onProductModalCancel = () => {
		reset();
		setProduct();
		setDeleteModalOpen(false);
	};

	const onProductModalDelete = () => {
		deleteProductMutation.mutate(product._id);
	};

	const viewAndAddMultipleImage = (
		title,
		imagesArray,
		imagesArrayKey,
		isViewOnly
	) => {
		return (
			<>
				<div>
					<h3 className="font-bold">{title}</h3>
					<div className="flex flex-wrap">
						{imagesArray?.length > 0
							? imagesArray.map((image, index) => (
									<div className="group relative mr-4 mb-4">
										<img src={image} width={200} height={200} />
										{isViewOnly === "false" && (
											<button
												className="hidden group-hover:block absolute right-1 top-1 rounded-full bg-white w-6 h-6"
												onClick={() => deleteImage(image)}
											>
												<MdOutlineClose className="mx-auto" />
											</button>
										)}
									</div>
							  ))
							: "There are no images to display"}
					</div>
				</div>
				<div>
					{isViewOnly === "false" ? (
						addImageView === imagesView._id ? (
							<div>
								<form onSubmit={handleSubmit(onImageSubmit)}>
									<div className="flex flex-col">
										<input
											className="border-none"
											type="file"
											accept="image/png,image/jpeg,image/jpg"
											multiple
											{...register(imagesArrayKey)}
										/>
										{errors[imagesArrayKey] && (
											<span className="text-red-500">
												{errors[imagesArrayKey].message}
											</span>
										)}
									</div>
									<input
										className="hidden"
										type="text"
										value={imagesArrayKey}
										{...register("arrayKeyValue")}
									/>
									<div className="flex">
										<button
											className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
											onClick={onCancelHandler}
											type="button"
										>
											Cancel
										</button>
										<button
											className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
											type="submit"
											disabled={mutation.isLoading}
										>
											{mutation.isLoading ? "processing..." : `Upload ${title}`}
										</button>
									</div>
								</form>
							</div>
						) : (
							<button
								className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mx-4"
								onClick={() => setAddImageView(imagesView._id)}
							>
								Add {title}
							</button>
						)
					) : null}
				</div>
			</>
		);
	};

	if (isLoading || categories.isLoading) {
		return <Loading />;
	}
	if (isError || categories.isLoading) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}

	return (
		<>
			{isDeleteModalOpen && (
				<>
					{console.log("deletemodal is open")}
					<DeleteModal
						title="Are you sure you want to delete this product? All products associated with this product will be deleted?"
						onCancel={onProductModalCancel}
						onDelete={onProductModalDelete}
					/>
				</>
			)}
			{editProductModalOpen && (
				<>
					<div className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
						<div className="bg-white p-6">
							<h1 className="mb-8 ">Edit Product</h1>
							<form onSubmit={handleSubmit(onEditSubmit)}>
								<div className="">
									<Input
										title="Product Name"
										inputName="name"
										register={register}
										defaultValue={product.name || ""}
										errors={errors}
										required
										maxLength={100}
									/>
								</div>

								<div className="">
									<Textarea
										title="Description"
										inputName="description"
										register={register}
										defaultValue={product.description || ""}
										errors={errors}
										required
										maxLength={4000}
									/>
								</div>

								<div className="">
									<Input
										title="Available Quantity"
										inputName="availableQuantity"
										type="number"
										register={register}
										defaultValue={product.availableQuantity || ""}
										errors={errors}
										required
									/>
								</div>

								<div className="">
									<Input
										title="Price"
										inputName="price"
										register={register}
										defaultValue={product.price || ""}
										type="number"
										errors={errors}
										required
									/>
								</div>

								<div className="flex flex-col">
									<label htmlFor="category">Select Category</label>
									{/* The default value for selection is the id of the mapped item */}
									<select
										defaultValue={product.category?._id || ""}
										{...register("category", {
											required: {
												value: true,
												message: "This field is required",
											},
										})}
										className="px-4 border-2 bg-blue-100 rounded-md py-2 mb-4"
										// defaultValue={location.state?.itemType || ""}
										// onChange={(e) => setSelectedItemType(e.target.value)}
									>
										<option value=""> -- select a category -- </option>
										{categories.data &&
											categories.data.map((item) => (
												<option value={item._id} className="capitalize">
													{item.name}
												</option>
											))}
									</select>
								</div>

								<div className="flex">
									<button
										className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
										onClick={() => {
											setProduct();
											setEditProductModalOpen(false);
											reset();
										}}
									>
										cancel
									</button>

									<button
										className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 disabled:bg-blue-200"
										type="submit"
										disabled={editProductMutation.isLoading}
									>
										{editProductMutation.isLoading
											? "Processing..."
											: "Edit Item"}
									</button>
								</div>
							</form>
						</div>
					</div>
				</>
			)}
			<div className="">
				<h1 className="text-center font-bold text-4xl mb-10">Products</h1>
				{addProductModalOpen && (
					<>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex lg:min-w-[800px]">
								<div className="mr-6">
									<Input
										title="Product Name"
										inputName="name"
										register={register}
										errors={errors}
										required
										maxLength={100}
									/>
								</div>
							</div>
							<div className="flex lg:min-w-[800px]">
								<div className="mr-6">
									<Textarea
										title="Description"
										inputName="description"
										register={register}
										errors={errors}
										required
										maxLength={4000}
									/>
								</div>
								<div className="flex flex-col mr-6">
									<label htmlFor="category">Select Category</label>
									<select
										{...register("category", {
											required: {
												value: true,
												message: "This field is required",
											},
										})}
										className="px-4 border-2 bg-blue-100 rounded-md py-2"
										// defaultValue={location.state?.itemType || ""}
										// onChange={(e) => setSelectedItemType(e.target.value)}
									>
										<option value=""> -- select a category -- </option>
										{categories.data &&
											categories.data.map((item) => (
												<option value={item._id} className="capitalize">
													{item.name}
												</option>
											))}
									</select>
								</div>
							</div>

							<div className="flex lg:min-w-[800px]">
								<div className="mr-6">
									<Input
										title="Available Quantity"
										inputName="availableQuantity"
										type="number"
										register={register}
										defaultValue={product?.availableQuantity || ""}
										errors={errors}
										required
									/>
								</div>

								<div className="mr-6">
									<Input
										title="Price"
										inputName="price"
										register={register}
										defaultValue={product?.price || ""}
										type="number"
										errors={errors}
										required
									/>
								</div>
							</div>
							<div className="flex">
								<button
									className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
									onClick={() => {
										setAddProductModalOpen(false);
										reset();
									}}
								>
									cancel
								</button>

								<button
									className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 disabled:bg-blue-200"
									type="submit"
									disabled={createProductMutation.isLoading}
								>
									{createProductMutation.isLoading
										? "Processing..."
										: "Add Item"}
								</button>
							</div>
						</form>
					</>
				)}

				{addProductModalOpen ? null : (
					<button
						className="bg-blue-500 px-4 py-2 rounded-md mb-4"
						onClick={() => setAddProductModalOpen(true)}
					>
						Add New Product
					</button>
				)}
				{data?.length > 0 ? (
					<>
						<table className="border">
							<thead>
								<tr className="border border-bottom">
									<th className="px-4 py-3">Name</th>
									<th className="px-4 py-3">Description</th>
									<th className="px-4 py-3">Available Quantity</th>
									<th className="px-4 py-3">Price</th>
									<th className="px-4 py-3">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item) => (
									<React.Fragment key={item._id}>
										<tr className="">
											<td className="px-4">{item.name}</td>
											<td className="px-4">{item.description}</td>
											<td className="px-4">{item.availableQuantity}</td>
											<td className="px-4">{item.price}</td>
											<td className="px-4">
												<div
													className="inline-block mr-2"
													onClick={() => {
														imagesView?._id === item._id
															? setImagesView("")
															: setImagesView(item);
													}}
												>
													{imagesView?._id === item._id
														? "Hide Images"
														: "View Images"}
												</div>
												<button
													className="bg-blue-500 px-4 py-2 rounded-md mr-4 my-2"
													onClick={() => {
														setProduct(item);
														setEditProductModalOpen(true);
													}}
												>
													Edit
												</button>
												<button
													className="bg-gray-500 px-4 py-2 rounded-md"
													onClick={() => {
														setProduct(item);
														setDeleteModalOpen(true);
													}}
												>
													Delete Product
												</button>
											</td>
										</tr>
										{imagesView?._id === item._id && (
											<tr>
												<td colSpan="10">
													{viewAndAddMultipleImage(
														"Images",
														item.images,
														"images",
														"false"
													)}
												</td>
											</tr>
										)}
									</React.Fragment>
								))}
							</tbody>
						</table>
					</>
				) : (
					<div>There are no items to display</div>
				)}
			</div>
		</>
	);
}
