import Loading from "components/Loading";
import { successToast } from "helpers/Toast";
import {
	adminConfirmedDelivered,
	adminConfirmReviewed,
	getOrders,
	userAckonwledgedReviewed,
} from "queries/orderQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function Shipped() {
	const role = localStorage.getItem("userRole");
	const queryClient = useQueryClient();
	const [itemId, setItemId] = useState("");
	const [orderId, setOrderId] = useState("");
	const [isPriceModalOpen, setPriceModalOpen] = useState(false);
	const [isConfirmReviewedOpen, setConfirmReviewedOpen] = useState(false);
	const [reviewData, setReveiwData] = useState();
	const [paymentGatewayOpen, setPaymentGatewayOpen] = useState(false);

	let isReviewedVerifier = 0;
	const {
		handleSubmit,
		formState: { errors },
		register,
		reset,
	} = useForm();
	const { data, isLoading, isError, error } = useQuery(
		["orders", "shipped"],
		() => getOrders("shipped")
	);

	const mutation = useMutation(adminConfirmedDelivered, {
		onSuccess: (data) => {
			console.log(data);
			successToast("The order has been successfully delivered");
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});

	const confirmMutation = useMutation(adminConfirmReviewed, {
		onSuccess: (data) => {
			console.log(data);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});

	const userAcknowledgedMutation = useMutation(userAckonwledgedReviewed, {
		onSuccess: (data) => {
			console.log(orderId);
			console.log(data);
			setPaymentGatewayOpen(false);
			successToast(
				"Successfully completed Payment. The items will be procured soon"
			);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
		onError: () => {
			console.log(orderId);
		},
	});

	const onCancelHandler = () => {
		reset();
		setOrderId("");
		setItemId("");
		setPriceModalOpen(false);
	};

	const detailsHandler = (orderId, itemId) => {
		setOrderId(orderId);
		setItemId(itemId);
		setPriceModalOpen(true);
	};
	const onSubmit = (formData) => {
		console.log(formData);
		console.log("OrderId and ItemID");
		console.log(formData);
		console.log(orderId);
		console.log(itemId);
		const newData = { ...formData, orderId, itemId };
		console.log(newData);
		mutation.mutate(newData);
	};

	const verifier = (price) => {
		// console.log(price);
		if (price) {
			isReviewedVerifier += 1;
		}
		// console.log(isReviewedVerifier);
	};
	const resetVerifier = () => {
		isReviewedVerifier = 0;
	};
	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}

	return (
		<>
			{paymentGatewayOpen && (
				<div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
					<div className="bg-white p-6">
						<h1 className="mb-8 ">Payment Gateway</h1>
						<div>
							<div className="mb-6">
								The total price to procure the items are 1500 USD
							</div>
							<button
								className="bg-gray-500 px-4 py-2 rounded-md mr-6"
								onClick={() => setPaymentGatewayOpen(false)}
							>
								Cancel
							</button>
							<button
								className="bg-blue-700 font-bold py-2 text-white px-6 rounded-md m-auto"
								onClick={() => userAcknowledgedMutation.mutate(orderId)}
							>
								Pay Now
							</button>
						</div>
					</div>
				</div>
			)}
			{/* {isPriceModalOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Add Details</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col ">
                <label htmlFor="procurableQuantity" className="text-left">
                  Proculable Quantity
                </label>
                <input
                  className="input"
                  type="number"
                  placeholder="procureable quantity "
                  {...register("procurableQuantity", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.procurableQuantity && (
                  <span className="text-red-500">
                    {errors.procurableQuantity.message}
                  </span>
                )}
              </div>

              <div className="flex flex-col ">
                <label htmlFor="price" className="text-left">
                  Price
                </label>
                <input
                  className="input"
                  placeholder="price"
                  {...register("price", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.price && (
                  <span className="text-red-500">{errors.price.message}</span>
                )}
              </div>
              <div className="flex flex-col ">
                <label htmlFor="totalPrice" className="text-left">
                  Total Price
                </label>
                <input
                  className="input"
                  placeholder="Total Price "
                  {...register("totalPrice", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.totalPrice && (
                  <span className="text-red-500">
                    {errors.totalPrice.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col">
                <Input
                  title="Tentative Date"
                  inputName="tentativeDate"
                  inputType="date"
                  defaultValue={
                    productItem.tentativeDate &&
                    new Date(productItem?.tentativeDate)
                      .toISOString()
                      .substring(0, 10)
                  }
                  register={register}
                  errors={errors}
                  required
                  min
                />
              </div>

              <div>
                <button
                  className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                  onClick={onCancelHandler}
                >
                  Cancel
                </button>
                <button className="bg-blue-500 px-4 py-2 rounded-md">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )} */}
			{/* TODO: Create this flow after asking if it is required for the usecase and what details should be displayed in this */}
			{/* {isConfirmReviewedOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Confirm Review Details</h1>
            <div>{reviewData.map((data) => (

<div>

            ))}</div>

            <div>
              <button
                className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                onClick={onCancelHandler}
              >
                Cancel
              </button>
              <button className="bg-blue-500 px-4 py-2 rounded-md">
                Submit
              </button>
            </div>
          </div>
        </div>
      )} */}
			<div>
				{/* delete Modal */}

				{data.length !== 0 ? (
					<div>
						{data.map((orders, index) => (
							<div className="mt-6" key={orders._id}>
								<div className="hidden">{(isReviewedVerifier = 0)}</div>

								<div className="flex">
									<h3 className="font-medium mr-4">Order ID:</h3>
									<p>{orders.uniqueOrderId}</p>
								</div>
								<div className="flex">
									<h3 className="font-medium mr-4">User Email:</h3>
									<p>{orders.userEmail}</p>
								</div>
								<div className="overflow-x-auto">
									<table classname=" text-sm  text-gray-500 dark:text-white overflow-x-scroll">
										<thead className="text-xs text-gray-700 uppercase bg-[#115383] dark:bg-[#115383] dark:text-white">
											<tr>
												<th className="px-4 py-3">
													{orders.isMedicine ? "Name" : "Product Name"}
												</th>
												{orders.isMedicine ? null : (
													<th className="px-4 py-3">Quantity</th>
												)}
												{/* <th className="px-4 py-3">Description</th>
                      {role === "user" ? (
                        orders.isReviewedEmailSent ? (
                          <>
                            <th className="px-4 py-3">Procurable Quantity</th>
                            <th className="px-4 py-3">Price/unit</th>
                            <th className="px-4 py-3">Total Price</th>
                          </>
                        ) : null
                      ) : (
                        <>
                          <th className="px-4 py-3">Procurable Quantity</th>
                          <th className="px-4 py-3">Price/unit</th>
                          <th className="px-4 py-3">Total Price</th>
                        </>
                      )} */}

												<th className="px-4 py-3"></th>
											</tr>
										</thead>
										<tbody key={orders._id} className="mb-8 border">
											{orders.items
												.filter(
													(item) => item.isProcurable && item.userAcknowledge
												)
												.map((product) => (
													<tr key={product._id}>
														<td className="text-center px-6">{product.name}</td>
														<td className="text-center px-6">
															{product.quantity}
														</td>
														{/* <td className="text-center px-6 truncate  max-w-[300px]">
                          {product.description}
                        </td>
                        {role === "user" ? (
                          orders.isReviewedEmailSent === true ? (
                            <>
                              <td className="text-center px-6">
                                {product.procurableQuantity || "-"}
                              </td>
                              <td className="text-center px-6">
                                {product.price || "-"}
                              </td>
                              <td className="text-center px-6">
                                {product.totalPrice || "-"}
                              </td>
                            </>
                          ) : null
                        ) : (
                          <>
                            <td className="text-center px-6">
                              {product.procurableQuantity || "-"}
                            </td>
                            <td className="text-center px-6">
                              {product.price || "-"}
                            </td>
                            <td className="text-center px-6">
                              {product.totalPrice || "-"}
                            </td>
                          </>
                        )} */}
														<td className="text-center px-6">
															<div className="flex items-center">
																{/* <Link to="/" className="mr-4">
                              View details
                            </Link> */}
																{/* {localStorage.getItem("userRole") ===
                            "user" ? null : (
                              <button
                                className="bg-gray-700 px-3 py-2 text-white rounded-lg"
                                onClick={() =>
                                  detailsHandler(orders._id, product._id)
                                }
                              >
                                Add Details
                              </button>
                            )} */}
															</div>
														</td>
														{verifier(product.price)}
													</tr>
												))}
										</tbody>
									</table>
								</div>
								<div>Total weight is: {orders.weight} kg</div>
								<div className="text-capitalize">
									Courier is: {orders.courier}
								</div>
								<div>Tracking Number is: {orders.trackingNumber}</div>
								<button
									className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
									onClick={() => {
										setOrderId(orders._id);
										mutation.mutate(orders._id);
									}}
								>
									{localStorage.getItem("userRole") === "user"
										? "Order Recieved"
										: "Order Delivered"}
								</button>
								{/* {role === "user" ? (
                  orders.isReviewedEmailSent ? (
                    <>
                      <button className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4 mr-4 ">
                        Contact Support
                      </button>
                      <button
                        className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                        onClick={() => {
                          setOrderId(orders._id);
                          setPaymentGatewayOpen(true);
                        }}
                      >
                        Confirm Items
                      </button>
                    </>
                  ) : null
                ) : null}

                {role !== "user" ? (
                  orders.isReviewedEmailSent ? ( // <button
                    //   className="bg-green-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                    //   onClick={() => {
                    //     confirmMutation.mutate(orders._id);
                    //   }}
                    // >
                    //   Resend Email
                    // </button>
                    <div className="text-green-500">Customer is notified</div>
                  ) : isReviewedVerifier === orders.items.length ? (
                    <button
                      className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                      onClick={() => {
                        confirmMutation.mutate(orders._id);
                      }}
                    >
                      Confirm Reviewed
                    </button>
                  ) : null
                ) : null}*/}
							</div>
						))}

						{/* <div className="mt-6">
              <button
                className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-8"
                onClick={() => deleteOrderHandler(orders._id)}
              >
                Delete Order
              </button>
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8"
                onClick={() => submitOrderMutation.mutate(orders._id)}
              >
                Submit Order
              </button>
            </div> */}
					</div>
				) : (
					<div className="">No Orders in Shipped</div>
				)}
			</div>
		</>
	);
}
