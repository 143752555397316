import { create } from "zustand";

const useErrorStore = create(() => ({
	message: "",
	status: "",
	statusRes: "",
	statusText: "",
}));

export default useErrorStore;
