import { errorToast } from "helpers/Toast";
import { addItemToOrder, createOrder } from "queries/orderQueries";
import { useMutation, useQueryClient } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Button } from "components/Button";
import { useForm } from "react-hook-form";

export default function CustomizeAddItemInputs() {
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		watch,
	} = useForm();

	const location = useLocation();
	const navigate = useNavigate();
	// console.log("location.state");
	// console.log(location.state);
	const queryClient = useQueryClient();
	const { orderId } = useParams();

	// Draft mentioned in query keys is status which is sent as url parameter

	const watchIsPremium = watch("isPremiumAvailed");

	const resetHandler = () => {
		reset();
	};

	const mutationSuccessHandler = (data) => {
		console.log(data);
		reset();

		queryClient.invalidateQueries("orders");
		queryClient.invalidateQueries("allOrders");
		// setAddItem(false);
		navigate(`/user/customized-buy/${data._id}`);
	};

	const createOrderMutation = useMutation(createOrder, {
		onSuccess: (data) => {
			mutationSuccessHandler(data);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	const mutation = useMutation(addItemToOrder, {
		onSuccess: (data) => {
			mutationSuccessHandler(data);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	const isNewOrder = orderId === null || orderId === undefined ? true : false;
	// let existingOrderData = data && data.filter((item) => !item.isMedicine);
	const onSubmitHandler = (formData) => {
		formData["orderType"] = "customized";
		isNewOrder
			? createOrderMutation.mutate(formData)
			: mutation.mutate({ formData, orderId });
	};

	const onSubmit = async (formData) => {
		console.log("formData");
		console.log(formData);
		// tertiary function to facilitate push and put request based on condition
		formData = { ...formData, itemType: "default" };
		onSubmitHandler(formData);
	};

	return (
		<div className=" container mx-auto">
			<h3 className="h3 mb-4 text-center">Customize Products</h3>
			<div className="flex justify-center">
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="flex flex-col justify-center"
				>
					<div className="flex lg:min-w-[800px]">
						<div className="mr-6">
							<label htmlFor="name" className="">
								Product Name
							</label>
							<input
								className="input"
								placeholder="Product Name"
								{...register("name", {
									required: {
										value: true,
										message: "This field is required",
									},
									maxLength: {
										value: 100,
										message: "The maximum characters allowed is 100",
									},
								})}
							/>
							{errors.name && (
								<span className="text-red-500">{errors.name.message}</span>
							)}
						</div>
						<div>
							<label htmlFor="quantity" className="">
								Quantity
							</label>
							<input
								type="number"
								className="input"
								placeholder="Enter Required Quantity "
								{...register("quantity", {
									required: {
										value: true,
										message: "This field is required",
									},
									max: {
										value: 1000,
										message: "The Maximum Quantity allowed is 1000",
									},
									min: {
										value: 1,
										message: "The Minimum Quantity allowed is 1",
									},
								})}
							/>
							{errors.quantity && (
								<span className="text-red-500">{errors.quantity.message}</span>
							)}
						</div>
					</div>
					<div className="flex lg:min-w-[800px]">
						<div className="mr-6">
							<label htmlFor="description" className="">
								Product Description/URL
							</label>
							<textarea
								className="textarea"
								rows="5"
								placeholder="Product Description"
								{...register("description", {
									required: {
										value: true,
										message: "This field is required",
									},
									maxLength: {
										value: 4000,
										message: "The maximum characters allowed is 4000",
									},
								})}
							/>
							{errors.description && (
								<span className="text-red-500">
									{errors.description.message}
								</span>
							)}
						</div>

						<div className="flex flex-col">
							<label htmlFor="userImages" className="mb-4">
								Images
							</label>
							<input
								className="border-none"
								type="file"
								accept="image/png,image/jpeg,image/jpg"
								multiple
								{...register("userImages", {})}
							/>
							{errors.userImages && (
								<span className="text-red-500">
									{errors.userImages.message}
								</span>
							)}
						</div>
					</div>

					{/* <div className="flex flex-col lg:min-w-[800px]">
            <div className="mr-6 mb-4">
              <label>
                <input type="checkbox" {...register("isPremiumAvailed")} />{" "}
                <span>Avail Premium services </span>
                <span className="">
                  <Disclosure>
                    <DisclosureButton className="pt-2">
                      <BsInfoCircle />
                    </DisclosureButton>
                    <DisclosurePanel>
                      Get Multiple photos of procured product with personal
                      attention to items as well as return policy
                    </DisclosurePanel>
                  </Disclosure>
                </span>
              </label>
              {errors.isPremiumAvailed && (
                <span className="text-red-500">
                  {errors.isPremiumAvailed.message}
                </span>
              )}
            </div>

            {watchIsPremium && (
              <div className="flex flex-col">
                <Textarea
                  title="Requirements"
                  inputName="requirements"
                  placeholder={
                    "Please type in requirements: \neg: 1 - Measure the size of t-shirt shoulder size to be 28cm \n2 - Measure the length to"
                  }
                  register={watchIsPremium && register}
                  errors={errors}
                  required
                  maxLength={6000}
                />
              </div>
            )}
          </div> */}
					<div className="flex">
						{/* <CancelButton
              className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
              onClick={() => {
                reset();
                setSelectedItemType("");
                setAddItem(false);
              }}
            >
              cancel
            </CancelButton> */}

						<Button
							type="submit"
							disabled={createOrderMutation.isLoading || mutation.isLoading}
						>
							{createOrderMutation.isLoading || mutation.isLoading
								? "Processing..."
								: "Add Item"}
						</Button>
					</div>
				</form>
			</div>
			<Outlet />
		</div>
	);
}
