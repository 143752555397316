import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InnerTable from "./InnerTable";

const OrderActionsTableHeader = ({}) => {
	return (
		<thead className="">
			<tr className="">
				<th className="px-2 text-center capitalize">Id</th>
				<th className="px-2 text-center capitalize">Services</th>
				<th className="px-2 text-center capitalize">Start Date</th>
				<th className="px-2 text-center capitalize">Status</th>
			</tr>
		</thead>
	);
};

const OrderActionsTableBody = ({
	editOrderNavigator,
	setInnerdetails,
	innerDetails,
	item,
	index,
}) => {
	return (
		<Fragment key={item._id}>
			<tr>
				<td className="px-2 text-center capitalize">
					{item.uniqueOrderId || index + 1}
				</td>
				<td className="px-2 text-center capitalize">
					{item.orderType || "- "}
				</td>
				<td className="px-2 text-center capitalize">
					{new Date(item.createdAt).toLocaleDateString("en-IN")}
				</td>

				<td className="px-2 text-center capitalize">{item.orderStatus}</td>

				{/* <td className="px-2 text-center capitalize ">
					{item.orderStatus === "draft" && (
						// <DraftOrderActionButtons order={item} />
						<Button onClick={() => editOrderNavigator(item)}>Edit Order</Button>
					)}
					{item.orderStatus === "review" &&
						item.isReviewedEmailSent === true && (
							<Link
								to={`/user/actions/${item._id}`}
								className="textunderline text-blue-500"
							>
								Click to Approve
							</Link>
						)}
				</td> */}
			</tr>

			<tr>
				<td colSpan="10">
					<InnerTable data={item} />
				</td>
			</tr>
		</Fragment>
	);
};

const OrderActionsTable = ({ data }) => {
	const [innerDetails, setInnerdetails] = useState(false);
	console.log("innerdetails ", innerDetails);
	const navigate = useNavigate();
	const editOrderNavigator = (data) => {
		if (data.orderType === "buyYourself") {
			navigate(`/user/buy-yourself/${data._id}`);
		}
		if (data.orderType === "weBuy") {
			navigate(`/user/we-buy-4-u/${data._id}`);
		}
		if (data.orderType === "medicine") {
			navigate(`/user/medicine/${data._id}`);
		}
		if (data.orderType === "customized") {
			navigate(`/user/customized-buy/${data._id}`);
		}
	};
	return (
		<div className="overflow-x-auto">
			<table className="block overflow-x-scroll whitespace-nowrap mt-4">
				<OrderActionsTableHeader />
				<tbody>
					{data.length !== 0 ? (
						<OrderActionsTableBody
							editOrderNavigator={editOrderNavigator}
							innerDetails={innerDetails}
							setInnerdetails={setInnerdetails}
							item={data}
							index={0}
						/>
					) : (
						<tr>
							<td colSpan={10} className=" px-2 md:text-center">
								No Orders History
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default OrderActionsTable;
