import { Button } from "components/Button";
import { Input } from "components/Input";
import Loading from "components/Loading";
import { errorToast } from "helpers/Toast";
import {
	addInhouseItemToOrder,
	createInhouseOrder,
} from "queries/orderQueries";
import { getSingleProduct } from "queries/productQueries";
import { useState } from "react";
import { Carousel } from "react-carousel-minimal";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import useAuthStore from "stores/AuthStore";
import Dummy from "../assets/dummy.png";

export default function ProductDetails() {
	const { productId, orderId } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
	const [modal, setModal] = useState(false);
	const { data, isLoading, isError, error } = useQuery(
		["product", productId],
		() => getSingleProduct(productId)
	);

	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		watch,
	} = useForm();

	// data && console.log(data);
	const convertImageArrayToObject = () => {
		let convertedNewObject = [];
		data?.images?.map((item) => {
			convertedNewObject = [
				...convertedNewObject,
				{ image: item, caption: "" },
			];
		});
		console.log("new object :", convertedNewObject);
		return convertedNewObject;
	};
	const NewImagesData = data && convertImageArrayToObject();

	const inHouseHandler = () => {
		isLoggedIn
			? setModal(true)
			: navigate("/login", {
					replace: true,
					state: { addItem: true, productId },
			  });
	};
	const onSubmit = (formData) => {
		console.log(formData);
		const dataInfo = {
			name: data.name,
			description: data.description,
			quantity: formData.quantity,
			itemType: "cart",
			procuredImages: data.images,
			productId: data._id,
			orderId,
		};
		orderId ? mutation.mutate(dataInfo) : createOrderMutation.mutate(dataInfo);
	};

	const mutationSuccessHandler = (data) => {
		console.log(data);
		reset();

		queryClient.invalidateQueries("orders");
		queryClient.invalidateQueries("allOrders");
		// setAddItem(false);
		navigate(`/user/inhouse/${data._id}`);
	};

	const createOrderMutation = useMutation(createInhouseOrder, {
		onSuccess: (data) => {
			mutationSuccessHandler(data);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	const mutation = useMutation(addInhouseItemToOrder, {
		onSuccess: (data) => {
			mutationSuccessHandler(data);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	if (isLoading) {
		return <Loading />;
	}

	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}
	return (
		<div className="bg-[#F0FEFC] flex justify-center">
			<div className="container mx-auto my-12">
				<div className="flex gap-8">
					{/* <div className="flex flex-col gap-8">
            <img src={Dummy} alt="Image" />
            <div className="flex gap-2">
              <img
                src={Dummy}
                alt="Image"
                width={100}
                height={100}
                className="inline-block"
              />
              <img
                src={Dummy}
                alt="Image"
                width={100}
                height={100}
                className="inline-block"
              />
            </div>
          </div> */}
					<div className="w-[600px]">
						{NewImagesData.length !== 0 ? (
							<Carousel
								data={NewImagesData}
								width="600px"
								height="400px"
								radius="10px"
								slideNumber={true}
								captionPosition="bottom"
								dots={true}
								pauseIconColor="white"
								pauseIconSize="40px"
								slideBackgroundColor="darkgrey"
								slideImageFit="cover"
								thumbnails={true}
							/>
						) : (
							<img src={Dummy} />
						)}
					</div>
					{/* {convertImageArrayToObject()} */}
					<div className=" max-w-[600px]">
						<h2 className="font-bold text-2xl capitalize">{data.name}</h2>
						<div>{data.description}</div>
						<div>Price: ₹{data.price}/-</div>
						<div className="mt-4">
							{data.availableQuantity < 1 && <div>Out of Stock</div>}
							<Button
								className="bg-blue-500 px-4 py-2 rounded-md"
								onClick={inHouseHandler}
								disabled={data.availableQuantity < 1}
							>
								Add item to order
							</Button>
						</div>
						{modal && (
							<div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
								<div className="bg-white p-6">
									<h1 className="mb-8 ">Add Items</h1>
									<form onSubmit={handleSubmit(onSubmit)}>
										<Input
											title="Quantity"
											inputName="quantity"
											inputType="number"
											register={register}
											errors={errors}
											required
											placeholder="eg:25"
											max={data.availableQuantity}
										/>
										<div className="flex">
											{/* <CancelButton
          className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
          onClick={() => {
            reset();
            setSelectedItemType("");
            setAddItem(false);
          }}
        >
          cancel
        </CancelButton> */}

											<Button
												type="submit"
												disabled={
													createOrderMutation.isLoading || mutation.isLoading
												}
											>
												{createOrderMutation.isLoading || mutation.isLoading
													? "Processing..."
													: "Add Item"}
											</Button>
										</div>
									</form>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
