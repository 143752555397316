import { Button } from "components/Button";
import SupportButton from "components/SupportButton";

import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const ReadyToShipActionButtons = ({ order }) => {
	const role = localStorage.getItem("userRole");
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	return (
		<div>
			{role === "user" ? (
				order.isLogisticsChargePaid ? (
					<div className="text-cyan-700 mt-2">Order will be shipped soon</div>
				) : (
					<>
						<SupportButton />
						<Button
							className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
							// onClick={() => userAcknowledgedMutation.mutate(order._id)}
							onClick={() =>
								navigate(`/user/payment/${order._id}`, {
									state: {
										orderId: order._id,
										cost: order.totalShippingCost,
										country: order.userId.shippingAddress.country,
										type: "shipping",
									},
								})
							}
						>
							Pay Shipping Cost
						</Button>
					</>
				)
			) : null}
		</div>
	);
};

export default ReadyToShipActionButtons;
