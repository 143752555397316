import { Button } from "components/Button";
import Loading from "components/Loading";
import { successToast } from "helpers/Toast";
import { getUnVerifiedUsers, verifyUser } from "queries/userQueries";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

const AddressView = ({ item }) => {
	return (
		<div className="bg-teal-100 px-4 py-2 mx-2 inline-block">
			<div>
				<div className="mb-4">
					Name: {item.firstName} {item.lastName}
				</div>
				<div className="mb-4">Email:{item.email}</div>
			</div>
			<div className="flex  gap-3">
				<div>
					<div className="underline mt-4 mb-2 font-bold">Shipping Address</div>
					<div className="mb-4">
						Recipient Name: {item.shippingAddress.name}
					</div>
					<div className="mb-4">
						Address:{item.shippingAddress.addressline1},
						{item.shippingAddress.addressline2},{item.shippingAddress.city},
						{item.shippingAddress.state},{item.shippingAddress.country}
					</div>
					<div className="mb-4">
						Pincode/Zipcode:{item.shippingAddress.pincode}
					</div>
					<div className="mb-4">Phone: {item.shippingAddress.telephone}</div>
				</div>

				<div>
					<div className="underline mt-4 mb-2 font-bold">Indian Address</div>
					<div className="mb-4">Recipient Name: {item.localAddress.name}</div>
					<div className="mb-4 ">
						Address:{item.localAddress.addressline1},
						{item.localAddress.addressline2},{item.localAddress.city},
						{item.localAddress.state},{item.localAddress.country}
					</div>
					<div className="mb-4">
						Pincode/Zipcode:{item.localAddress.pincode}
					</div>
					<div className="mb-4">Phone: {item.localAddress.telephone}</div>
				</div>
			</div>
		</div>
	);
};

export default function UsersList() {
	const [showDetails, setDetails] = useState("");
	const queryClient = useQueryClient();
	const { data, isLoading, isError } = useQuery(
		"getUnVerifiedUsers",
		getUnVerifiedUsers
	);

	const mutation = useMutation(verifyUser, {
		onSuccess: (data) => {
			successToast("Successfully Verified User");
			queryClient.invalidateQueries("getUnVerifiedUsers");
		},
	});

	if (isLoading) {
		return <Loading />;
	}
	return (
		<div className="mt-8">
			<div className="h3">Unverified Users</div>
			<div className="mt-6">
				<table className="border">
					<thead className="border border-bottom-1">
						<tr>
							<td className="mx-2 px-4">Name</td>
							<td className="mx-2 px-4">Indian Contact Name</td>
							<td className="mx-2 px-4">Indian Contact Number</td>
							<td className="mx-2 px-4">Actions</td>
						</tr>
					</thead>
					<tbody>
						{data.unVerifiedUser.map((item) => (
							<React.Fragment key={item._id}>
								<tr>
									<td className="px-2">
										{item.firstName} {item.lastName}
									</td>
									<td className="px-2">{item.localAddress.name}</td>
									<td className="px-2">{item.localAddress.telephone}</td>
									<td className="px-2">
										<Button
											onClick={() => {
												if (showDetails !== item._id) {
													setDetails(item._id);
												} else {
													setDetails("");
												}
											}}
										>
											View Details
										</Button>
										<Button onClick={() => mutation.mutate({ id: item._id })}>
											Verify User
										</Button>
									</td>
								</tr>
								{showDetails === item._id ? (
									<tr>
										<td colSpan={4}>
											<div className="inline-block">
												<AddressView item={item} />
											</div>
										</td>
									</tr>
								) : null}
							</React.Fragment>
						))}
					</tbody>
				</table>
			</div>
			<div className="h3 mt-6">Verified Users</div>
			<div className="mt-6">
				<table className="border">
					<thead className="border border-bottom-1">
						<tr>
							<td className="mx-2 px-4">Name</td>
							<td className="mx-2 px-4">Indian Contact Name</td>
							<td className="mx-2 px-4">Indian Contact Number</td>
							<td className="mx-2 px-4">Actions</td>
						</tr>
					</thead>
					<tbody>
						{data.verifiedUser.map((item) => (
							<React.Fragment key={item._id}>
								<tr>
									<td className="px-2">
										{item.firstName} {item.lastName}
									</td>
									<td className="px-2">{item.localAddress.name}</td>
									<td className="px-2">{item.localAddress.telephone}</td>
									<td className="px-2">
										<Button
											onClick={() => {
												if (showDetails !== item._id) {
													setDetails(item._id);
												} else {
													setDetails("");
												}
											}}
										>
											View Details
										</Button>
									</td>
								</tr>
								{showDetails === item._id ? (
									<tr>
										<td colSpan={4}>
											<div className="inline-block">
												<AddressView item={item} />
											</div>
										</td>
									</tr>
								) : null}
							</React.Fragment>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
