import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import { userAckonwledgedReviewedItem } from "queries/orderQueries";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

const InnerTable = ({ data, hideActions }) => {
	const queryClient = useQueryClient();
	const userAcknowledgeItemMutation = useMutation(
		userAckonwledgedReviewedItem,
		{
			onSuccess: (data) => {
				console.log(data);
				queryClient.invalidateQueries("allOrders");
				queryClient.invalidateQueries("orders");
			},
		}
	);
	return (
		<>
			<div className="container  mx-auto m-4 pb-4 bg-[#B7D9E2]">
				<table className="w-full">
					<thead className="">
						<tr className="">
							<th className="px-2 text-center capitalize">Product Name</th>
							{data.orderStatus === "review" ? (
								<>
									<th className="px-2 text-center capitalize">
										Required Quantity
									</th>
									<th className="px-2 text-center capitalize">
										Procurable Quantity
									</th>
								</>
							) : (
								<th className="px-2 text-center capitalize">Quantity</th>
							)}
							<th className="px-2 text-center capitalize">Description</th>
							<th className="px-2 text-center capitalize">Price</th>
							<th className="px-2 text-center capitalize">Details</th>
							{hideActions ? null : (
								<th className="px-2 text-center capitalize">Actions</th>
							)}
						</tr>
					</thead>
					<tbody>
						{data.items.map((product, index) => (
							<tr key={product._id}>
								<td className="px-2 text-center capitalize">
									{product.name || "-"}
								</td>
								{data.orderStatus === "review" ? (
									<>
										<td className="px-2 text-center capitalize">
											{product.quantity || "-"}
										</td>
										<td className="px-2 text-center capitalize">
											{product.procurableQuantity || "-"}
										</td>
									</>
								) : (
									<td className="px-2 text-center capitalize">
										{product.procurableQuantity || product.quantity || "-"}
									</td>
								)}

								<td className="px-2 text-center capitalize">
									{product.description || "-"}
								</td>
								<td className="px-2 text-center capitalize">
									{product.price || "-"}
								</td>
								<td className="px-2 text-center capitalize underline">
									{/* {viewDetailsOpen ? (
                        <button
                          // to="/"
                          className="mr-4"
                          onClick={() => setViewDetailsOpen(false)}
                        >
                          Hide details
                        </button>
                      ) : (
                        <button
                          // to="/"
                          className="mr-4"
                          onClick={() => setViewDetailsOpen(true)}
                        >
                          View details
                        </button>
                      )} */}
									<Link
										className="text-[#0A2B42]"
										to={`/user/item/${product._id}`}
									>
										View
									</Link>
								</td>
								{hideActions ? null : (
									<td className="px-2 text-center capitalize">
										{data.orderStatus === "review" &&
											(data.isReviewedEmailSent && !product.userAcknowledge ? (
												<Button
													onClick={() => {
														const data = {
															itemId: product._id,
															userAcknowledge: !product.userAcknowledge,
														};

														userAcknowledgeItemMutation.mutate(data);
													}}
													disabled={!product.isProcurable}
												>
													{!product.userAcknowledge && "Approve Item"}
												</Button>
											) : (
												"Approved ✓"
											))}
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default InnerTable;
