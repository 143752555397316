const ProhibitedItems = () => {
	return (
		<div className="p-6 dashboard-main-colors">
			<div className="max-w-[800px] mx-auto">
				<h3 className="font-bold mb-3 text-center">
					Export Prohibited Items Listed.
				</h3>
				<p>Prohibited Items what we cannot ship internationally from India.</p>
				<p className="mb-4">Last Updated: 9th Jan 2022</p>
				<div className="grid  grid-cols-1 lg:grid-cols-3 gap-2">
					<ul className="">
						<li className="flex">
							<span className="mr-1">- </span>Adult Material
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Agriculture Products (Eg.
							Ginger,garlic paste or whole)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Air Conditioner/Purifier
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Alcoholic Beverages
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Animal Products
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Animal Supplement
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Antiques
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Bakery Items
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Batteries (Propane, Butane,
							Hydrogen, Helium)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Battery Shells
						</li>
						<li className="flex">
							<span className="mr-1">- </span>
							Beverages requiring refrigeration or other environmental control
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Bubble gum (Singapore)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Bulbs
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Candles
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Charcoal (Powder, Cream, etc..,)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Cigarette / E-cigarette
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Coffee
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Coins (Silver/Gold)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Currency Notes
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Dal (Lentil)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Damaged/broken/used phones of any
							kind
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Debit/Credit Card
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Driving Licence
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Drone Cameras
						</li>
						<li className="flex">
							<span className="mr-1">- </span>DSLR Camera (Without Lens we can
							ship)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Dyes (Hair, Textile, etc.)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Ghee
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Gold, Silver and Gold/Silver
							Plated Products
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Government-issued documents
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Hand Sanitizers
						</li>
					</ul>
					<ul className="">
						<li className="flex">
							<span className="mr-1">- </span>Hard Disk Containing Data
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Herbs
						</li>
						<li className="flex">
							<span className="mr-1">- </span>High Value Gadgets
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Hing (Asafoetida)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Honey
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Hookah Flavours
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Hoverboard
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Human and animal remains, Ashes
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Incense Sticks
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Insulin
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Laptop/Desktops
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Lasers
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Lithium Batteries
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Live Organs or Human Remains
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Lottery Tickets, Gambling Devices
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Magnets
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Matchbox
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Mattress
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Meat of Wild Animals
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Medical Samples
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Meltable Chocolates
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Millets
						</li>
						<li className="flex">
							<span className="mr-1">- </span>
							Money, Fake/Dummy/Collectable Cash, Payment Cards, Traveler
							Cheques
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Mosquito Repellent
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Mustard
						</li>
						<li className="flex">
							<span className="mr-1">- </span>N95 & 4 Ply, 8 Ply Face Masks
						</li>
						<li className="flex">
							<span className="mr-1">- </span>
							Organic Food (any items which has names as organic can't be
							shipped)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Paints
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Passports, IDs, Stamps, Gift Cards
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Peacock Feathers (Any Bird)
						</li>
					</ul>
					<ul className="">
						<li className="flex">
							<span className="mr-1">- </span>Pepper & Salt
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Perfumes
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Perishable Foods
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Pickle
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Plants & Seeds (Need Phytosanitary
							Certificates for seeds)
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Pornography
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Power Bank
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Radar detectors
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Rice
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Sambac & Tuberose
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Sandalwood & Red Sandal
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Sealant
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Speakers
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Spectacles & Lenses
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Steel Coating
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Television
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Tonner & Ink
						</li>
						<li className="flex">
							<span className="mr-1">- </span>
							Toy Weapons, Paint Ball Guns and BB guns, Antique weapons, Knives
							and Swords, Fake grenades, Items that could be used as weapons,
							etc
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Turmeric
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Vape Liquid
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Vibhuti & Kumkum
						</li>
						<li className="flex">
							<span className="mr-1">- </span>Walkie Talkie
						</li>
					</ul>
				</div>
				<p>
					NOTE: We won’t be able to ship this category of items internationally.
					However, the shipment of some items can vary depending on the country
					you are shipping to, as it is specific to the customs of that country.
					Countries in which the customers need to hire a broker to clear the
					customs are:
				</p>

				<li className="flex">
					<span className="mr-1">- </span>China
				</li>
				<li className="flex">
					<span className="mr-1">- </span>Bangladesh
				</li>
				<li className="flex">
					<span className="mr-1">- </span>South Africa
				</li>
				<li className="flex">
					<span className="mr-1">- </span>Mexico
				</li>
				<li className="flex">
					<span className="mr-1">- </span>Brazil
				</li>
				<li className="flex">
					<span className="mr-1">- </span>Japan
				</li>
				<li className="flex">
					<span className="mr-1">- </span>Taiwan
				</li>
				<li className="flex">
					<span className="mr-1">- </span>African countries.
				</li>
			</div>
		</div>
	);
};

export default ProhibitedItems;
