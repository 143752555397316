const Button = ({ children, disabled, onClick, type = "button" }) => {
	return (
		<button
			className="bg-[#0A2B42] text-white font-bold py-2 px-6 rounded-xl m-2 mr-6 disabled:opacity-30 shadow-2xl capitalize"
			disabled={disabled}
			type={type}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

const SignUpButton = ({ children, disabled, onClick, type = "button" }) => {
	return (
		<button
			className="bg-[#0A2B42] text-white font-bold py-2 px-6 rounded-xl mr-6 w-full disabled:opacity-30 capitalize"
			disabled={disabled}
			type={type}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

const LightButton = ({
	children,
	disabled,
	onClick,
	type = "button",
	className,
}) => {
	return (
		<button
			className={
				"bg-[#F0FEFC] text-[#0A2B42] font-bold py-2 px-6 rounded-xl mr-6 disabled:opacity-30 capitalize" +
				" " +
				className
			}
			disabled={disabled}
			type={type}
			onClick={onClick}
		>
			{children}
		</button>
	);
};
const CancelButton = ({ children, disabled, onClick, type = "button" }) => {
	return (
		<button
			className="bg-gray-700 text-white font-bold py-2 px-6 rounded-xl mr-6 disabled:opacity-30 capitalize"
			disabled={disabled}
			type={type}
			onClick={onClick}
		>
			{children}
		</button>
	);
};
export { Button, LightButton, CancelButton, SignUpButton };
