import { logout } from "helpers/storageHelper";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BiMenuAltRight } from "react-icons/bi";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";
import Logo from "../assets/Logo.png";
import { LightButton } from "./Button";
import {
	Menu,
	MenuList,
	MenuButton,
	MenuItem,
	MenuItems,
	MenuPopover,
	MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";

const UserProfileHeaderLayout = () => {
	const navigate = useNavigate();
	const [showMenu, setMenu] = useState(false);
	const name = localStorage.getItem("name");
	const email = localStorage.getItem("email");
	const LogoutHandler = () => {
		logout();
		navigate("/", { replace: true });
	};

	const open = () => setMenu(true);
	const close = () => setMenu(false);

	return (
		<Menu className="relative group">
			<MenuButton className="rounded-full bg-green-400 w-8 h-8 flex justify-center items-center ">
				<span className="">{name?.charAt(0)}</span>
			</MenuButton>
			<MenuList>
				<MenuItem onSelect={() => navigate("/profile")}>
					<div className="flex gap-4 items-center">
						<div className="rounded-full bg-green-400 w-12 h-12 flex justify-center items-center">
							<span className="">{name?.charAt(0)}</span>
						</div>
						<div>
							<p className="text-left mb-1">{name}</p>
							<p className="text-left">{email}</p>
						</div>
					</div>
				</MenuItem>
				<MenuItem onSelect={() => navigate("/profile")}> My Profile</MenuItem>
				<MenuItem onSelect={LogoutHandler}>Logout</MenuItem>
			</MenuList>
		</Menu>
	);
};

const Header = () => {
	const navigate = useNavigate();
	const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
	const setLogOutState = useAuthStore((state) => state.setLogOutState);
	const role = useUserStore((state) => state.role);
	const [isMenuActive, setMenuActive] = useState(false);
	console.log("Logged In State", isLoggedIn);
	const LogoutHandler = () => {
		logout();
		navigate("/", { replace: true });
	};
	const closeMenu = () => {
		console.log("close menu");
		setMenuActive(false);
	};
	const openMenu = () => {
		console.log("Open menu");
		setMenuActive(true);
	};
	const linkActive = "font-bold text-xl scale-105  ";
	const linkInactive = "font-bold text-xl text-white hover:text-cyan-500";

	return (
		<>
			<header className="header-color border-none">
				<div className="  flex flex-col lg:flex-row justify-center items-between lg:justify-between mx-auto py-1 lg:items-center p-8 ">
					<div className="flex justify-between">
						<Link to="/">
							<img src={Logo} className="h-14" alt="Logo" />
						</Link>
						<div className="lg:hidden flex items-center">
							{isMenuActive ? (
								<button onClick={closeMenu} className="lg:hidden mr-6">
									<AiOutlineClose size={30} />
								</button>
							) : (
								<div className=" lg:hidden lg:mr-6" onClick={openMenu}>
									<BiMenuAltRight size={30} />
								</div>
							)}
						</div>
					</div>
					<div>
						<menu
							className={`${
								isMenuActive ? "flex" : "hidden"
							} flex-col font-bold ml-auto items-center gap-4 lg:gap-0 lg:flex lg:flex-row`}
						>
							<li className="lg:mr-6  lg:block" onClick={closeMenu}>
								<NavLink
									className={({ isActive }) =>
										isActive ? linkActive : linkInactive
									}
									to="/"
								>
									Home
								</NavLink>
							</li>
							{isLoggedIn ? (
								<>
									{role === "user" ? (
										<li className="lg:mr-6  lg:block" onClick={closeMenu}>
											<NavLink
												className={({ isActive }) =>
													isActive ? linkActive : linkInactive
												}
												to="/user/orders"
											>
												Orders
											</NavLink>
										</li>
									) : (
										<li className="lg:mr-6  lg:block" onClick={closeMenu}>
											<NavLink
												className={({ isActive }) =>
													isActive ? linkActive : linkInactive
												}
												to="/dashboard"
											>
												Dashboard
											</NavLink>
										</li>
									)}
									<li className="lg:mr-6 hidden lg:block">
										<UserProfileHeaderLayout />
									</li>

									<li onClick={closeMenu} className="lg:hidden">
										<NavLink
											to="/profile"
											className={({ isActive }) =>
												isActive ? linkActive : linkInactive
											}
										>
											My Profile
										</NavLink>
									</li>

									<li onClick={closeMenu} className="lg:hidden">
										<LightButton
											onClick={LogoutHandler}
											className="!mr-0 lg:mr-6"
										>
											Logout
										</LightButton>
									</li>
								</>
							) : (
								<>
									{/* <li className="lg:mr-6">
              <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : linkInactive
                  }to="/#about">About</NavLink>
            </li>{" "} */}
									{/* <li className="lg:mr-6 lg:block" onClick={closeMenu}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkActive : linkInactive
                      }
                      to="/#features"
                    >
                      Features
                    </NavLink>
                  </li>{" "} */}
									{/* <li className="lg:mr-6 lg:block" onClick={closeMenu}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkActive : linkInactive
                      }
                      to="/products"
                    >
                      Products
                    </NavLink>
                  </li> */}
									{/* <li className="lg:mr-6 lg:block">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? linkActive : linkInactive
                      }
                      to="/#contact"
                    >
                      Contact
                    </NavLink>
                  </li> */}
									<li className="lg:block my-4" onClick={closeMenu}>
										{/* <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : linkInactive
                  }to="/login">Login</NavLink> */}
										<LightButton
											onClick={() => navigate("/login")}
											className="mr-0 lg:mr-6"
										>
											Login
										</LightButton>
									</li>
									<li className="lg:block" onClick={closeMenu}>
										{/* <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : linkInactive
                  }to="/login">Login</NavLink> */}
										<LightButton
											onClick={() => navigate("/register")}
											className="mr-0 lg:mr-6"
										>
											Register
										</LightButton>
									</li>
								</>
							)}
						</menu>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
