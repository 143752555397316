import VisuallyHidden from "@reach/visually-hidden";
import Five from "assets/five.jpeg";
import Four from "assets/four.jpeg";
import One from "assets/one.jpeg";
import Three from "assets/three.jpeg";
import TrainerImage from "assets/trainerImage.jpg";
import YoutubeImage from "assets/youtube.png";
import Two from "assets/two.jpeg";
import Video from "assets/videos/ship.mp4";
import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";

const carouselData = [
	{
		image: One,
		title: "Buy Yourself",
		content:
			"You can buy any product (Except, import Restricted product Listed as per country) from any online Ecommerce websites listed within India. You can choose our warehouse address to receive, when you book your parcel from E-Commerce website. Once we receive all your parcel booked under one order (Could be from different Merchandise), we consolidate all the parcel dispatch on the international courier to your destination.",
		buttonText: "Buy",
		path: "/user/buy-yourself",
	},
	{
		image: Two,
		title: "We Buy 4 U",
		content:
			"You can share us the product description /Photo of the product you are looking from India, We procure it locally / online for you. Once all products are locally procured, we dispatch the same on the international courier to you destination. Please check Import Restricted items before Ordering.",
		buttonText: "Order",
		path: "/user/we-buy-4-u",
	},
	{
		image: Three,
		title: "Medicine",
		content:
			"Person who have travelled abroad, and want to have their Medicines (Ayurvedic / Allopathic / Homeopathy/ Unani) to be bought from India and send to destination address anywhere in the foreign country can avail this service. Any Medicines restricted to the port of Import be restricted from Shipping. On sharing us the Prescription, we can let you know of any restriction imposed from the country where it is received.",
		buttonText: "Order",
		path: "/user/medicine",
	},
	{
		image: Four,
		title: "Customized Buy",
		content:
			"You can always avail our service to customise product according to your exact specification. We will go out of the way to get your requirement met within India. Please check restricted items per country of import, before placing orders.",
		buttonText: "Coming soon",
		// path: "/user/customized-buy",
		path: "/",
	},
	{
		image: Five,
		title: "In House Proudcts",
		content:
			"We have listed some of our most customer preferred products in our In-house cart, where customers can directly add to be shipped at competitive price",
		buttonText: "Coming soon",
		// path: "/user/in-house",
		path: "/",
	},
];

const VideoModal = ({
	productItem,
	register,
	errors,
	handleSubmit,
	onSubmit,
	onCancelHandler,
	orderType,
	close,
}) => {
	const opts = { height: "100%", width: "100%" };
	return ReactDom.createPortal(
		<div className="fixed w-full h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-80 z-50 flex justify-center items-center">
			<div className="bg-white p-6 ">
				<div className="flex flex-row align-center justify-between mb-8">
					<h1 className="">Trainer Video</h1>
					<div className="flex">
						<button className="close-button ml-auto" onClick={close}>
							{/* <button className="close-button ml-auto" onClick={close}> */}
							<VisuallyHidden>Close</VisuallyHidden>
							<span aria-hidden>×</span>
						</button>
					</div>
				</div>
				<div>
					<YouTube
						videoId="4IJ-5qO3W5A"
						opts={opts}
						onEnd={close}
						className="w-[350px] h-[198px]  sm:w-[450px] sm:h-[252px] lg:w-[800px] lg:h-[450px] "
					/>
				</div>
			</div>
		</div>,
		document.getElementById("portal")
	);
};

export default function HeroSection() {
	const navigate = useNavigate();
	const [carouselState, setCarouselState] = useState(0);
	const [prevCarouselState, setPrevCarouselState] = useState(
		carouselData.length - 1
	);
	const [nextCarouselState, setNextCarouselState] = useState(1);
	const [isVideoModalOpen, setVideoModalOpen] = useState(false);
	const close = () => {
		setVideoModalOpen(false);
	};
	useEffect(() => {
		const intervalValue = setInterval(() => {
			let presentValue;
			let prevValue;
			let nextValue;
			if (carouselState === carouselData.length - 1) {
				presentValue = 0;
			} else {
				presentValue = carouselState + 1;
			}
			prevValue = prevIndex(presentValue);
			nextValue = nextIndex(presentValue);
			setCarouselState(presentValue);
			setPrevCarouselState(prevValue);
			setNextCarouselState(nextValue);
		}, 12000);
		return () => clearInterval(intervalValue);
	}, [carouselState]);

	const prevIndex = (data) => (data === 0 ? carouselData.length - 1 : data - 1);

	const nextIndex = (data) => (data === carouselData.length - 1 ? 0 : data + 1);

	const handleCarouselTransition = (data) => {
		const next = nextIndex(data);
		const prev = prevIndex(data);
		setPrevCarouselState(prev);
		setNextCarouselState(next);
		setCarouselState(data);
	};

	return (
		<section className=" max-w-[1920px] mx-auto ">
			{/* carousel Section start */}
			<div className="grid grid-cols-1 lg:grid-cols-5 mx-auto min-h-full  lg:min-h-[calc(100vh-56px)] lg:max-h-[1000px]">
				<div className="hero-section-carousel flex flex-col col-span-2 justify-center h-full om:py-20  relative ">
					{/* Video background added */}

					<video
						src={Video}
						className="absolute top-0 left-0 w-full h-full object-cover z-0 "
						autoPlay
						muted
						loop
					/>

					{/* <div className="absolute top-0 left-0 w-full h-full z-10 bg-black opacity-40" /> */}
					{/* video background ended */}
					<div className="carousel-images  hide-scroll justify-center flex  gap-6 z-10">
						{/* {carouselData.map((item, index) => ( */}

						<div
							id={`carousel-item-${prevCarouselState}`}
							className={`shrink-0 my-auto snap-center `}
							onClick={() => handleCarouselTransition(carouselState)}
						>
							<h2 className="h1 text-white max-w-sm leading-[5rem] overflow-visible">
								{carouselData[carouselState].title}
							</h2>
							{/* <img
                src={carouselData[carouselState].image}
                alt="One"
                width={500}
                height={300}
                className="w-[250px] h-[140px] md:w-[300px] md:h-[169px] xl:w-[400px] xl:h-[225px]"
              /> */}
						</div>

						{/*
        // ))}
      */}
					</div>
					<div className="flex gap-2 mx-auto mt-24 z-10 ">
						{carouselData.map((item, index) => (
							<div
								className={`w-4 h-4 hero-carousel-dots-color mx-4 ${
									carouselState === index ? "rotate-45" : "rounded-full"
								} `}
								onClick={() => handleCarouselTransition(index)}
								key={index}
							></div>
						))}
					</div>
				</div>
				{/* carousel Section end */}
				<div className="hero-section-color col-span-3 flex flex-col justify-center py-20 px-12 xl:px-32  hero-background">
					{/* <h2 className="h1  ">{carouselData[carouselState].title}</h2> */}
					<div className="mx-auto">
						{/* <img src={Logo} className="h-16" alt="Logo" /> */}
						{isVideoModalOpen ? (
							<VideoModal close={close} />
						) : (
							<div onClick={() => setVideoModalOpen(true)} className="relative">
								<img src={TrainerImage} height={150} width={300} />
								<img
									src={YoutubeImage}
									height={50}
									width={50}
									className="absolute bottom-[50px] right-[125px]"
								/>
							</div>
						)}
					</div>
					<p className="font-regular  text-md mt-8 md:mt-10 lg:mt-12 text-justify">
						{carouselData[carouselState].content}
					</p>
					<div>
						<button
							className="hero-section-button-color py-2 px-6 mt-8 rounded-xl om:mb-14"
							onClick={() =>
								navigate(carouselData[carouselState].path, { replace: true })
							}
						>
							{carouselData[carouselState].buttonText}
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}
