import {
	LinkAuthenticationElement,
	PaymentElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { errorToast, successToast } from "helpers/Toast";
import { useEffect, useState } from "react";

export default function Payment({
	orderId,
	userEmail,
	cost,
	country,
	type,
	redirect_status,
}) {
	const stripe = useStripe();
	const elements = useElements();

	const [email, setEmail] = useState(userEmail);
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case "succeeded":
					successToast("Payment Succeeded");
					console.log("Payment succeeded!");
					break;
				case "processing":
					console.log("Your payment is processing.");
					break;
				case "requires_payment_method":
					errorToast("Your payment was not successful, please try again.");

					break;
				default:
					errorToast("Something went wrong.");
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: `http://localhost:3000/user/payment/${orderId}/response/${type}`,
			},
		});
		console.log("elements is:elements", elements);
		if (!error) {
			console.log("success message bas withouhtn");
		}
		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const paymentElementOptions = {
		layout: "tabs",
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<LinkAuthenticationElement
				id="link-authentication-element"
				defaultValue={userEmail}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<PaymentElement id="payment-element" options={paymentElementOptions} />
			<button disabled={isLoading || !stripe || !elements} id="submit">
				<span id="button-text">
					{isLoading ? (
						<div className="spinner" id="spinner"></div>
					) : (
						`Pay ${country === "INR" ? cost : cost.toFixed(2)} ${country}`
					)}
				</span>
			</button>
			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
}
