import { Button } from "components/Button";
import SupportButton from "components/SupportButton";
import { successToast } from "helpers/Toast";
import {
	adminConfirmReviewed,
	userAckonwledgedReviewed,
} from "queries/orderQueries";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export default function ReviewOrderActionButtons({ order, index }) {
	const queryClient = useQueryClient();
	const role = localStorage.getItem("userRole");
	const navigate = useNavigate();
	const [paymentGatewayOpen, setPaymentGatewayOpen] = useState(false);

	const confirmMutation = useMutation(adminConfirmReviewed, {
		onSuccess: (data) => {
			console.log(data);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});

	const userAcknowledgedMutation = useMutation(userAckonwledgedReviewed, {
		onSuccess: (data) => {
			console.log(data);

			successToast(
				"Successfully completed Payment. The items will be procured soon"
			);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
		onError: (error) => {
			console.log(error);
		},
	});

	let orderPriceValue = [];

	const today = new Date();
	let isReviewedVerifier = 0;
	let isOrderEligible = 0;
	let orderPrice = 0;
	let shouldAdminCancelTheOrder = 0;

	const resetVerifier = () => {
		isOrderEligible = 0;
		orderPrice = 0;
		isReviewedVerifier = 0;
		shouldAdminCancelTheOrder = 0;
	};
	const checkIfOrderIsEligibleForCheckOut = () => {
		let eligibleValue = 0;
		let totalPrice = 0;
		let totalItemPrice = 0;
		let totalServiceCharge = 0;
		let totalServiceChargeWithGst = 0;
		const filteredData = order.items
			.filter((item) => !item.isProcurable || item.userAcknowledge)
			.map(
				(val) =>
					val.userAcknowledge &&
					((eligibleValue += 1),
					(totalPrice += val.totalPrice),
					(totalItemPrice += val.price),
					(totalServiceCharge += val.serviceCharges),
					(totalServiceChargeWithGst += val.serviceChargesWithGst))
			);

		const finalOutput = {
			isEligible: eligibleValue,
			totalPrice,
			totalItemPrice,
			totalServiceCharge,
			totalServiceChargeWithGst,
		};
		return finalOutput;
	};
	// const isEligible = checkIfOrderIsEligibleForCheckOut;
	const orderValue = checkIfOrderIsEligibleForCheckOut();

	return (
		<div>
			{role === "user" ? (
				order.isReviewedEmailSent ? (
					orderValue.isEligible > 0 ? (
						<>
							<div className="text-[10px]">* Inclusive of GST</div>
							<div className="mb-3 flex flex-col">
								<div className="flex justify-between">
									<p className="mr-6">Price of all items :</p>
									<p> ₹{orderValue.totalItemPrice.toFixed(2)}</p>
								</div>
								<div className="flex justify-between">
									<p className="mr-6">Service charge :</p>
									<p> ₹{orderValue.totalServiceCharge.toFixed(2)}</p>
								</div>
								<div className="flex justify-between">
									<p className="mr-6">Gst 18%:</p>
									<p>
										{" "}
										₹
										{Math.round(
											(orderValue.totalServiceChargeWithGst -
												orderValue.totalServiceCharge) *
												100
										) / 100}
									</p>
								</div>
								<div className="flex justify-between">
									<p className="mr-6">Total Amount:</p>
									<p> ₹{orderValue.totalPrice.toFixed(2)}</p>
								</div>
							</div>
							<div className="">
								<SupportButton />
								<Button
									className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
									// onClick={() => {
									// 	// let amount = orderPriceValue[index];
									// 	// setPaymentGatewayOpen(true);
									// 	// setOrderId(order._id);
									// 	// setPaymentAmount(amount);
									// 	// setOrderItems(order.items);
									// 	// setPaymentGatewayOpen(true);
									// 	const data = {
									// 		orderId: order._id,
									// 		orderCost: orderValue.totalPrice,
									// 	};
									// 	userAcknowledgedMutation.mutate(data);
									// }}
									onClick={() =>
										navigate(`/user/payment/${order._id}`, {
											state: {
												orderId: order._id,
												cost: orderValue.totalPrice.toFixed(2),
												country: order.userId.shippingAddress.country,
												type: "cost",
											},
										})
									}
								>
									Pay Items Cost
								</Button>
							</div>
						</>
					) : (
						<div>
							<p>
								kindly contact support if you have any other requirement in this
								order
							</p>

							<div className="">
								<SupportButton />
								{/* <Button
                  className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                  onClick={() => {
                    userCancelMutation.mutate(order._id);
                  }}
                >
                  Cancel Order
                </Button> */}
							</div>
						</div>
					)
				) : null
			) : null}

			{resetVerifier()}
		</div>
	);
}
