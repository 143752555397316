import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import Loading from "components/Loading";
import { getUser, putEditAddress } from "queries/userQueries";
import { useMutation, useQuery } from "react-query";

import { Input } from "components/Input";
import { successToast } from "helpers/Toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useUserStore from "stores/UserStore";
import { countryCodeArray } from "utils/countryData";

export default function ProfileRegistration({ isEdit }) {
	const methods = useForm();
	const navigate = useNavigate();
	const isRegistrationComplete = useUserStore(
		(state) => state.isRegistrationComplete
	);

	const { isLoading, isError, data } = useQuery("user", getUser);

	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
	} = useForm();

	const mutation = useMutation(putEditAddress, {
		onSuccess: (data) => {
			if (isEdit) {
				successToast("Updated Address");
				navigate("/profile");
			}
			console.log("mutation success on edit address", data);
			useUserStore.setState({ isRegistrationComplete: "true" });
			localStorage.setItem("isRegistrationComplete", "true");
		},
		onError: (error) => {
			console.log("error has occured ", error.message);
		},
	});

	const onSubmit = (data) => {
		console.log("testing on submit", data);
		mutation.mutate(data);
	};
	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		return <div>Oops! Something went wrong!!!</div>;
	}
	return (
		<div className="dashboard-main-colors min-h-full flex flex-col my-auto items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
			<h1 className="h1 text-center mb-12">
				{isEdit ? "Edit Address" : "Profile Registration"}
			</h1>

			<div className="mb-6 font-bold">
				{isEdit ? "Edit" : "Add"} Recipient Address
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col ">
					<Input
						title="Recipient Name"
						inputName="name"
						register={register}
						defaultValue={data?.shippingAddress?.name || ""}
						errors={errors}
						required
						maxLength={100}
					/>
				</div>

				<div className="flex flex-col ">
					<Input
						title="Address Line 1"
						inputName="addressline1"
						register={register}
						placeholder="Enter your address"
						defaultValue={data?.shippingAddress?.addressline1 || ""}
						errors={errors}
						required
					/>
				</div>

				<div className="flex flex-col ">
					<Input
						title="Address Line 2"
						inputName="addressline2"
						register={register}
						placeholder="Enter your address"
						defaultValue={data?.shippingAddress?.addressline2 || ""}
						errors={errors}
						maxLength={250}
					/>
				</div>
				<div className="flex flex-row">
					<div className="flex flex-col mr-6">
						<Input
							title="City"
							inputName="city"
							register={register}
							placeholder="Enter your City"
							defaultValue={data?.shippingAddress?.city || ""}
							errors={errors}
							required
						/>
					</div>

					<div className="flex flex-col ">
						<Input
							title="State/Province"
							inputName="state"
							register={register}
							defaultValue={data?.shippingAddress?.state || ""}
							errors={errors}
							required
						/>
					</div>
				</div>
				<div className="flex flex-row">
					<div className="flex flex-col mr-6">
						<Input
							title="Country"
							inputName="country"
							select
							methods={methods}
							options={countryCodeArray}
							register={register}
							placeholder="Enter your country"
							defaultValue={data?.shippingAddress?.country || ""}
							errors={errors}
							required
							maxLength={100}
						/>
					</div>

					<div className="flex flex-col ">
						<Input
							title="Zip/Postal code"
							inputtype="number"
							inputName="pincode"
							register={register}
							defaultValue={data?.shippingAddress?.pincode || ""}
							errors={errors}
							required
						/>
					</div>
				</div>
				<div className="flex flex-col ">
					<Input
						title="Telephone"
						inputName="number"
						register={register}
						placeholder="Enter your Phone Number"
						defaultValue={data?.shippingAddress?.telephone || ""}
						errors={errors}
						required
					/>
				</div>

				<div className="my-6 text-center font-bold">
					{isEdit ? "Edit" : "Add"} Indian Address
				</div>
				<div className="flex flex-col ">
					<Input
						title="Name"
						inputName="indianName"
						register={register}
						defaultValue={data?.localAddress?.name || ""}
						errors={errors}
						required
						maxLength={100}
					/>
				</div>

				<div className="flex flex-col ">
					<Input
						title="Address Line 1"
						inputName="indianAddressline1"
						register={register}
						placeholder="Enter your address"
						defaultValue={data?.localAddress?.addressline1 || ""}
						errors={errors}
						required
					/>
				</div>

				<div className="flex flex-col ">
					<Input
						title="Address Line 2"
						inputName="indianAddressline2"
						register={register}
						placeholder="Enter your address"
						defaultValue={data?.localAddress?.addressline2 || ""}
						errors={errors}
						maxLength={250}
					/>
				</div>
				<div className="flex flex-row">
					<div className="flex flex-col mr-6">
						<Input
							title="City"
							inputName="indianCity"
							register={register}
							placeholder="Enter your City"
							defaultValue={data?.localAddress?.city || ""}
							errors={errors}
							required
						/>
					</div>

					<div className="flex flex-col ">
						<Input
							title="State/Province"
							inputName="indianState"
							register={register}
							defaultValue={data?.localAddress?.state || ""}
							errors={errors}
							required
						/>
					</div>
				</div>
				<div className="flex flex-row">
					<div className="flex flex-col mr-6">
						<Input
							title="Country"
							inputName="india"
							register={register}
							placeholder="Enter your country"
							defaultValue="India"
							disabled
							errors={errors}
							maxLength={100}
						/>
					</div>

					<div className="flex flex-col ">
						<Input
							title="Pincode"
							inputtype="number"
							inputName="indianPincode"
							register={register}
							defaultValue={data?.localAddress?.pincode || ""}
							errors={errors}
							required
						/>
					</div>
				</div>
				<div className="flex flex-col ">
					<Input
						title="Telephone"
						inputName="indianNumber"
						register={register}
						placeholder="Enter Indian Phone Number"
						defaultValue={data?.localAddress?.telephone || ""}
						errors={errors}
						required
					/>
				</div>

				<Button type="submit">Submit</Button>
			</form>
		</div>
	);
}
