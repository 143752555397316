import AboutUs from "components/HomePage/AboutUs";
import ContactUs from "components/HomePage/ContactUs";
import HeroSection from "components/HomePage/HeroSection";
import Testimonial from "components/HomePage/Testimonial";
import Why4inExpo from "components/HomePage/Why4inExpo";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import YouTube from "react-youtube";

export default function Home() {
	const { pathname, hash, key } = useLocation();

	const [videoViewerOpen, setVideoViewerOpen] = useState(false);

	useEffect(() => {
		// if not a hash link, scroll to top
		if (hash === "") {
			window.scrollTo(0, 0);
		}
		// else scroll to id
		else {
			setTimeout(() => {
				const id = hash.replace("#", "");
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView();
				}
			}, 0);
		}
	}, [pathname, hash, key]);

	const opts = {
		height: "800",

		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 1,
		},
	};

	return (
		<>
			{/* Hero Page */}
			{/* Hero Section Start */}

			<HeroSection />
			{/* Hero Section End */}
			<AboutUs />
			<Why4inExpo />
			<Testimonial />
			<ContactUs />
		</>
	);
}
