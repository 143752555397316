import Loading from "components/Loading";
import SupportButton from "components/SupportButton";
import AddCommentModal from "components/modals/AddCommentsModal";
import PriceModal from "components/modals/PriceModal";
import { successToast } from "helpers/Toast";
import ItemDetails from "pages/ItemDetails";
import {
	adminAddCommentForItem,
	adminAddReviewDetails,
	adminCancelOrder,
	adminConfirmReviewed,
	adminMarkItemIsProcurable,
	getOrders,
	userAckonwledgedReviewed,
	userAckonwledgedReviewedItem,
	userCancelOrder,
} from "queries/orderQueries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ToggleSwitch from "react-switch";

export default function Review() {
	const role = localStorage.getItem("userRole");
	const queryClient = useQueryClient();
	const [itemId, setItemId] = useState("");
	const [orderId, setOrderId] = useState("");
	const [orderIsMedicine, setOrderIsMedicine] = useState();
	const [orderItems, setOrderItems] = useState();
	const [quantity, setQuantity] = useState();
	const [isPriceModalOpen, setPriceModalOpen] = useState(false);
	const [isConfirmReviewedOpen, setConfirmReviewedOpen] = useState(false);
	const [reviewData, setReveiwData] = useState();
	const [paymentAmount, setPaymentAmount] = useState();
	const [paymentAmountWithGst, setPaymentAmountWithGst] = useState();
	const [paymentGatewayOpen, setPaymentGatewayOpen] = useState(false);
	const [openAddComment, setOpenAddComment] = useState(false);
	const [comment, setComment] = useState("");
	const [productItem, setProductItem] = useState();
	const [viewDetailsOpen, setViewDetailsOpen] = useState();

	let orderPriceValue = [];

	const today = new Date();
	let isReviewedVerifier = 0;
	let isOrderEligible = 0;
	let orderPrice = 0;
	let shouldAdminCancelTheOrder = 0;
	const {
		handleSubmit,
		formState: { errors },
		register,
		reset,
	} = useForm();
	const { data, isLoading, isError, error } = useQuery(
		["orders", "review"],
		() => getOrders("review")
	);

	const userCancelMutation = useMutation(userCancelOrder, {
		onSuccess: (data) => {
			console.log(data);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});

	const adminCancelMutation = useMutation(adminCancelOrder, {
		onSuccess: (data) => {
			console.log(data);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});

	const isProucrableMutation = useMutation(adminMarkItemIsProcurable, {
		onSuccess: (data) => {
			console.log(data);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});
	const userAcknowledgeItemMutation = useMutation(
		userAckonwledgedReviewedItem,
		{
			onSuccess: (data) => {
				console.log(data);
				queryClient.invalidateQueries("allOrders");
				queryClient.invalidateQueries("orders");
			},
		}
	);
	const mutation = useMutation(adminAddReviewDetails, {
		onSuccess: (data) => {
			console.log(data);
			reset();
			setOrderId("");
			setItemId("");
			setPriceModalOpen(false);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});

	const confirmMutation = useMutation(adminConfirmReviewed, {
		onSuccess: (data) => {
			console.log(data);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
	});

	const userAcknowledgedMutation = useMutation(userAckonwledgedReviewed, {
		onSuccess: (data) => {
			console.log(orderId);
			console.log(data);
			setPaymentGatewayOpen(false);
			successToast(
				"Successfully completed Payment. The items will be procured soon"
			);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
		},
		onError: () => {
			console.log(orderId);
		},
	});
	const commentHandler = (orderId, itemId, comm = "") => {
		console.log(orderId);
		console.log(itemId);
		setOrderId(orderId);
		setItemId(itemId);
		console.log("comm");
		console.log(comm);
		setComment(comm);
		setOpenAddComment(true);
	};
	const onCancelHandler = () => {
		reset();
		setOrderId("");
		setItemId("");
		setComment("");
		setProductItem();
		setPriceModalOpen(false);
		setOpenAddComment(false);
	};

	const commentMutation = useMutation(adminAddCommentForItem, {
		onSuccess: (data) => {
			console.log(data);
			queryClient.invalidateQueries("allOrders");
			queryClient.invalidateQueries("orders");
			setOrderId("");
			setItemId("");
			setComment("");
			setPriceModalOpen(false);
			setOpenAddComment(false);
		},
	});

	const onCommentSubmit = (formData) => {
		console.log(orderId);
		console.log("orderId");
		console.log(formData);
		const newData = {
			orderId: orderId,
			comment: formData.comment,
			itemId: itemId,
		};
		commentMutation.mutate(newData);
	};

	const detailsHandler = (orderId, product) => {
		setOrderId(orderId);
		setItemId(product._id);

		setPriceModalOpen(product._id);
	};
	const onSubmit = (formData) => {
		console.log(formData);
		console.log("OrderId and ItemID");
		console.log(formData);
		console.log(orderId);
		console.log(itemId);
		const newData = { ...formData, orderId, itemId };
		console.log(newData);
		mutation.mutate(newData);
	};

	const isProcurableHandler = (orderId, itemId, isProcurable) => {
		const data = {
			orderId,
			itemId,
			isProcurable: !isProcurable,
		};
		isProucrableMutation.mutate(data);
	};

	const confirmActionHandler = async (func) => {
		if (
			await window.confirm(
				"User has already been notified are you sure you want to make change"
			)
		) {
			func();
		}
	};

	const verifier = (product) => {
		// console.log(price);
		if (product.price || product.itemType === "buyYourself") {
			isReviewedVerifier += 1;
		}
		if (!product.price) {
			if (!product.isProcurable) {
				isReviewedVerifier += 1;
			}
		}
		if (
			product.itemType !== "buyYourself" &&
			product.isProcurable &&
			product.userAcknowledge
		) {
			orderPrice += product.totalPrice;
		}
		if (!product.isProcurable || !product.userAcknowledge) {
			isOrderEligible += 1;
		}
		if (!product.isProcurable) {
			shouldAdminCancelTheOrder += 1;
		}
		// console.log("isOrderEligible");

		// console.log(isOrderEligible);
		// console.log(isReviewedVerifier);
	};
	const resetVerifier = () => {
		isOrderEligible = 0;
		orderPrice = 0;
		isReviewedVerifier = 0;
		shouldAdminCancelTheOrder = 0;
	};
	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}

	return (
		<>
			{openAddComment && (
				<AddCommentModal
					orderId={orderId}
					itemId={itemId}
					data={comment}
					setOpenAddComment={setOpenAddComment}
					onCancelHandler={onCancelHandler}
					onCommentSubmit={onCommentSubmit}
				/>
			)}

			{paymentGatewayOpen && (
				<div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
					<div className="bg-white p-6">
						<h1 className="mb-8 ">Payment Gateway</h1>
						<div>
							<div>
								{orderItems[0].itemType === "medicine" ? null : (
									<table className="border">
										<thead className="border border-bottom-1">
											<tr>
												<th className="mx-2 px-4">Name</th>
												<th className="mx-2 px-4">Quantity</th>
												<th className="mx-2 px-4">Price *</th>
											</tr>
										</thead>
										<tbody>
											{orderItems
												.filter(
													(val) => val.isProcurable && val.userAcknowledge
												)
												.map((item) => (
													<tr>
														<td className="mx-2 px-4">{item.name}</td>
														<td className="mx-2 px-4">
															{item.procurableQuantity}
														</td>
														<td className="mx-2 px-4">{item.totalPrice}</td>
													</tr>
												))}
										</tbody>
									</table>
								)}
							</div>
							<div className="mb-6">
								The total price to procure the items are ₹{paymentAmount}
							</div>
							<div className="text-[10px) text-left">
								* Inclusive of service charges and GST
							</div>
							<button
								className="bg-gray-500 px-4 py-2 rounded-md mr-6"
								onClick={() => setPaymentGatewayOpen(false)}
							>
								Cancel
							</button>
							<button
								className="bg-blue-700 font-bold py-2 text-white px-6 rounded-md m-auto"
								onClick={() => {
									const data = {
										orderId,
										orderCost: paymentAmount,
										orderCostWithGst: paymentAmountWithGst,
									};
									userAcknowledgedMutation.mutate(data);
								}}
							>
								Pay Now
							</button>
						</div>
					</div>
				</div>
			)}

			{/* TODO: Create this flow after asking if it is required for the usecase and what details should be displayed in this */}
			{/* {isConfirmReviewedOpen && (
        <div className="fixed w-screen h-full top-0 bottom-0 right-0 left-0 text-center bg-black bg-opacity-40 z-50 flex justify-center items-center">
          <div className="bg-white p-6">
            <h1 className="mb-8 ">Confirm Review Details</h1>
            <div>{reviewData.map((data) => (

<div>

            ))}</div>

            <div>
              <button
                className="bg-gray-500 px-4 py-2 rounded-md mr-6"
                onClick={onCancelHandler}
              >
                Cancel
              </button>
              <button className="bg-blue-500 px-4 py-2 rounded-md">
                Submit
              </button>
            </div>
          </div>
        </div>
      )} */}
			<div>
				{/* delete Modal */}

				{data.length !== 0 ? (
					<div>
						{data.map((orders, index) => (
							<div className="mt-6" key={orders._id}>
								<div className="hidden">{(isReviewedVerifier = 0)}</div>
								<div className="flex">
									<h3 className="font-medium mr-4">Order ID:</h3>
									<p>{orders.uniqueOrderId}</p>
								</div>
								<div className="flex">
									<h3 className="font-medium mr-4">User Email:</h3>
									<p>{orders.userEmail}</p>
								</div>
								<div className="flex">
									<h3 className="font-medium mr-4">Order Type:</h3>
									<p className="capitalize">{orders.orderType}</p>
								</div>
								<div className="overflow-x-auto">
									<table classname=" text-sm  text-gray-500 dark:text-white overflow-x-scroll">
										<thead className="text-xs text-gray-700 uppercase bg-[#115383] dark:bg-[#115383] dark:text-white">
											<tr>
												<th className="px-4 py-3">
													{orders.isMedicine ? "Name" : "Product Name"}
												</th>
												{orders.isMedicine ? null : (
													<th className="px-4 py-3">Quantity</th>
												)}
												<th className="px-4 py-3">Description</th>
												{role === "user" ? (
													orders.isReviewedEmailSent ? (
														<>
															<th className="px-4 py-3">Procurable Quantity</th>
															<th className="px-4 py-3">Price</th>
															<th className="px-4 py-3">Total Price *</th>
															<th className="px-4 py-3">Tentative Date</th>
															<th className="px-4 py-3">Details</th>
															<th className="px-4 py-3">Procurable?</th>
															<th className="px-4 py-3">Comment</th>
															<th className="px-4 py-3">Acknowledge</th>
														</>
													) : (
														<th className="px-4 py-3">Details</th>
													)
												) : (
													<>
														<th className="px-4 py-3">Procurable Quantity</th>
														<th className="px-4 py-3">Price</th>
														<th className="px-4 py-3">Total Price</th>
														<th className="px-4 py-3">Tentative Date</th>
														<th className="px-4 py-3">Details</th>
														<th className="px-4 py-3">Is Procurable</th>
														<th className="px-4 py-3"></th>
													</>
												)}
												<th className="px-4 py-3"></th>
											</tr>
										</thead>
										<tbody key={orders._id} className="mb-8 border">
											{orders.items.map((product) => (
												<>
													<tr
														key={product._id}
														className={
															product.isReviewedEmailSent &&
															(product.isProcurable == false ||
																product.userAcknowledge == false)
																? "bg-gray-100"
																: ""
														}
													>
														<td className="text-center px-6 break-words">
															{product.name}
														</td>
														{orders.isMedicine ? null : (
															<td className="text-center px-6">
																{product.quantity}
															</td>
														)}
														<td className="text-center px-6 truncate  max-w-[300px]">
															{product.description}
														</td>
														{role === "user" ? (
															orders.isReviewedEmailSent === true ? (
																<>
																	<td className="text-center px-6">
																		{product.procurableQuantity || "-"}
																	</td>
																	<td className="text-center px-6">
																		{product.price || "-"}
																	</td>
																	<td className="text-center px-6">
																		{product.totalPrice || "-"}
																	</td>
																	<td className="text-center px-6">
																		{(product.tentativeDate &&
																			new Date(
																				product.tentativeDate
																			).toLocaleDateString()) ||
																			"-"}
																	</td>
																	<td className="text-center px-6">
																		<div className="flex items-center">
																			{viewDetailsOpen === product._id ? (
																				<button
																					// to="/"
																					className="mr-4"
																					onClick={() => setViewDetailsOpen()}
																				>
																					Hide details
																				</button>
																			) : (
																				<button
																					// to="/"
																					className="mr-4"
																					onClick={() =>
																						setViewDetailsOpen(product._id)
																					}
																				>
																					View details
																				</button>
																			)}
																		</div>
																	</td>
																	<td className="text-center px-6">
																		{product.isProcurable
																			? "Procurable"
																			: "Can not proucre"}
																	</td>
																	<td className="text-center px-6">
																		{product.comment || "-"}
																	</td>
																	<td className="text-center px-6">
																		<div className="flex items-center">
																			{/* <Link to="/" className="mr-4">
                              View details
                            </Link> */}
																			{!product.userAcknowledge && (
																				<button
																					className="bg-gray-700 px-3 py-2 text-white rounded-lg disabled:bg-gray-200 mr-6"
																					onClick={() => {
																						const data = {
																							orderId: orders._id,
																							itemId: product._id,
																							userAcknowledge:
																								!product.userAcknowledge,
																						};

																						userAcknowledgeItemMutation.mutate(
																							data
																						);
																					}}
																					disabled={!product.isProcurable}
																				>
																					{/* {product.userAcknowledge
                                      ? "Cancel Item"
                                      : "Approve Item"} */}
																					{!product.userAcknowledge &&
																						"Approve Item"}
																				</button>
																			)}
																		</div>
																	</td>
																</>
															) : (
																<td className="text-center px-6">
																	<div className="flex items-center">
																		{viewDetailsOpen === product._id ? (
																			<button
																				// to="/"
																				className="mr-4"
																				onClick={() => setViewDetailsOpen()}
																			>
																				Hide details
																			</button>
																		) : (
																			<button
																				// to="/"
																				className="mr-4"
																				onClick={() =>
																					setViewDetailsOpen(product._id)
																				}
																			>
																				View details
																			</button>
																		)}
																	</div>
																</td>
															)
														) : (
															<>
																<td className="text-center px-6">
																	{product.procurableQuantity || "-"}
																</td>
																<td className="text-center px-6">
																	{product.price || "-"}
																</td>
																<td className="text-center px-6">
																	{product.totalPrice || "-"}
																</td>
																<td className="text-center px-6">
																	{(product.tentativeDate &&
																		new Date(
																			product.tentativeDate
																		).toLocaleDateString()) ||
																		"-"}
																</td>
															</>
														)}
														{localStorage.getItem("userRole") ===
														"user" ? null : (
															<>
																<td className="text-center px-6">
																	<div className="flex items-center">
																		{viewDetailsOpen === product._id ? (
																			<button
																				// to="/"
																				className="mr-4"
																				onClick={() => setViewDetailsOpen()}
																			>
																				Hide details
																			</button>
																		) : (
																			<button
																				// to="/"
																				className="mr-4"
																				onClick={() =>
																					setViewDetailsOpen(product._id)
																				}
																			>
																				View details
																			</button>
																		)}
																	</div>
																</td>
																<td className="text-center px-6">
																	<div className="flex items-center">
																		{/* <Link to="/" className="mr-4">
                              View details
                            </Link> */}
																		<ToggleSwitch
																			className=" mr-6"
																			checked={product.isProcurable}
																			onChange={() => {
																				orders.isReviewedEmailSent
																					? confirmActionHandler(() =>
																							isProcurableHandler(
																								orders._id,
																								product._id,
																								product.isProcurable
																							)
																					  )
																					: isProcurableHandler(
																							orders._id,
																							product._id,
																							product.isProcurable
																					  );
																			}}
																		/>
																		<button
																			className="bg-gray-700 px-3 py-2 text-white rounded-lg disabled:bg-gray-200 mr-6"
																			onClick={() => {
																				orders.isReviewedEmailSent
																					? confirmActionHandler(() =>
																							detailsHandler(
																								orders._id,
																								product
																							)
																					  )
																					: detailsHandler(orders._id, product);
																			}}
																			disabled={
																				!product.isProcurable ||
																				product.itemType === "buyYourself"
																			}
																		>
																			Add Details
																		</button>
																		{isPriceModalOpen === product._id && (
																			<PriceModal
																				productItem={product}
																				register={register}
																				errors={errors}
																				handleSubmit={handleSubmit}
																				onSubmit={onSubmit}
																				onCancelHandler={onCancelHandler}
																				orderType={orders.orderType}
																			/>
																		)}
																		<button
																			className="bg-gray-700 px-3 py-2 text-white rounded-lg"
																			onClick={() => {
																				orders.isReviewedEmailSent
																					? confirmActionHandler(() =>
																							commentHandler(
																								orders._id,
																								product._id,
																								product.comment
																							)
																					  )
																					: commentHandler(
																							orders._id,
																							product._id,
																							product.comment
																					  );
																			}}
																		>
																			Add Comments
																		</button>
																	</div>
																</td>
															</>
														)}
														<td>
															<div
																className={`text-xs text-center p-2 rounded-full m-2 +
                                  ${
																		product.itemType === "cart"
																			? "bg-orange-100"
																			: "bg-teal-100"
																	}`}
															>
																{product.itemType === "cart"
																	? "In House"
																	: orders.orderType}
															</div>
														</td>
														{/* <Link to="/" className="mr-4">
                              View details
                            </Link> */}
													</tr>
													<tr>
														<td colSpan={10}>
															{verifier(product)}
															{viewDetailsOpen === product._id && (
																<ItemDetails
																	product={product}
																	orderId={orders._id}
																	isReviewedEmailSent={
																		orders.isReviewedEmailSent
																	}
																	orderStatus={orders.orderStatus}
																	isMedicine={orders.isMedicine}
																	isPremiumAvailed={orders.isPremiumAvailed}
																	orderType={orders.orderType}
																/>
															)}
														</td>
													</tr>
												</>
											))}
										</tbody>
									</table>
								</div>

								<div className="hidden">{orderPriceValue.push(orderPrice)}</div>
								{role === "user" ? (
									orders.isReviewedEmailSent ? (
										isOrderEligible !== orders.items.length ? (
											<>
												{" "}
												<div className="text-[10px]">* Inclusive of GST</div>
												<div className="mb-3">
													Total Order Price : ₹{orderPrice}
												</div>
												<div className="">
													<SupportButton />
													<button
														className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
														onClick={() => {
															let amount = orderPriceValue[index];
															setOrderId(orders._id);
															setPaymentAmount(amount);
															setOrderItems(orders.items);
															setPaymentGatewayOpen(true);
														}}
													>
														Confirm Items
													</button>
												</div>
											</>
										) : (
											<div>
												<p>
													kindly contact support if you have any other
													requirement in this order
												</p>

												<div className="">
													<SupportButton />
													{/* <button
                            className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                            onClick={() => {
                              userCancelMutation.mutate(orders._id);
                            }}
                          >
                            Cancel Order
                          </button> */}
												</div>
											</div>
										)
									) : null
								) : null}
								{/* {role !== "user" ? (
                  orders.isReviewedEmailSent ? (
                    // <button
                    //   className="bg-green-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                    //   onClick={() => {
                    //     confirmMutation.mutate(orders._id);
                    //   }}
                    // >
                    //   Resend Email
                    // </button>
                    <div className="text-green-500">Customer is notified</div>
                  ) : isOrderEligible !== orders.items.length ? (
                    isReviewedVerifier ===
                    orders.items.filter((item) => item.isProcurable).length ? (
                      <button
                        className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                        onClick={() => {
                          confirmMutation.mutate(orders._id);
                        }}
                      >
                        Confirm Reviewed
                      </button>
                    ) : (
                      <button
                        className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
                        onClick={() => {
                          adminCancelMutation.mutate(orders._id);
                        }}
                      >
                        Cancel Order
                      </button>
                    )
                  ) : null
                ) : null} */}
								{role !== "user" ? (
									orders.isReviewedEmailSent ? ( // <button
										//   className="bg-green-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
										//   onClick={() => {
										//     confirmMutation.mutate(orders._id);
										//   }}
										// >
										//   Resend Email
										// </button>
										<div className="text-green-500">Customer is notified</div>
									) : isReviewedVerifier === orders.items.length ? (
										shouldAdminCancelTheOrder === orders.items.length ? (
											<button
												className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
												onClick={() => {
													adminCancelMutation.mutate(orders._id);
												}}
											>
												Cancel Order
											</button>
										) : (
											<button
												className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mt-4"
												onClick={() => {
													confirmMutation.mutate(orders._id);
												}}
											>
												Confirm Reviewed
											</button>
										)
									) : null
								) : null}
								{resetVerifier()}
							</div>
						))}

						{/* <div className="mt-6">
              <button
                className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-8"
                onClick={() => deleteOrderHandler(orders._id)}
              >
                Delete Order
              </button>
              <button
                className="bg-blue-700 font-bold py-2 text-white px-6 rounded-xl mb-8"
                onClick={() => submitOrderMutation.mutate(orders._id)}
              >
                Submit Order
              </button>
            </div> */}
					</div>
				) : (
					<div className="">No Orders in Review</div>
				)}
			</div>
			{/* data.length !== 0 ? ( */
			/*   <div className="flex flex-col"> */
			/*     {data.map((order, index) => ( */
			/*       <OrderItem order={order} index={index} /> */
			/*     ))} */
			/*   </div> */
			/* ) : ( */
			/*   <div className="">No Orders in Review</div> */
			/* ) */}
		</>
	);
}
