import { checkAuth } from "hooks/checkAuth";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";

export const createPaymentIntent = async (data) => {
	await checkAuth();
	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/create-payment-intent`, requestOptions);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);
	return resjson;
};

export const verifyPaymentIntent = async (data) => {
	await checkAuth();
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};

	console.log("payment intent data", data);

	const res = await fetch(
		`${baseURL}/verify-payment-intent?paymentIntent=${data.paymentIntent}&paymentClientSecret=${data.paymentClientSecret}&orderId=${data.orderId}&type=${data.type}`,
		requestOptions
	);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);
	return resjson;
};

const fetchQuery = async (urlPath, requestOptions) => {
	const res = await fetch(`${urlPath}`, requestOptions);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);
	return resjson;
};

const putRequestOptions = (data) => {
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};
	return requestOptions;
};

export async function getUser() {
	await checkAuth();
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log("Get User Data for address modal");
	const res = await fetch(`${baseURL}/users`, requestOptions);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function getUnVerifiedUsers() {
	await checkAuth();
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};

	const res = await fetch(
		`${baseURL}/users/getUnVerifiedUsers`,
		requestOptions
	);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function verifyUser(data) {
	await checkAuth();

	const requestOptions = await putRequestOptions(data);
	const res = await fetch(`${baseURL}/users/verifyUser`, requestOptions);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminGetUser(data) {
	await checkAuth();
	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log("Get User Data for address modal");
	const res = await fetch(
		`${baseURL}/users/admin-users?userId=${data}`,
		requestOptions
	);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function putEditDetails(data) {
	await checkAuth();
	const urlPath = `${baseURL}/users`;

	const requestOptions = await putRequestOptions(data);
	const response = await fetchQuery(urlPath, requestOptions);
	return response;
}

export async function putEditAddress(data) {
	await checkAuth();
	const urlPath = `${baseURL}/users/edit-address`;
	console.log("Put Edit Address", data);
	const requestOptions = await putRequestOptions(data);
	const response = await fetchQuery(urlPath, requestOptions);
	return response;
}

export async function putChangePassword(data) {
	await checkAuth();
	const urlPath = `${baseURL}/users/change-password`;
	const requestOptions = await putRequestOptions(data);
	const response = await fetchQuery(urlPath, requestOptions);
	return response;
}
