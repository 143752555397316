import { Outlet, useNavigate } from "react-router-dom";
import LeftArrow from "assets/arrow-left-solid.svg";

export default function NewOrders() {
	const navigate = useNavigate();
	return (
		<div>
			<div className="flex flex-row items-center mb-2">
				<div className="mr-4" onClick={() => navigate(-1)}>
					<img src={LeftArrow} className="w-6 h-6" />
				</div>
				<h3 className="h3 ">New Order</h3>
			</div>
			<Outlet />
		</div>
	);
}
