import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import Loading from "components/Loading";
import { getAllOrders } from "queries/orderQueries";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import OrdersTable from "./OrdersTable";

const buyingModelData = [
	{ name: "Buy Yourself", link: "/user/buy-yourself" },
	{ name: "We Buy 4 U", link: "/user/we-buy-4-u" },
	{ name: "medicine", link: "/user/medicine" },
	// { name: "Customized Buy", link: "/user/customized-buy" },
	// { name: "In House Products", link: "/user/in-house" },
];

// const ProductActions = ({ product }) => {
//     return <div>Product Actions as mentioned</div>;
// }

export default function UserOrders() {
	const navigate = useNavigate();
	const { data, isLoading, isError, error } = useQuery(
		"allOrders",
		getAllOrders
	);
	console.log(data && data);
	const [openAddItem, setAddItem] = useState(false);

	if (isLoading) {
		return <Loading />;
	}
	if (isError) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}

	return (
		<div className="flex">
			<div className="max-w-[100%]">
				{openAddItem && (
					<Dialog
						className="flex flex-wrap gap-y-8"
						onDismiss={() => setAddItem(false)}
					>
						{buyingModelData.map((item) => (
							<Button onClick={() => navigate(item.link)} key={item._id}>
								{item.name}
							</Button>
						))}
					</Dialog>
				)}
				<Button
					onClick={() => {
						setAddItem(!openAddItem);
					}}
				>
					Add Order
				</Button>

				<div className="">
					{data.bag.length > 0 && (
						<div className="mb-8 bg-teal-100 p-4 mt-4 shadow">
							<h2 className="h3 mt-4 uppercase">Cart</h2>
							<OrdersTable data={data.bag} />
						</div>
					)}
					<div className="mb-4">
						<h2 className="h3 mt-4 uppercase">Orders In Progress</h2>
						<OrdersTable data={data.orders} />
					</div>
				</div>
			</div>
		</div>
	);
}
