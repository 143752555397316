import { logout } from "helpers/storageHelper";
import { useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import useUserStore from "stores/UserStore";

export default function Sidebar() {
	const navigate = useNavigate();
	const location = useLocation();
	const role = useUserStore((state) => state.role);
	const [viewAccountDetailsOpen, setViewAccountDetailsOpen] = useState(false);
	const LogoutHandler = () => {
		logout();
		navigate("/", { replace: true });
	};
	const { pathname } = location;
	const splitValue = pathname.split("/");
	console.log("splitValue");

	console.log(splitValue);
	const linkActive =
		"font-bold text-xl text-cyan-300 underline scale-105 text-sm ";
	const linkInactive =
		"font-bold text-xl text-[#ababab] hover:text-cyan-500 text-sm";

	return (
		<div className="flex">
			<div className="dashboard-sidebar-colors flex flex-row min-w-40">
				<div className="w-40 p-4 pt-2 mt-4">
					<menu>
						{role !== "user" ? (
							<>
								<li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/dashboard"
									>
										<span className="whitespace-nowrap text-sm">Dashboard</span>
									</NavLink>
								</li>

								<li className="mb-4">
									<NavLink
										className={() =>
											splitValue.includes("orders") ? linkActive : linkInactive
										}
										to={role === "user" ? "/user/orders" : "/orders/review"}
									>
										<span className="whitespace-nowrap text-sm">Orders</span>
									</NavLink>
								</li>
								<li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/history"
									>
										<span className="whitespace-normal text-sm">
											Order History
										</span>
									</NavLink>
								</li>
							</>
						) : (
							<>
								<li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/profile"
									>
										<span className="whitespace-nowrap text-sm">Profile</span>
									</NavLink>
								</li>

								<li className="mb-4">
									<NavLink
										className={() =>
											splitValue.includes("orders") ? linkActive : linkInactive
										}
										to={role === "user" ? "/user/orders" : "/orders/review"}
									>
										<span className="whitespace-nowrap text-sm">Orders</span>
									</NavLink>
								</li>
								<li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/history"
									>
										<span className="whitespace-nowrap text-sm">
											Order History
										</span>
									</NavLink>
								</li>
								<hr />
								<li className="mb-4 mt-5">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/prohibited-items"
									>
										<span className="whitespace-normal text-sm">
											Prohibited Items
										</span>
									</NavLink>
								</li>
								{/* <li className="mb-4">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? linkActive : linkInactive
                    }
                    to="/history"
                  >
                    <span className="whitespace-nowrap text-sm">Restricted Items</span>
                  </NavLink>
                </li> */}
								{/* <li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/volumetric"
									>
										<span className="whitespace-nowrap text-sm">
											Volumetric Weight
										</span>
									</NavLink>
								</li> */}
								{/* <li className="mb-4">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? linkActive : linkInactive
                  }
                  to="/history"
                >
                  <span className="whitespace-nowrap text-sm">Prohibited Countries</span>
                </NavLink>
              </li> */}
							</>
						)}
						{(role === "employee" || role === "admin") && (
							<>
								<li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/users-list"
									>
										<span className="whitespace-nowrap text-sm">Users</span>
									</NavLink>
								</li>
								<li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/categories"
									>
										<span className="whitespace-nowrap text-sm">
											Categories
										</span>
									</NavLink>
								</li>
								<li className="mb-4">
									<NavLink
										className={({ isActive }) =>
											isActive ? linkActive : linkInactive
										}
										to="/countries"
									>
										<span className="whitespace-nowrap text-sm">
											Countries List
										</span>
									</NavLink>
								</li>
								{/* <li className="mb-4">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? linkActive : linkInactive
                    }
                    to="/admin-products"
                  >
                    <span className="whitespace-nowrap text-sm">Products</span>
                  </NavLink>
                </li> */}
							</>
						)}
					</menu>
				</div>
			</div>

			<div className=" bg-[#F0FEFC] text-[#0A2B42] flex-grow min-h-screen">
				<div className=" mx-6 mt-4">
					<Outlet />
				</div>
			</div>
		</div>
	);
}
