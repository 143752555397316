const TermsAndConditions = () => {
	return (
		<div>
			<div className="max-w-3xl my-6 mx-auto">
				<h1 className="h2 text-center m-2">Terms And Conditions</h1>
				<div>
					Please read these terms and conditions (“Terms”) carefully. They
					govern the relationship between Member (“Member” or “You”) and
					4inExpo.com and govern Member’s access to the 4inExpo (Subsidiary of
					360ai) Website. The Terms also include the legal authority by which
					4inExpo will act on Member’s behalf in certain circumstances. If You
					are unclear about any of these Terms or have any questions, please
					contact Our customer support team at grievance@4inExpo.com before
					agreeing to them.
				</div>
				<h2 className="h3 my-4">About this Website</h2>
				4inExpo a subsidery of 360.ai is a company incorporated and registered
				in India with GST NO: 27ADDPK6440Q1Z3 whose registered office is at
				Quest Coworks, Technopolis Knowledge Park, 5th Floor, Hanuman Nagar,
				Anderi East, Mumbai, Maharashtra 400093.
				<h2 className="h3 my-4">Acceptance of the Terms</h2>
				We use the Personal Information you submit to operate, maintain, and
				provide to you the features and functionality of the Platform. We may
				collect and use User’s Personal Information for the following purposes:
				<ol type="a" className="my-3 ml-2 list-[lower-alpha] list-inside">
					<li>
						Activation of the Member's account or use of services provided by
						4inExpo (hereinafter “4inExpo.com” or “4inExpo” or the "Website" or
						“We” or “Us”) will represent the Member's acceptance of these Terms
						and the Member's authorization for 4inExpo.com to charge the
						Member's payment method for all future charges. By accessing and/or
						otherwise using this Website and its content, Member acknowledges
						that he/she has read, understood and agrees to be legally bound by
						these Terms.
					</li>

					<li>
						Our Privacy Policy hereby incorporated by reference into these
						Terms, explains the policy applicable to the information that is
						collected through the Website or received directly from you.
					</li>

					<li>
						4inExpo.com reserves the right, at its sole discretion, to change,
						modify, amend or otherwise alter these terms and conditions. It is
						the Member's responsibility to review the 4inExpo.com Terms and
						Conditions periodically as continued use of 4inExpo.com's services
						will constitute the Member's acceptance of any revised terms and
						conditions. If you disagree with modification made to these Terms
						and Conditions, your only recourse is to cease using the services of
						4inExpo.com.
					</li>

					<li>
						 4inExpo.com prohibits any illegal activity by its Members. Any
						charges incurred, for legal advice or otherwise, by 4inExpo.com in
						connection with the enforcement of the law against a Member or a
						potential Member will be the financial responsibility of the Member
						or the potential Member. See Indemnification section below for
						further details.{" "}
					</li>

					<li>
						Membership starts the day the Member's application is received by
						4inExpo.com.
					</li>

					<li>
						The Member represents and warrants that he/she is responsible for
						complying with all known applicable export and import control laws
						and regulations in the order and receipt of merchandise and mail
						under these Terms.{" "}
					</li>
				</ol>
				<h2 className="h3 my-4">Eligibility to Use the Website</h2>
				<ol type="a" className="my-3 ml-2 list-[lower-alpha] list-inside">
					<li>
						 The Website is provided by the Proprietor and available to entities
						and persons that are competent to enter into a legally binding
						agreement(s) under the applicable law, and for commercial use only,
						and not for personal use. If the Member or proposed use of the
						Website do not qualify with the aforesaid requirements, users are
						not permitted to use the Website.
					</li>
					<li>
						Further, the privilege granted to the user to use the Website shall
						be automatically terminated if the Member is deemed a “consumer” as
						per the CPA, and the Proprietor and its affiliates shall have the
						right to treat all transactions entered into by such Member through
						the Website as void and non-binding on them, at the sole cost and
						risk of such Member.
					</li>
				</ol>
				<h2 className="h3 my-4">Intellectual Property Rights</h2>
				<p>
					Member acknowledges that this Website and various elements contained
					therein are protected by copyrights, trademarks, trade secrets,
					patents, or other proprietary rights, and that these worldwide rights
					are valid and protected in all forms, media, and technologies existing
					now and hereinafter developed. Members also acknowledge that the
					content is and shall remain 4inExpo’s property or the property of our
					licensors. Members agree to comply with all intellectual property laws
					and to not encumber any interest in, or assert any rights to, the
					content. Members may not modify, transmit, participate in the sale or
					transfer of, or create derivative works based on any content, in whole
					or in part.
				</p>
				<h2 className="h3 my-4">Copyrights/Trademarks</h2>
				<p>
					The trademarks, logos and service marks appearing on this website,
					including, but not limited to, the trademarks: “4inExpo”, and
					“4inExpo.com” are trademarks and service marks of 4inExpo. Other
					company, product, and service names and logos used and displayed on
					this Website may be trademarks or service marks of their respective
					owners who may or may not endorse or be affiliated with or connected
					to us. Nothing on this Website should be construed as granting, by
					implication or otherwise, any license or right to use any of
					trademarks and service marks displayed on this Website, without our
					prior written permission in each instance. The content of this Website
					is protected by copyright law. All such content is also copyrighted as
					a collective work under the The Copyright Act, 1957, and 4inExpo owns
					a copyright in the selection, coordination, arrangement, and
					enhancement of such Content.
				</p>
				<h2 className="h3 my-4">User Guidelines</h2>
				<p>
					Member agree to adhere to the following guidelines when using this
					Website:
				</p>
				<ol type="a" className="my-3 ml-2 list-[lower-alpha] list-inside">
					<li>
						You may not defame, abuse, harass, stalk, threaten or otherwise
						violate the legal rights (such as rights of privacy and publicity)
						of others.
					</li>
					<li>
						You may not publish, post, advertise, distribute, disseminate, or
						upload any defamatory, infringing, obscene, indecent, abusive,
						offensive, inflammatory, objectionable, or unlawful material or
						information.
					</li>
					<li>
						 You may not publish, post, advertise, distribute, disseminate, or
						upload any information that may infringe any patent, trademark,
						trade secret, copyright, or other intellectual property rights, or
						any rights to privacy or publicity, of any other person.
					</li>
					<li>
						You may not publish, post, advertise, distribute, disseminate, or
						upload any information that violates the legal rights of any other
						person or that could give rise to any civil or criminal liability
						under applicable laws or regulations or that otherwise may be in
						conflict with these Terms and Conditions or the Privacy Policy.
					</li>

					<li>
						You agree that you will not interrupt or attempt to interrupt the
						operation of this Website in any way. You will not impersonate any
						person or entity, or misrepresent your affiliation with a person or
						entity, or misrepresent the origin of any information that you
						provide to us. You agree that your use of the Website will at all
						times be for a lawful purpose and you agree to comply with all
						applicable laws and regulations in your use of the Website. Possible
						evidence of use of this Website for illegal purposes may be provided
						to law enforcement authorities.
					</li>

					<li>
						You may not upload files that contain viruses, Trojan horses, worms,
						corrupted files, or any other similar malicious or harmful software,
						code, or programs that may damage the operation of another's
						computer or the Website.
					</li>

					<li>
						You may not delete or modify any author attributions, legal notices
						or proprietary designations or labels in any information that is
						published, posted, advertised, distributed, disseminated, or
						uploaded.
					</li>

					<li>
						You may not falsify the origin or source of information or material
						published, posted, advertised, distributed, disseminated, or
						uploaded. You may not conduct or forward surveys, contests, chain
						letters, or commercial solicitations.
					</li>

					<li>
						You may not download any material or information published, posted,
						distributed, disseminated, or uploaded by another user that you
						know, or reasonably should know, cannot be legally distributed in
						such manner.
					</li>

					<li>
						You may not provide personal information, as that term is defined in
						the Privacy Policy, of another person without his or her express
						permission. You may not provide personal information, as that term
						is defined in the Privacy Policy, of individuals under the age of 13
						(thirteen).
					</li>

					<li>
						You may not access or attempt to access 4inExpo.com's information
						technology systems, environments, networks, files, data or accounts
						to which express authorization has not been obtained, or log into a
						server that you are not authorized to access.
					</li>

					<li>
						You may not attempt to circumvent or subvert system or network
						security mechanisms, or probe the security of any system, network,
						or account associated or used in conjunction with the Website.
					</li>

					<li>
						You may not authorize or permit anyone else to access and/or use
						your account registration information, or access, visit and/or use
						the Website by use of your account/profile and/or registration
						information or impersonate, imitate or pretend to be somebody else.
					</li>

					<li>
						You may not use the Website in any manner that could disable,
						overburden, damage, or impair the Website or interfere with any
						other person's use of the Website, including their ability to engage
						in real time activities through the Website.
					</li>

					<li>
						You may not use any robot, spider, or other automatic device,
						process, or means to access the Website for any purpose, including
						monitoring or copying any of the material on the Website.
					</li>

					<li>
						You may not use any manual process to monitor or copy any of the
						material on the Website or for any other unauthorized purpose
						without our prior written consent.
					</li>

					<li>
						You may not use any device, software, or routine that interferes
						with the proper working of the Website.
					</li>

					<li>
						You may not attack the Website via a denial-of-service attack or a
						distributed denial-of-service attack.
					</li>

					<li>
						You may not use any language, or post, upload, transmit, send or
						otherwise make available on or through the Website any content that
						we deem to be offensive, immoral, vulgar, crude, harmful, violent,
						deceptive, or otherwise inappropriate.
					</li>

					<li>
						You may not post, upload, transmit, send or otherwise make available
						on or through the Website any content that you are bound to not
						disclose, by agreement, contract, fiduciary duty, employment
						relationship, or otherwise, such as insider information, proprietary
						and/or confidential information, or trade secrets.
					</li>

					<li>
						You may not post, upload, transmit, send or otherwise make available
						on or through the Website any content that infringes, violates, or
						breaches the copyright, trademark, trade secret or any other
						personal or proprietary right of us, our licensors, vendors, other
						users, and/or any third party.
					</li>

					<li>
						You may not copy, reproduce, modify, change, edit, crop, alter,
						revise, adapt, translate, enhance, reformat, remix, rearrange,
						resize, create derivative works of, move, remove, delete, or erase
						any copyright, trademark, or other proprietary legends, symbols,
						marks, or notices on the Website, or attempt to circumvent any
						mechanisms for preventing the unauthorized reproduction or
						distribution of content.
					</li>

					<li>
						You may not copy, reproduce, modify, change, edit, crop, alter,
						revise, adapt, translate, enhance, reformat, remix, rearrange,
						resize, create derivative works of, move, remove, delete, erase,
						reverse engineer, decipher, decompile, disassemble, store, cache,
						aggregate, publish, post, display, distribute, broadcast, perform,
						transmit, rent, sell, share, sublicense, syndicate, or otherwise
						provide to others, or use any content obtained on or through the
						Website, in whole or in part, except as permitted by law; or reverse
						engineer, decipher, decompile, disassemble, or otherwise attempt to
						derive any source code or underlying ideas or algorithms of the
						Website, in whole or in part. Please do not post any personal
						information, as that term is defined in the Privacy Policy, on
						publicly accessible areas of the Website. If you elect to post any
						information to publicly-accessible areas of the Website, you hereby
						grant to 4inExpo.com a perpetual, worldwide, irrevocable,
						fully-paid-up, royalty-free, nonexclusive license to use, reproduce,
						copy, modify, publish, distribute, perform, display, and create
						derivative works from such information (in whole or part) for any
						reason whatsoever.
					</li>
				</ol>
				<h2 className="h3 my-4">Terms of Service</h2>
				<ol type="a" className="my-3 ml-2 list-[lower-alpha] list-inside">
					<li>
						4inExpo.com will provide the Member with a Locker/Store number at
						the 4inExpo facility in India at which the Member can have mail
						and/or merchandise delivered prior to export.
					</li>

					<li>
						4inExpo.com’s services consist of acting as an agent of the Member
						for consolidation and export purposes, by receiving the Member's
						merchandise, which will be sorted, packed, and consolidated by
						4inExpo. At the Member's direction, 4inExpo.com arranges for export
						to the Member's specified local address by an unaffiliated service
						provider, which acts as the freight forwarder and/or carrier for the
						shipment. 4inExpo.com does not act as an indirect air carrier of
						property, but rather acts as an agent of the shipper (i.e.,
						Members).
					</li>

					<li>
						All packages received by 4inExpo.com will be opened by
						representatives of the company for validation.
					</li>

					<li>
						Members are responsible for all import and export obligations in the
						country to which all mails and packages will be sent. Members also
						authorize 4inExpo.com as its agent to authorize freight forwarder
						and/or carriers to conduct customs clearance and entry and to
						certify freight forwarder and/or carriers as the consignee solely
						for the purpose of designating a customs broker to perform customs
						clearance and entry.
					</li>

					<li>
						Members accept that freight forwarders and/or carriers reserve the
						right to open and/or inspect every shipment tendered to forwarders
						and/or carriers on behalf of 4inExpo Member.
					</li>

					<li>
						4inExpo advises the Member to insure the goods handed over for
						transportation as per the consignor insurance information provided
						athttps://www.4inExpo.com/FAQ.
					</li>

					<li>
						4inExpo shall have a general lien over all the consignments of the
						Member towards any dues payable to 4inExpo by the Member.
					</li>

					<li>
						No claim shall be entertained by the 4inExpo for any loss or
						shortage/damage/non-delivery/breakage/leakages/pilferage etc. of the
						consignment unless a written claim is lodged within 3-days from the
						date of the shipment, subject to remarks on the proof of delivery.
						4inExpo shall not entertain any claims arising due to delay in
						delivery of merchandize for any reason.{" "}
					</li>
				</ol>
				<h2 className="h3 my-4">Compliance with Laws</h2>
				a.Compliance with Export and Import Laws: The Member represents and
				warrants that (i) the recipient of any items consolidated by 4inExpo.com
				is the end user of any and all merchandise and mail that the Member has
				requested be exported through a freight forwarder or carrier to the
				recipient's address, or, if the Member is reselling the merchandise, the
				Member can identify the end users and provide 4inExpo.com with any
				requested information on such end users, (ii) any and all merchandise
				and mail that the Member requests be consolidated by 4inExpo.com will be
				used in the country to which the Member requests shipment, and (iii) the
				Member will not re-export the merchandise and mail to a different
				destination. The Member may not return to India or resell in or into
				India any food products or dietary supplements it receives through
				4inExpo.com, and the Member shall require any recipients of the food
				products or dietary supplements to adhere to the same requirements.
				4inExpo.com reserves the right to request that the Member or the
				recipient of an item, as applicable, sign documents confirming the end
				use or end user of any item exported through a freight forwarder or
				carrier by 4inExpo.com. If you are a reseller, you agree to be bound by
				the following terms: i.As a condition of your membership with
				4inExpo.com, you agree to be bound by all terms and conditions of
				4inExpo.com. The 4inExpo.com Terms & Conditions are subject to change at
				any time. ii.You shall not use, transfer, re-export, resell, or
				otherwise dispose of any items consolidated and/or exported using the
				services of 4inExpo.com to any destination, end-user, or for any end-use
				prohibited by the laws of India. By signing and returning this letter,
				you agree that you will comply with all relevant export laws of India.
				This includes ensuring that any names or addresses that you add to your
				account and customers to whom you resell do not appear on an Indian
				Government denied party list. b.Your Personal Information: i.In
				connection with your use of the Website and out interactions with You,
				We will collect information about You, including your name, contact
				details, and email address (your “Personal Information”), subject to
				obtaining your consent, to provide You with information about our
				services and products. ii.We will handle your Personal Information in
				accordance with applicable data protection law and will maintain
				appropriate data security procedures designed to protect against loss or
				compromise of personal data. iii.We may disclose your Personal
				Information to third parties, who handle it on Our behalf. Depending on
				the circumstances, We may transfer your Personal Information abroad to
				countries whose Personal Information protection laws are less strict
				than in India. If so, We will ensure that appropriate safeguards are put
				in place. iv.You have certain rights under applicable data protection
				law, including the right to access certain Personal Information that We
				hold about You and to have any incorrect Personal Information corrected.
				v.More detail about how We process your Personal Information and how You
				may exercise your rights in respect of that information is set out in
				the Privacy Policy. You should review this Privacy Policy to ensure that
				You understand how We process your Personal Information and your rights
				in respect of it. vi.Should You have any questions about Our use of your
				Personal Information, please contact Us via the contact details set out
				in the Privacy Policy. c.Prohibited and Restricted Items: The following
				list is a non-exclusive sampling of items that are restricted, cannot be
				shipped or, depending on your destination country, may either require an
				import license, additional documentation and/or cause problems and
				delays during the customs review of your shipment:
				i.Combustible/flammable items (paints, oils, lighters, perfume, nail
				polish). ii.Any type of pressurized can (hair spray, shaving cream,
				spray cans of any type). iii.Hazardous materials (matches, chemicals,
				explosives). iv.Firearms, weaponry and their parts: military, police and
				tactical equipment of any kind, including guns, gun replicas, gun
				accessories, gun components (magazines, clips and rail systems),
				ammunition, knives, swords, compound bows, crossbows, discharge weapons
				such as shock batons or stun guns, tooling for guns or gun parts,
				optical scopes, laser sights, night sights, night visions goggles,
				handcuffs and other restraints, surveillance equipment. v.Agricultural
				products such as tobacco, plants and seeds (restricted to some
				destinations). vi.Coffee (restricted to some destinations)
				vii.Perishable foods (refrigerated, frozen, fresh/raw fruits and
				vegetables, etc.) Note that any food (including dietary supplements)
				received through 4inExpo.com may not be returned to or resold in India.
				viii.Animals and products made with animal skin (furs). ix.Alcohol
				(4inExpo.com may be able to assist you with shipping table wine through
				unaffiliated freight forwarders and carriers in limited quantities to
				some countries, but not to Muslim countries such as Saudi Arabia).
				x.Cash, currency, money orders, cashiers' checks, bank drafts, bearer
				bonds and collectible stamps and coins, Jewellery and precious stones.
				xi.Lottery tickets and gambling devices. xii.Prescription and/or
				veterinary medication. xiii.Pornography. xiv.Lock picking devices.
				xv.Government IDs and licenses, or items that claim to be, or that look
				similar to, government identification documents. This includes uniforms
				and badges or patches designed to look like official government-issues.
				xvi.The Member understands and agrees that any software and/or data
				residing on used computer equipment or other electronic storage device
				is the sole responsibility of the Member. Department of Commerce and any
				requirements pertaining to the shipping of any such software and/or data
				to any country to, from, through or over which the Member's shipment may
				be carried. Members also understand that 4inExpo.com is not responsible
				for the loss of any software and/or data. xvii.The Member acknowledges
				that 4inExpo.com will not directly or indirectly, participate in any way
				in any transaction involving any commodity, software or technology
				(hereinafter collectively referred to as "item") exported or to be
				exported from India that is subject to the ITAR or that would require an
				export license under the EAR, except in instances where the item subject
				to the EAR is eligible for an export license exception that has been
				approved for use by 4inExpo.com. The Member also acknowledges that
				4inExpo.com does not allow for the use of exemptions under the ITAR.
				xviii.The Member is responsible for confirming that prohibited items
				listed in the 4inExpo.com Prohibited and Restricted Item page are not
				sent to 4inExpo.com. See Prohibited Items Guide for more information.
				4inExpo.com reserves the right to return packages to the sender(s) for
				any reason, to provide packages (and information concerning the same) to
				government authorities, or to otherwise discard packages (including by
				selling forfeited items with the sales proceeds going to charity).
				xix.Additional information is available on our Prohibited and
				Restricted Click Here to learn more. d.Prohibited and Restricted End
				Users and Destinations: 4inExpo.com will not provide services to the
				following countries/regions: i.Cuba ii.Iran iii.Iraq iv.Turkey
				v.Morocco* vi.Myanmar* vii.North Korea viii.Syria ix.Sudan x.Crimean
				Peninsula xi.Afghanistan Countries, where we don’t ship includes
				Pakistan, e.Fraud Prevention: i.4inExpo.com uses commercially reasonable
				efforts to prevent fraudulent use of the Website. NOTWITHSTANDING THE
				FOREGOING, 4inExpo.com WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED
				BY FRAUDULENT USE OF THE WEBSITE BY YOU OR BY THIRD PARTIES. If you
				think there has been fraudulent activity with respect to your account,
				you must contact 4inExpo.com immediately, and 4inExpo.com will cancel
				such account. ii.You may not use the Website for multi-level marketing,
				pyramid schemes, receiving checks and/money orders, or other business
				practices easily subject to abuse. iii.You represent, warrant, and
				covenant that all information you provide to 4inExpo.com is accurate and
				complies with all applicable local, state, and federal laws and
				regulations, including any information regarding the value or export
				classification of merchandise. iv.You are solely liable for such
				information and for maintaining all records as required under law. You
				may be subject to civil or criminal penalties by the Indian Government
				for making false or fraudulent statements with respect to such
				information. f.Members shall use the Website in strict compliance with
				all applicable laws, rulings, and regulations and in a manner that does
				not negatively affect the goodwill or reputation of the Proprietor.
				Members shall not take any action that may cause the 4inExpo to be held
				in breach of any laws, rulings or regulations applicable to it. g.Export
				control regulations: 4inExpo and the Website are based in India, which
				may control the export of products and information. Member agrees to
				comply with all such applicable restrictions and not to export or
				re-export the content (including any software or the services) to
				countries or persons prohibited under India or other applicable export
				control laws or regulations. If Member accesses and downloads the
				content (including any software or the Services) or information, such
				users shall be deemed to have represented that they are not in a country
				where such export is prohibited or are not persons or entities to whom
				such export is prohibited. h.Legality of shipment: Member shall be
				solely responsible for compliance with the laws of their local
				jurisdiction and any other applicable laws regarding the import, export,
				or re-export of, and to ensure the legality of the proposed shipments
				regarding which the Member is requests, including but not limited to
				ensuring that the proposed shipments comply with applicable sanctions or
				restrictive measures. i.Customs: Member shall reimburse 4inExpo for all
				customs-related duties, fees and associated costs incurred by 4inExpo on
				behalf of Member with respect to the consignment, including all such
				duties, fees and costs incurred in connection with customs duty
				clearance. For the purpose of this clause, a “customs duty” includes any
				duty or charge of any kind imposed on or in connection with the
				importation or exportation of a good, including any form of surtax or
				surcharge imposed on or in connection with such importation or
				exportation. j.Nothing in these Terms shall constitute or be deemed to
				constitute an agreement between Parties towards a hereto or constitute
				or be deemed to constitute 4inExpo as providing freight services of any
				kind or for any purpose whatsoever, and 4inExpo shall not be bound by,
				have liability towards any other Party, in any contract pertaining to
				freight services. 9. Appointment of 4inExpo.com as Member’s Agent As a
				Member, you grant 4inExpo.com a written authorization to act on your
				behalf as your true and lawful agent for the following specific
				purposes: a.Preparation of shipping documents; preparation of any
				Certificates of Origin; b.Preparation of an airway bill or any other
				document required to export merchandise to Member; c.Entering into
				service agreements on your behalf and to authorize parties to such
				service agreements with a power of sub-delegation for the preparation
				and completion of all documentation required for the clearance and
				delivery of all shipments subject to such agreements; and
				d.Authorization for freight forwarder and/or carriers to conduct customs
				clearance and entry, and certification of freight forwarder and/or
				carriers as the consignee solely for the purpose of designating a
				customs broker to perform customs clearance and entry. 10.
				Indemnification The Member agrees that he/she will remain liable for and
				indemnify, defend, and hold harmless 4inExpo.com and its shareholders,
				officers, directors, agents, partners, employees and independent
				contractors, at all times from the date hereof, from and against any and
				all claims, actions, damages, awards, liabilities, losses (including
				consequential losses), judgments, penalties, interest, fines, expenses,
				and/or other costs (including attorneys' charges and court costs)
				arising by reason of the execution hereof or the consummation of the
				transactions contemplated hereby, including without limitation those
				arising from or relating to: a.any negligent action or omission of
				Member or any of the Member's employees, contractors, agents or any
				other person acting under Member's supervision or control prior to, as
				of, or following the date hereof; b.any inaccuracy or breach of any
				representation or warranty made by Member in these Terms or any other
				document or instrument executed or delivered by Member in connection
				with these Terms; c.any breach or non- performance of any covenant or
				agreement made by company in these Terms or any other document or
				instrument made by Member in connection with these Terms; d.any all
				claims, losses, charges and expenses incurred by 4inExpo due to any
				banned, restricted or dangerous items entering in to the network of the
				4inExpo due to any omission or commission of Member; e.4inExpo.com's
				preparation, determination or execution of the documents or any other
				document necessary for transportation, including but not limited to
				preparing address information, routing, classification, licensing
				requirements, and listing the value of goods or the value for carriage,
				except for claims arising solely from the gross negligence or willful
				misconduct of 4inExpo.com; f.the Member providing to 4inExpo.com any
				incomplete or false information; and g.the Member's failure to comply
				with the terms hereof or of any jurisdiction's law applicable to the
				exportation or importation of such shipments. h.The Member acknowledges
				that the foregoing provisions are a material inducement for 4inExpo.com
				to enter into these Terms and the transactions contemplated hereby, and
				shall survive termination of these Terms. 11. Limitation of Liability
				UNDER NO CIRCUMSTANCES WILL ANY OF THE 4inExpo PARTIES BE LIABLE FOR ANY
				LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED THROUGH
				THIS WEBSITE. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY,
				COMPLETENESS, OR USEFULNESS OF THIS WEBSITE. IN NO EVENT SHALL ANY OF
				THE 4inExpo PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
				INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR RELATING
				TO THE WEBSITE, THE CONTENT OR THIS AGREEMENT, WHETHER BASED ON
				WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY. BECAUSE SOME
				JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
				NEGLIGENCE, CONSEQUENTIAL, INCIDENTAL OR OTHER DAMAGES, IN SUCH
				JURISDICTIONS THE 4inExpo PARTIES’ LIABILITY IS LIMITED TO THE GREATEST
				EXTENT PERMITTED BY LAW. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THIS
				WEBSITE IS TO STOP USING THIS WEBSITE. 12. Disclaimer of Warranties YOU
				ACKNOWLEDGE AND AGREE THAT THIS WEBSITE IS PROVIDED ON AN “AS IS” AND
				“AS AVAILABLE” BASIS. NONE OF 4inExpo, ITS AFFILIATES, SUBSIDIARIES OR
				ITS OR THEIR OFFICERS, MANAGERS, DIRECTORS, EMPLOYEES OR AGENTS
				(COLLECTIVELY THE “4inExpo PARTIES”) GUARANTEES THE ACCURACY,
				COMPLETENESS, TIMELINESS, RELIABILITY, SUITABILITY OR USEFULNESS OF ANY
				PORTION OF THE WEBSITE. NONE OF THE 4inExpo PARTIES WARRANT THAT THIS
				WEBSITE WILL BE UNINTERRUPTED OR ERROR FREE, THAT ANY SPECIFIC
				INFORMATION THAT IS REQUESTED WILL BE PROVIDED OR THAT THIS WEBSITE OR
				ITS SERVER ARE OR WILL BE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL
				ELEMENTS. YOU EXPRESSLY AGREE THAT THE ENTIRE RISK AS TO THE QUALITY AND
				PERFORMANCE OF THIS WEBSITE AND THE ACCURACY, TIMELINESS OR COMPLETENESS
				OF THE CONTENT IS ASSUMED SOLELY BY YOU. NONE OF THE 4inExpo PARTIES
				MAKES ANY, AND HEREBY SPECIFICALLY DISCLAIMS ANY AND ALL,
				REPRESENTATIONS, ENDORSEMENTS, GUARANTEES, AND WARRANTIES, EXPRESS OR
				IMPLIED, REGARDING THIS WEBSITE, INCLUDING WITHOUT LIMITATION, THE
				IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
				PURPOSE, TITLE AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. YOU
				UNDERSTAND AND AGREE THAT ANY CONTENT, MATERIAL AND/OR DATA DOWNLOADED
				OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE IS USED AT YOUR OWN
				RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
				COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH
				CONTENT, MATERIAL AND/OR DATA. 13. Severability Any use in contravention
				of this provision or any provision of these Terms is at your own risk
				and, if any part of these Terms is invalid or unenforceable under
				applicable law, the invalid or unenforceable provision will be deemed
				superseded by a valid, enforceable provision that most closely matches
				the intent of the original provision and the remainder of the agreement
				shall govern such use. 14. Customer Support. For any queries, enquiries,
				of information on Shipment tracking, Services, associating, complaints
				and escalations, Refund, Cancellation, can use the chat-boot facilitated
				with the website, or mail us on info@4inExpo.com, sales@4inExpo.com, or
				feel free to call us at Toll Free number 1800…… , 24 x 7 days a week, or
				can write us to “ 504, Green Hill, Lokandwala, Kandivali, Mumbai 400101,
				Maharashtra, India” . Refund & Cancellation Policy 15. Return policy
				update Provision are made for all products be acknowledged by the
				customer before dispatching on the international shipment part of
				business. For returning of product at any state of business (Both
				Domestic and International) customer can avail product under Return
				policy as per agreed rate prior to shipping. You may return most new,
				unopened items sold and fulfilled by 4inExpo within 30 days of delivery
				for a full refund. Mistaken Returns Ensure you enclose the correct and
				complete item when making a return. The correct item must be returned to
				receive a refund. 4inExpo does not store items mistakenly sent, instead
				donates, or recycles them, when appropriate and possible. If you
				accidentally, sent the wrong item to 4inExpo, please contact Customer
				Support service as soon as possible. 4inExpo cannot guarantee your item
				will be found and returned, and no compensation is provided for
				incorrect items sent back to 4inExpo. 4inExpo Global Return Policy It
				can take up to 30 days for an item to reach us once you return it. Once
				the item is received at our fulfilment centre, it takes 14 business days
				for the refund to be processed and 7-10 business days for the refund
				amount to show up in your account. Not all Global Store items are
				eligible for a pre-paid return. In this case, you will need to return
				the item at your own expense if return policy is not taken. We recommend
				you return the item(s) using a trackable method at the carrier of your
				choice, and the goods received back on return should be in good
				condition for availing full refund. Handmade in-house product are
				subject to the standard Guarantee policies for 4inExpo, and should be
				returned back in good condition as it was shipped to the customer. The
				exception to this are items that are purchased with optional
				customization options, other than size and colour. See Customized
				Products for additional details. Customized Products Customized products
				include products that are configured, personalized, or inscribed; for
				example, engravings, stamps, monograms, embroidery, embossing, etching,
				carving, or printing or any personalised buy. Customized products sold
				fall under the standard Guarantee policy. To file a claim for these
				items please note the following: Notification to the third-party seller
				within 2 days of receipt of item for return. Due to personal
				configuration, inscription, or design on these products, the third-party
				seller is not required to accept returns or exchanges unless the
				products are damaged/defective upon arrival, or materially different
				from what was ordered. If there is a misspelling or error in the
				configuration, inscription, or design due to a mistake by the
				third-party seller, you are protected per the 4inExpo standard
				Guarantee. All product packaging (such as boxes) and if applicable;
				certificates of authenticity, grading, and appraisal must be returned
				with the item. Products missing all their applicable tags are not
				acceptable for return. Handicraft, Jewellery, and Fine Art Handicraft,
				Jewellery, and Fine Art items with a value of $20 or more and booked
				under return policy (Priority service) must be returned using a
				trackable shipping method. Items with a value more than $500 must also
				be insured. All product packaging (boxes, etc.) and certificates of
				authenticity and appraisal must be returned with the item. Any
				Handicraft, Jewellery, and Fine Art item returned without original
				documentation will be rejected. Any Handicraft, Jewellery, and Fine Art
				items that have been damaged, or otherwise altered after delivery, won't
				be accepted for return. Before sending a Handicraft, Jewellery, and Fine
				Art return, photograph the item for your records. Clothing and
				Accessories You can return clothing and accessories up to 2 days after
				the delivery date. Items from (Privilege services) are eligible for
				returns, if the items is seen not matching with the placed order by the
				customer then the return shipping fee will be waved off because the item
				are shipped under return policy. 16. Delivery & Shipping Delivery &
				Shipping will be based on the Shipping partner the customer will be
				opting for from the list of shipping partners. All the consignments will
				be mapped back to back as per norms of shipping partner, on cost,
				dimension, restricted items as per the receiving country, Duration of
				delivery and end to end norms.
			</div>
		</div>
	);
};

export default TermsAndConditions;
