import {
	BrowserRouter,
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";
// import your route components too
import Forgot from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Reset from "../pages/auth/ResetPassword";
// import Login from "../pages/auth/Login";
import BuyYourselfAddItemInputs from "components/AddItems/BuyYourselfAddItemInputs";
import CustomizeAddItemInputs from "components/AddItems/CustomizeAddItemsInputs";
import MedicineAddItemsInputs from "components/AddItems/MedicineAddItemsInputs";
import WeBuyAddItemInputs from "components/AddItems/WeBuyAddItemInputs";
import Footer from "components/Footer";
import Header from "components/Header";
import ContactUs from "components/HomePage/ContactUs";
import Sidebar from "components/Sidebar";
import AdminCategories from "pages/AdminCategories";
import AdminProducts from "pages/AdminProducts";
import CountriesList from "pages/CountriesList";
import NewOrders from "pages/NewOrders";
import OrderHistory from "pages/OrderHistoryDashboard";
import PaymentHandler from "pages/PaymentHandler";
import PaymentResponseHandler from "pages/PaymentResponseHandler";
import Product from "pages/Product";
import ProductDetails from "pages/ProductDetails";
import ProhibitedItems from "pages/ProhibitedItems";
import TermsAndConditions from "pages/TermsAndConditions";
import UsersList from "pages/UsersList";
import BuyYourselfDraftPages from "pages/orders/BuyYourselfDraftPages";
import CustomizeDraftPages from "pages/orders/CustomizeDraftPages";
import InHouseDraftPages from "pages/orders/InHouseDraftPage";
import InReview from "pages/orders/InReview";
import MedicineDraftPages from "pages/orders/MedicineDraftPages";
import OrderActions from "pages/orders/OrderActions";
import Procuring from "pages/orders/Procuring";
import ReadyToBeShipped from "pages/orders/ReadyToBeShipped";
import Shipped from "pages/orders/Shipped";
import UserOrders from "pages/orders/UserOrders";
import ViewDetailsPage from "pages/orders/ViewDetailsPage";
import WeBuyDraftPages from "pages/orders/WeBuyDraftPages";
import EditAddress from "pages/profile/EditAddress";
import Profile from "pages/profile/Profile";
import ProfileRegistration from "pages/profile/ProfileRegistration";
import Home from "../pages/Home";
import OrdersDashboard from "../pages/OrdersDashboard";
import Dashboard from "../pages/UserDashboard";

const GeneralLayout = () => {
	return (
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
};

const IsAllowedRoute = ({ allowedRoles }) => {
	const role = useUserStore((state) => state.role);
	const isAllowed = allowedRoles?.includes(role) || false;
	const location = useLocation();
	const data = location.state;
	return isAllowed ? (
		<>
			<Header />
			<Sidebar />
		</>
	) : (
		<Navigate
			to="/unauthorized"
			state={{ from: location, ...data }}
			replace={true}
		/>
	);
};

const ProtectedRoute = ({ allowedRoles }) => {
	const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
	const isRegistrationComplete = useUserStore(
		(state) => state.isRegistrationComplete
	);
	const isUserVerified = useUserStore((state) => state.isUserVerified);
	console.log("is Registration complet data is", isRegistrationComplete);
	const location = useLocation();
	const data = location.state;
	console.log("DATA:", data);
	console.log("protected Route");

	return isLoggedIn ? (
		isRegistrationComplete === "true" ? (
			isUserVerified === "true" ? (
				<IsAllowedRoute allowedRoles={allowedRoles} stateData={data} />
			) : (
				<>
					<Header />
					<div className="">
						<div className="bg-white p-6  flex justify-center">
							<div className="text-center bg-teal-100 mx-auto p-4">
								<div>
									Please Wait for admin to verify your details within 48 hours.
								</div>
								<div>Happy to help for any further queries.</div>

								<p> Have a great day ! </p>
							</div>
						</div>
						<ContactUs />
					</div>
					<Footer />
				</>
			)
		) : (
			<>
				<Header />
				<ProfileRegistration />
				<Footer />
			</>
		)
	) : (
		<Navigate to="/login" state={{ from: location, ...data }} replace />
	);
};
// const AuthRoutes = () => {
//   const location = useLocation();
//   const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
//   console.log("location");
//   console.log(location);
//   return isLoggedIn ? (
//     <Navigate to="/dashboard" state={{ from: location }} replace />
//   ) : (
//     <Outlet />
//   );
// };
export default function Main() {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<GeneralLayout />}>
					<Route path="/" index element={<Home />} />
					<Route path="/products" element={<Product />} />
					<Route
						path="/product-details/:productId"
						element={<ProductDetails />}
					/>
					<Route path="/terms" element={<TermsAndConditions />} />
					<Route path="/prohibited-items" element={<ProhibitedItems />} />

					{/* <Route element={<AuthRoutes />}> */}
					<Route path="/login" element={<Login restricted={true} />} />
					<Route path="/register" element={<Register restricted={true} />} />
					<Route path="/forgot" element={<Forgot restricted={true} />} />
					<Route path="/reset/:id" element={<Reset restricted={true} />} />
					{/* </Route> */}
					<Route
						path="/unauthorized"
						element={
							<section>
								<h1>Unauthorized</h1>
								<br />
								<p>You do not have access to the requested page.</p>
								<div className="flexGrow"></div>
							</section>
						}
					/>
					<Route
						path="*"
						element={
							<div className="my-20 mx-auto text-center">
								This route does not exist{" "}
							</div>
						}
					/>
				</Route>
				<Route
					element={
						<ProtectedRoute allowedRoles={["employee", "partner", "admin"]} />
					}
				>
					<Route path="/dashboard" element={<Dashboard />} />
				</Route>
				<Route element={<ProtectedRoute allowedRoles={["user"]} />}>
					<Route element={<OrdersDashboard />}>
						{/* <Route path="/orders/to-order" element={<ToOrder />} /> */}
						<Route path="/user/orders" element={<UserOrders />} />
					</Route>
					{/* New Order Routes */}
					<Route element={<NewOrders />}>
						<Route
							path="/user/buy-yourself"
							element={<BuyYourselfAddItemInputs />}
						>
							<Route path=":orderId" element={<BuyYourselfDraftPages />} />
						</Route>
						<Route path="/user/we-buy-4-u" element={<WeBuyAddItemInputs />}>
							<Route path=":orderId" element={<WeBuyDraftPages />} />
						</Route>
						<Route path="/user/medicine" element={<MedicineAddItemsInputs />}>
							<Route path=":orderId" element={<MedicineDraftPages />} />
						</Route>
						<Route
							path="/user/customized-buy"
							element={<CustomizeAddItemInputs />}
						>
							<Route path=":orderId" element={<CustomizeDraftPages />} />
						</Route>
					</Route>
					<Route path="/user/item/:itemId" element={<ViewDetailsPage />} />
					<Route path="/user/payment/:orderId" element={<PaymentHandler />} />
					<Route
						path="/user/payment/:orderId/response/:type"
						element={<PaymentResponseHandler />}
					/>
					<Route
						path="/user/orders/:orderId"
						element={<PaymentResponseHandler />}
					/>
					<Route path="/user/actions/:orderId" element={<OrderActions />} />
					<Route
						path="/user/in-house"
						element={<Product navigateUrl={"/user/in-house"} />}
					/>
					<Route
						path="/user/inhouse/:orderId"
						element={<InHouseDraftPages />}
					/>
					<Route
						path="/user/:orderId/in-house"
						element={<Product navigateUrl={"/user/in-house"} />}
					/>
					<Route
						path="/user/:orderId/in-house/:productId"
						element={<ProductDetails />}
					/>
					<Route
						path="/user/in-house/:productId"
						element={<ProductDetails />}
					/>
				</Route>

				<Route
					element={
						<ProtectedRoute
							allowedRoles={["user", "employee", "partner", "admin"]}
						/>
					}
				>
					<Route element={<OrdersDashboard />}>
						<Route path="/orders/review" element={<InReview />} />
						<Route path="/orders/procuring" element={<Procuring />} />
						<Route
							path="/orders/ready-to-be-shipped"
							element={<ReadyToBeShipped />}
						/>
						<Route path="/orders/shipped" element={<Shipped />} />
					</Route>
					<Route path="/history" element={<OrderHistory />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/history" element={<OrderHistory />} />
					<Route path="/edit-profile-address" element={<EditAddress />} />
				</Route>
				<Route
					element={<ProtectedRoute allowedRoles={["employee", "admin"]} />}
				>
					<Route path="/categories" element={<AdminCategories />} />
					<Route path="/countries" element={<CountriesList />} />
					<Route path="/admin-products" element={<AdminProducts />} />
					<Route path="/users-list" element={<UsersList />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}
