import Loading from "components/Loading";
import { getCategories } from "queries/categoryQueries";
import { getProducts } from "queries/productQueries";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
export default function Product({ navigateUrl }) {
	const { orderId } = useParams();
	const [checked, setChecked] = useState([]);
	const categories = useQuery("categories", getCategories, {
		onSuccess: (data) => {
			console.log("data :", data);
			setCategory(data.length > 0 && data[0].name);
		},
	});
	const { data, isLoading, isError, error } = useQuery("products", getProducts);
	const [category, setCategory] = useState(
		categories.data && categories.data[0].name
	);
	const orderIdUrlValue = orderId ? `${orderId}/in-house/` : "in-house/";
	console.log("orderId value ", orderIdUrlValue);
	const navigate = useNavigate();

	const onChangeHandler = (e, name) => {
		console.log("e", e.target.checked);
		if (e.target.checked) {
			setChecked([...checked, name]);
		} else {
			const newValue = checked.filter((item) => item !== name);
			setChecked(newValue);
		}
		console.log("onChange", name);
	};

	if (isLoading || categories.isLoading) {
		return <Loading />;
	}

	if (isError || categories.isLoading) {
		console.log(error);
		return <div>Something went wrong!!!</div>;
	}
	return (
		<div className="bg-[#F0FEFC]">
			<div className="container mx-auto flex flex-col">
				<h1 className="font-bold text-4xl text-center my-12">
					In House Products
				</h1>
				<div className="grid grid-cols-4 ">
					<div className="p-4 flex flex-col gap-4">
						<h4 className="font-bold text-2xl">Categories</h4>
						{categories.data?.map((item) => (
							<div className="flex items-center" key={item.id}>
								<label>
									<input
										type="checkbox"
										className="mr-2"
										onChange={(e) => onChangeHandler(e, item.name)}
										name={item.name}
									/>{" "}
									{item.name}
								</label>
							</div>
						))}
					</div>
					<div className="col-span-3 flex gap-6 mb-4">
						{data.length > 0
							? data
									?.filter((filtered) =>
										checked.length > 0
											? checked.includes(filtered.category.name)
											: filtered
									)
									.map((item) => (
										<div
											className="bg-white rounded-md overflow-hidden"
											onClick={() =>
												navigate(
													navigateUrl === null || navigateUrl === undefined
														? `/product-details/${item._id}`
														: `/user/${orderIdUrlValue}${item._id}`
												)
											}
											key={item._id}
										>
											<img
												src={item.images[0]}
												alt="Image"
												width={300}
												height={220}
											/>
											<div className="flex felx-row p-4 justify-between">
												<p className="mr-6">{item.name}</p>
												<p>₹{item.price}/-</p>
											</div>
										</div>
									))
							: "There are no products to display"}
					</div>
				</div>
			</div>
		</div>
	);
}
