import { checkAuth } from "hooks/checkAuth";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";
import { uploader, uploadSingleImage } from "../helpers/ImageHelper";

export async function getAllOrders() {
	await checkAuth();

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log(`get all orders`);
	const res = await fetch(`${baseURL}/orders/all`, requestOptions);
	const resjson = await res.json();
	if (!res.ok) setErrorData(res, resjson);

	const filteredData = await resjson.filter(
		(data) => data.orderStatus === "draft"
	);
	const orders = await resjson.filter((data) => data.orderStatus !== "draft");
	const newJson = { bag: filteredData, orders };
	console.log("new json is:", newJson);
	return newJson;
}

export async function getSingleOrder(id) {
	await checkAuth();

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log(`get single orders`);
	const res = await fetch(
		`${baseURL}/orders/singleOrder?orderId=${id}`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function getSingleOrderWithItemId(id) {
	await checkAuth();

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log(`get single orders`);
	const res = await fetch(
		`${baseURL}/orders/single-order-with-item-id?itemId=${id}`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function getOrders(status) {
	await checkAuth();

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log(`get orders for status ${status}`);
	const res = await fetch(`${baseURL}/orders?status=${status}`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function getOrderHistory(status) {
	await checkAuth();

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
	};
	console.log(`get orders for status ${status}`);
	const res = await fetch(`${baseURL}/orders/history`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function createOrder(data) {
	await checkAuth();

	console.log("Order Query Data");
	console.log(data);

	const newImages = await uploader(data.userImages);

	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({
			name: data.name,
			description: data.description,
			quantity: data.quantity,
			itemType: data.itemType,
			orderType: data.orderType,
			isPremiumAvailed: data.isPremiumAvailed,
			requirements: data.requirements,
			userImages: newImages,
		}),
	};
	console.log(`Create New order`);

	const res = await fetch(`${baseURL}/orders`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function createInhouseOrder(data) {
	await checkAuth();

	console.log("Order Query Data");
	console.log(data);

	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};
	console.log(`Create New order`);

	const res = await fetch(`${baseURL}/orders/create-inhouse`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function addInhouseItemToOrder(data) {
	await checkAuth();

	console.log("add item to order");
	console.log(data);

	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};
	console.log(`Add item to order`);

	const res = await fetch(
		`${baseURL}/orders/add-item-to-inhouse`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function addItemToOrder(data) {
	await checkAuth();

	console.log("add item to order");
	console.log(data);

	const newImages = await uploader(data.formData.userImages);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({
			name: data.formData.name,
			description: data.formData.description,
			quantity: data.formData.quantity,
			userImages: newImages,
			orderId: data.orderId,
			isPremiumAvailed: data.formData.isPremiumAvailed,
			requirements: data.formData.requirements,
			itemType: data.formData.itemType,
		}),
	};
	console.log(`Add item to order`);

	const res = await fetch(`${baseURL}/orders`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function deleteOrder(data) {
	await checkAuth();
	console.log("Delete Order");
	console.log(data);
	const requestOptions = {
		method: "DElETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({
			itemId: data.itemId,
			orderId: data.orderId,
		}),
	};

	const res = await fetch(`${baseURL}/orders`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function deleteItemInOrder(data) {
	await checkAuth();
	console.log("Delete Order");
	console.log(data);
	const requestOptions = {
		method: "DElETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/item`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function userCancelOrder(data) {
	await checkAuth();
	console.log("Cancel Order");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ orderId: data }),
	};

	const res = await fetch(`${baseURL}/orders/cancel`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}
export async function submitOrder(data) {
	await checkAuth();
	console.log("Submit order for review");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/submit`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function userAckonwledgedReviewedItem(data) {
	await checkAuth();
	console.log("item is reviewed by customer");
	console.log(data);

	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/item-acknowledge`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function userAckonwledgedReviewed(data) {
	await checkAuth();
	console.log("Order is reviewed and being sent to customer for review");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/acknowledge`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function userLogisticsPayment(data) {
	await checkAuth();
	console.log("Logistics Payment");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ orderId: data }),
	};

	const res = await fetch(
		`${baseURL}/orders/logistics-payment`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function userAddAddress(data) {
	await checkAuth();
	console.log("Add Address");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/add-address`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

//admin and partner queries

export async function adminAddReviewDetails(data) {
	await checkAuth();
	console.log("Edit item in order while reviewing");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/review/edit`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminAddCommentForItem(data) {
	await checkAuth();
	console.log("Edit item in order while reviewing");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(
		`${baseURL}/orders/review/add-comment`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminConfirmReviewed(data) {
	await checkAuth();
	console.log("Order is reviewed and being sent to customer for review");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ orderId: data }),
	};

	const res = await fetch(
		`${baseURL}/orders/review/confirm-reviewed`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminMarkItemIsProcurable(data) {
	await checkAuth();
	console.log("Mark item if it is procurable");
	console.log(data);
	// console.log(itemId);
	// console.log(isProcurable);
	// const data = { orderId, itemId, isProcurable };
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(
		`${baseURL}/orders/review/is-procurable`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminMarkProcured(data) {
	await checkAuth();
	console.log("Item is procured and updated");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/confirm-procured`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminWeightandSize(data) {
	await checkAuth();
	console.log("Item is procured and updated");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(
		`${baseURL}/orders/add-weight-and-size`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminAddShippingCost(data) {
	await checkAuth();
	console.log("ShippingCost");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(
		`${baseURL}/orders/add-shipping-cost`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminNotifyCustumerOfShippingCost(data) {
	await checkAuth();
	console.log("Notify Customer Of Shipping cost");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ orderId: data }),
	};

	const res = await fetch(`${baseURL}/orders/ready-to-ship`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminConfirmedDelivered(data) {
	await checkAuth();
	console.log("Notify Customer Of Shipping cost");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ orderId: data }),
	};

	const res = await fetch(`${baseURL}/orders/order-delivered`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminCancelOrder(data) {
	await checkAuth();
	console.log("Admin Cancel Order");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ orderId: data }),
	};

	const res = await fetch(`${baseURL}/orders/cancel-order`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function adminAddTrackingNumber(data) {
	await checkAuth();
	console.log("Tracking Number");
	console.log(data);
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};

	const res = await fetch(`${baseURL}/orders/tracking-number`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function orderPut({ data, processing, query }) {
	await checkAuth();

	console.log("update order information");

	if (query === "addAddressProofImages") {
		const newImages = await uploader(data.addressProof);
		data.addressProof = newImages;
	}
	// if (query === "addImage") {
	//   const newImages = await uploader(processing.images);
	//   data[`${processing.imagesKey}`] = [
	//     ...data[`${processing.imagesKey}`],
	//     ...newImages,
	//   ];
	// }
	// if (query === "replaceImage") {
	//   const newImage = await uploadSingleImage(processing.images);
	//   data[`${processing.imagesKey}`] = newImage;
	// }
	// console.log("data");

	// console.log(data);
	// if (query === "addProcuredImage") {
	//   const newImages = await uploader(data.procuredImages);
	//   data.procuredImages = newImages;
	// }

	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};
	console.log(`Add item to order`);

	const res = await fetch(
		`${baseURL}/orders/order-put?apiQuery=${query}`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function itemPut({ data, processing, query }) {
	await checkAuth();

	console.log("update item in order");
	console.log(data);

	if (query === "addImage") {
		const newImages = await uploader(processing.images);
		data[`${processing.imagesKey}`] = [
			...data[`${processing.imagesKey}`],
			...newImages,
		];
	}
	if (query === "replaceImage") {
		const newImage = await uploadSingleImage(processing.images);
		data[`${processing.imagesKey}`] = newImage;
	}
	console.log("data");

	console.log(data);
	if (query === "addProcuredImage") {
		const newImages = await uploader(data.procuredImages);
		data.procuredImages = newImages;
	}

	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify(data),
	};
	console.log(`Add item to order`);

	const res = await fetch(
		`${baseURL}/orders/item-put?apiQuery=${query}`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function createMedicine(data) {
	await checkAuth();
	console.log("create Medicine Order");
	console.log(data);

	if (data.prescriptionImages.length > 0) {
		const newPrescriptionImages = await uploader(data.prescriptionImages);
		data.prescriptionImages = newPrescriptionImages;
	} else {
		data.prescriptionImages = [];
	}

	const newPassportImage = await uploadSingleImage(data.passportImage);
	data.passportImage = newPassportImage;

	const newVisaImage = await uploadSingleImage(data.visaImage);
	data.visaImage = newVisaImage;

	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ ...data }),
	};
	console.log(`Add medicine item to order`);

	const res = await fetch(`${baseURL}/orders/create-medicine`, requestOptions);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function createBuyYourself(data) {
	await checkAuth();
	console.log("create Buy yourself Order");
	console.log(data.invoiceImages);

	const invoiceImages = await uploader(data.invoiceImages);
	data.invoiceImages = invoiceImages;

	const requestOptions = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ ...data }),
	};
	console.log(`Add Buy Yourself item to order`);

	const res = await fetch(
		`${baseURL}/orders/create-buy-yourself-order`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}

export async function addBuyYourselfToOrder({ data, query }) {
	await checkAuth();

	console.log("add Buy Yourself item to order");
	console.log(data);

	const newImages = await uploader(data.invoiceImages);
	data["invoiceImages"] = newImages;
	const requestOptions = {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: localStorage.getItem("accessToken"),
		},
		body: JSON.stringify({ ...data }),
	};
	console.log(`Add item to order`);

	const res = await fetch(
		`${baseURL}/orders/add-new-item-to-order?apiQuery=${query}`,
		requestOptions
	);
	const resjson = await res.json();

	if (!res.ok) setErrorData(res, resjson);
	return resjson;
}
