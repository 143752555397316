export const currency = {
	USD: {
		code: "USD",
		alphaCode: "USD",
		numericCode: "840",
		name: "U.S. Dollar",
		rate: 0.012191423759603,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 82.024874183566,
	},
	EUR: {
		code: "EUR",
		alphaCode: "EUR",
		numericCode: "978",
		name: "Euro",
		rate: 0.011121408614038,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 89.916667456832,
	},
	GBP: {
		code: "GBP",
		alphaCode: "GBP",
		numericCode: "826",
		name: "U.K. Pound Sterling",
		rate: 0.0097918198645774,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 102.12606173624,
	},
	CAD: {
		code: "CAD",
		alphaCode: "CAD",
		numericCode: "124",
		name: "Canadian Dollar",
		rate: 0.016405283410373,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 60.955972230732,
	},
	JPY: {
		code: "JPY",
		alphaCode: "JPY",
		numericCode: "392",
		name: "Japanese Yen",
		rate: 1.6259207060943,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.61503614306146,
	},
	AUD: {
		code: "AUD",
		alphaCode: "AUD",
		numericCode: "036",
		name: "Australian Dollar",
		rate: 0.018233093658178,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 54.845327882769,
	},
	CHF: {
		code: "CHF",
		alphaCode: "CHF",
		numericCode: "756",
		name: "Swiss Franc",
		rate: 0.010956396831902,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 91.270881781887,
	},
	DOP: {
		code: "DOP",
		alphaCode: "DOP",
		numericCode: "214",
		name: "Dominican Peso",
		rate: 0.66716987523895,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 1.4988686346815,
	},
	CNY: {
		code: "CNY",
		alphaCode: "CNY",
		numericCode: "156",
		name: "Chinese Yuan",
		rate: 0.083849758376044,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 11.926092804171,
	},
	ISK: {
		code: "ISK",
		alphaCode: "ISK",
		numericCode: "352",
		name: "Icelandic Krona",
		rate: 1.6643370370124,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.60083984058606,
	},
	LYD: {
		code: "LYD",
		alphaCode: "LYD",
		numericCode: "434",
		name: "Libyan Dinar",
		rate: 0.058438655079159,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 17.11196122918,
	},
	CLP: {
		code: "CLP",
		alphaCode: "CLP",
		numericCode: "152",
		name: "Chilean Peso",
		rate: 9.8100512270682,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.10193626688113,
	},
	BSD: {
		code: "BSD",
		alphaCode: "BSD",
		numericCode: "044",
		name: "Bahamian Dollar",
		rate: 0.012191423759603,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 82.024874183566,
	},
	XPF: {
		code: "XPF",
		alphaCode: "XPF",
		numericCode: "953",
		name: "CFP Franc",
		rate: 1.331796120317,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.75086568037306,
	},
	ALL: {
		code: "ALL",
		alphaCode: "ALL",
		numericCode: "008",
		name: "Albanian lek",
		rate: 1.2602731988769,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.79347874801363,
	},
	SCR: {
		code: "SCR",
		alphaCode: "SCR",
		numericCode: "690",
		name: "Seychelles rupee",
		rate: 0.16047219816454,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 6.231609035321,
	},
	ANG: {
		code: "ANG",
		alphaCode: "ANG",
		numericCode: "532",
		name: "Neth. Antillean Guilder",
		rate: 0.021900025450138,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 45.662047392446,
	},
	LBP: {
		code: "LBP",
		alphaCode: "LBP",
		numericCode: "422",
		name: "Lebanese Pound",
		rate: 183.0376158295,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.0054633578757466,
	},
	MYR: {
		code: "MYR",
		alphaCode: "MYR",
		numericCode: "458",
		name: "Malaysian Ringgit",
		rate: 0.053809827229051,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 18.583966005751,
	},
	KZT: {
		code: "KZT",
		alphaCode: "KZT",
		numericCode: "398",
		name: "Kazakhstani Tenge",
		rate: 5.5176264408707,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.18123735100889,
	},
	HTG: {
		code: "HTG",
		alphaCode: "HTG",
		numericCode: "332",
		name: "Haitian gourde",
		rate: 1.8847981606342,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.53056078941816,
	},
	BND: {
		code: "BND",
		alphaCode: "BND",
		numericCode: "096",
		name: "Brunei Dollar",
		rate: 0.016241416101427,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 61.570985790588,
	},
	KMF: {
		code: "KMF",
		alphaCode: "KMF",
		numericCode: "174",
		name: "\tComoro franc",
		rate: 5.5397051387652,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.18051502290299,
	},
	LSL: {
		code: "LSL",
		alphaCode: "LSL",
		numericCode: "426",
		name: "Lesotho loti",
		rate: 0.22435686473242,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.4571847676364,
	},
	TZS: {
		code: "TZS",
		alphaCode: "TZS",
		numericCode: "834",
		name: "Tanzanian shilling",
		rate: 28.548919026532,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.035027595933515,
	},
	JOD: {
		code: "JOD",
		alphaCode: "JOD",
		numericCode: "400",
		name: "Jordanian Dinar",
		rate: 0.0086533403218386,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 115.5623103689,
	},
	PHP: {
		code: "PHP",
		alphaCode: "PHP",
		numericCode: "608",
		name: "Philippine Peso",
		rate: 0.67246596065586,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 1.487064117007,
	},
	XOF: {
		code: "XOF",
		alphaCode: "XOF",
		numericCode: "952",
		name: "West African CFA Franc",
		rate: 7.327309219134,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.13647574711173,
	},
	AMD: {
		code: "AMD",
		alphaCode: "AMD",
		numericCode: "051",
		name: "Armenia Dram",
		rate: 4.7392143435489,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.21100543835102,
	},
	UYU: {
		code: "UYU",
		alphaCode: "UYU",
		numericCode: "858",
		name: "Uruguayan Peso",
		rate: 0.47183835352997,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.1193698912322,
	},
	JMD: {
		code: "JMD",
		alphaCode: "JMD",
		numericCode: "388",
		name: "Jamaican Dollar",
		rate: 1.8531397973095,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.53962469612485,
	},
	SSP: {
		code: "SSP",
		alphaCode: "SSP",
		numericCode: "728",
		name: "South Sudanese pound",
		rate: 10.299293572863,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.097094037850797,
	},
	MRU: {
		code: "MRU",
		alphaCode: "MRU",
		numericCode: "929",
		name: "Mauritanian ouguiya",
		rate: 0.41825886188269,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.3908638671725,
	},
	MNT: {
		code: "MNT",
		alphaCode: "MNT",
		numericCode: "496",
		name: "Mongolian togrog",
		rate: 42.823378539799,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.023351730622343,
	},
	NZD: {
		code: "NZD",
		alphaCode: "NZD",
		numericCode: "554",
		name: "New Zealand Dollar",
		rate: 0.019637759470462,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 50.922306157387,
	},
	TRY: {
		code: "TRY",
		alphaCode: "TRY",
		numericCode: "949",
		name: "Turkish Lira",
		rate: 0.23552860973785,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.2457687034838,
	},
	NGN: {
		code: "NGN",
		alphaCode: "NGN",
		numericCode: "566",
		name: "Nigerian Naira",
		rate: 5.6132255478623,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.17815068920237,
	},
	KGS: {
		code: "KGS",
		alphaCode: "KGS",
		numericCode: "417",
		name: "Kyrgyzstan Som",
		rate: 1.0658908596521,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.93818235792583,
	},
	MGA: {
		code: "MGA",
		alphaCode: "MGA",
		numericCode: "969",
		name: "Malagasy ariary",
		rate: 53.463273413134,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.018704428968884,
	},
	SRD: {
		code: "SRD",
		alphaCode: "SRD",
		numericCode: "968",
		name: "Surinamese dollar",
		rate: 0.44774916856746,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.2333933152784,
	},
	GHS: {
		code: "GHS",
		alphaCode: "GHS",
		numericCode: "936",
		name: "Ghanaian Cedi",
		rate: 0.13290767816334,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 7.5240197844026,
	},
	CUP: {
		code: "CUP",
		alphaCode: "CUP",
		numericCode: "192",
		name: "Cuban peso",
		rate: 0.012191423759603,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 82.024874183566,
	},
	NOK: {
		code: "NOK",
		alphaCode: "NOK",
		numericCode: "578",
		name: "Norwegian Krone",
		rate: 0.12758766400807,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 7.8377483260196,
	},
	QAR: {
		code: "QAR",
		alphaCode: "QAR",
		numericCode: "634",
		name: "Qatari Rial",
		rate: 0.044646179591127,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 22.398333052415,
	},
	CZK: {
		code: "CZK",
		alphaCode: "CZK",
		numericCode: "203",
		name: "Czech Koruna",
		rate: 0.26139642696709,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 3.825606997015,
	},
	BYN: {
		code: "BYN",
		alphaCode: "BYN",
		numericCode: "933",
		name: "Belarussian Ruble",
		rate: 0.033865315220553,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 29.528737396576,
	},
	RSD: {
		code: "RSD",
		alphaCode: "RSD",
		numericCode: "941",
		name: "Serbian Dinar",
		rate: 1.3142512988723,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.76088948959612,
	},
	NIO: {
		code: "NIO",
		alphaCode: "NIO",
		numericCode: "558",
		name: "Nicaraguan C\u00f3rdoba",
		rate: 0.44583243411297,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.2429951781988,
	},
	SBD: {
		code: "SBD",
		alphaCode: "SBD",
		numericCode: "090",
		name: "Solomon Islands dollar",
		rate: 0.10362831503362,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 9.6498722349731,
	},
	MWK: {
		code: "MWK",
		alphaCode: "MWK",
		numericCode: "454",
		name: "Malawian kwacha",
		rate: 12.410206936223,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.080578833627762,
	},
	YER: {
		code: "YER",
		alphaCode: "YER",
		numericCode: "886",
		name: "Yemeni rial",
		rate: 3.0514637154641,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.32771158147227,
	},
	VES: {
		code: "VES",
		alphaCode: "VES",
		numericCode: "928",
		name: "Venezuelan Bolivar",
		rate: 0.29760355683502,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 3.3601748938584,
	},
	BDT: {
		code: "BDT",
		alphaCode: "BDT",
		numericCode: "050",
		name: "Bangladeshi taka",
		rate: 1.2983735525706,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.77019436973294,
	},
	RON: {
		code: "RON",
		alphaCode: "RON",
		numericCode: "946",
		name: "Romanian New Leu",
		rate: 0.055120465499581,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 18.142081909805,
	},
	DZD: {
		code: "DZD",
		alphaCode: "DZD",
		numericCode: "012",
		name: "Algerian Dinar",
		rate: 1.6518775494108,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.60537174826105,
	},
	ARS: {
		code: "ARS",
		alphaCode: "ARS",
		numericCode: "032",
		name: "Argentine Peso",
		rate: 2.6077827218335,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.38346753033815,
	},
	STN: {
		code: "STN",
		alphaCode: "STN",
		numericCode: "930",
		name: "S\u00e3o Tom\u00e9 and Pr\u00edncipe Dobra",
		rate: 0.27625054803393,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 3.61990232098,
	},
	BIF: {
		code: "BIF",
		alphaCode: "BIF",
		numericCode: "108",
		name: "Burundian franc",
		rate: 25.376816912473,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.039406045425204,
	},
	MMK: {
		code: "MMK",
		alphaCode: "MMK",
		numericCode: "104",
		name: "Myanma Kyat",
		rate: 25.613983425673,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.039041174634231,
	},
	MUR: {
		code: "MUR",
		alphaCode: "MUR",
		numericCode: "480",
		name: "Mauritian Rupee",
		rate: 0.55103945295657,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 1.8147520919501,
	},
	AED: {
		code: "AED",
		alphaCode: "AED",
		numericCode: "784",
		name: "U.A.E Dirham",
		rate: 0.044776100454343,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 22.333342784499,
	},
	IDR: {
		code: "IDR",
		alphaCode: "IDR",
		numericCode: "360",
		name: "Indonesian Rupiah",
		rate: 181.91950493732,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.0054969366827629,
	},
	MXN: {
		code: "MXN",
		alphaCode: "MXN",
		numericCode: "484",
		name: "Mexican Peso",
		rate: 0.220519897151,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.5347381933306,
	},
	UAH: {
		code: "UAH",
		alphaCode: "UAH",
		numericCode: "980",
		name: "Ukrainian Hryvnia",
		rate: 0.44653920306652,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.2394450322227,
	},
	CRC: {
		code: "CRC",
		alphaCode: "CRC",
		numericCode: "188",
		name: "Costa Rican Col\u00f3n",
		rate: 6.5574341816691,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.15249867132414,
	},
	BZD: {
		code: "BZD",
		alphaCode: "BZD",
		numericCode: "084",
		name: "Belize dollar",
		rate: 0.024580235215669,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 40.683093193614,
	},
	GNF: {
		code: "GNF",
		alphaCode: "GNF",
		numericCode: "324",
		name: "Guinean franc",
		rate: 104.7751072523,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.0095442517428494,
	},
	SZL: {
		code: "SZL",
		alphaCode: "SZL",
		numericCode: "748",
		name: "Swazi lilangeni",
		rate: 0.22445357027756,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.4552643950524,
	},
	SOS: {
		code: "SOS",
		alphaCode: "SOS",
		numericCode: "706",
		name: "Somali shilling",
		rate: 6.9320058978159,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.14425838851566,
	},
	NPR: {
		code: "NPR",
		alphaCode: "NPR",
		numericCode: "524",
		name: "Nepalese Rupee",
		rate: 1.6011393816295,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.62455524576649,
	},
	XAF: {
		code: "XAF",
		alphaCode: "XAF",
		numericCode: "950",
		name: "Central African CFA Franc",
		rate: 7.3326315616141,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.13637668708666,
	},
	AZN: {
		code: "AZN",
		alphaCode: "AZN",
		numericCode: "944",
		name: "Azerbaijan Manat",
		rate: 0.020693675592119,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 48.323943010919,
	},
	PYG: {
		code: "PYG",
		alphaCode: "PYG",
		numericCode: "600",
		name: "Paraguayan Guaran\u00ed",
		rate: 87.24453535618,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.01146203594205,
	},
	GYD: {
		code: "GYD",
		alphaCode: "GYD",
		numericCode: "328",
		name: "Guyanese dollar",
		rate: 2.579159400911,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.3877232247246,
	},
	RWF: {
		code: "RWF",
		alphaCode: "RWF",
		numericCode: "646",
		name: "Rwandan franc",
		rate: 13.455614548939,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.074318419003674,
	},
	ERN: {
		code: "ERN",
		alphaCode: "ERN",
		numericCode: "232",
		name: "Eritrean nakfa",
		rate: 0.18387439373021,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 5.4384951580982,
	},
	WST: {
		code: "WST",
		alphaCode: "WST",
		numericCode: "882",
		name: "Samoan tala",
		rate: 0.033146548888857,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 30.169053295807,
	},
	BRL: {
		code: "BRL",
		alphaCode: "BRL",
		numericCode: "986",
		name: "Brazilian Real",
		rate: 0.060525799966072,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 16.521879934847,
	},
	LKR: {
		code: "LKR",
		alphaCode: "LKR",
		numericCode: "144",
		name: "Sri Lanka Rupee",
		rate: 3.9022331298924,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.25626352058252,
	},
	TND: {
		code: "TND",
		alphaCode: "TND",
		numericCode: "788",
		name: "Tunisian Dinar",
		rate: 0.037246722494593,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 26.847999851401,
	},
	VND: {
		code: "VND",
		alphaCode: "VND",
		numericCode: "704",
		name: "Vietnamese Dong",
		rate: 286.08582747892,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.0034954545243024,
	},
	IQD: {
		code: "IQD",
		alphaCode: "IQD",
		numericCode: "368",
		name: "Iraqi dinar",
		rate: 15.971428828504,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.062611805790057,
	},
	AFN: {
		code: "AFN",
		alphaCode: "AFN",
		numericCode: "971",
		name: "Afghan afghani",
		rate: 1.0523458218862,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.95025796577746,
	},
	NAD: {
		code: "NAD",
		alphaCode: "NAD",
		numericCode: "516",
		name: "Namibian dollar",
		rate: 0.22445357027756,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.4552643950524,
	},
	SYP: {
		code: "SYP",
		alphaCode: "SYP",
		numericCode: "760",
		name: "Syrian pound",
		rate: 30.631310767292,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.032646333929262,
	},
	MOP: {
		code: "MOP",
		alphaCode: "MOP",
		numericCode: "446",
		name: "Macanese pataca",
		rate: 0.098660910012118,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 10.135726498744,
	},
	BAM: {
		code: "BAM",
		alphaCode: "BAM",
		numericCode: "977",
		name: "Bosnia and Herzegovina convertible mark",
		rate: 0.021879507690921,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 45.704867500969,
	},
	DKK: {
		code: "DKK",
		alphaCode: "DKK",
		numericCode: "208",
		name: "Danish Krone",
		rate: 0.08315652885745,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 12.02551397635,
	},
	PKR: {
		code: "PKR",
		alphaCode: "PKR",
		numericCode: "586",
		name: "Pakistani Rupee",
		rate: 3.4677009965827,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.28837549747959,
	},
	BGN: {
		code: "BGN",
		alphaCode: "BGN",
		numericCode: "975",
		name: "Bulgarian Lev",
		rate: 0.021830478023871,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 45.807517311648,
	},
	RUB: {
		code: "RUB",
		alphaCode: "RUB",
		numericCode: "643",
		name: "Russian Rouble",
		rate: 1.0083450963983,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.99172396788743,
	},
	TMT: {
		code: "TMT",
		alphaCode: "TMT",
		numericCode: "934",
		name: "New Turkmenistan Manat",
		rate: 0.04266629310359,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 23.437705206124,
	},
	SVC: {
		code: "SVC",
		alphaCode: "SVC",
		numericCode: "222",
		name: "Salvadoran colon",
		rate: 0.10677307423497,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 9.3656570925304,
	},
	XCD: {
		code: "XCD",
		alphaCode: "XCD",
		numericCode: "951",
		name: "East Caribbean Dollar",
		rate: 0.033033004506722,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 30.272753415346,
	},
	LAK: {
		code: "LAK",
		alphaCode: "LAK",
		numericCode: "418",
		name: "Lao kip",
		rate: 209.12943094134,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.0047817277343451,
	},
	GTQ: {
		code: "GTQ",
		alphaCode: "GTQ",
		numericCode: "320",
		name: "Guatemalan Quetzal",
		rate: 0.095093550592392,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 10.515960270391,
	},
	SAR: {
		code: "SAR",
		alphaCode: "SAR",
		numericCode: "682",
		name: "Saudi Riyal",
		rate: 0.045731312199611,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 21.866855594152,
	},
	PLN: {
		code: "PLN",
		alphaCode: "PLN",
		numericCode: "985",
		name: "Polish Zloty",
		rate: 0.05205263764873,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 19.211322330068,
	},
	GIP: {
		code: "GIP",
		alphaCode: "GIP",
		numericCode: "292",
		name: "Gibraltar pound",
		rate: 0.009810787854941,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 101.92861315377,
	},
	GEL: {
		code: "GEL",
		alphaCode: "GEL",
		numericCode: "981",
		name: "Georgian lari",
		rate: 0.03108458508431,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 32.170286245987,
	},
	MKD: {
		code: "MKD",
		alphaCode: "MKD",
		numericCode: "807",
		name: "Macedonian denar",
		rate: 0.68751704234689,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 1.4545093989037,
	},
	AWG: {
		code: "AWG",
		alphaCode: "AWG",
		numericCode: "533",
		name: "Aruban florin",
		rate: 0.022075216543471,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 45.299668885728,
	},
	AOA: {
		code: "AOA",
		alphaCode: "AOA",
		numericCode: "973",
		name: "Angolan kwanza",
		rate: 6.2738829282401,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.15939092447817,
	},
	MVR: {
		code: "MVR",
		alphaCode: "MVR",
		numericCode: "462",
		name: "Maldivian rufiyaa",
		rate: 0.18853449784357,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 5.3040690772131,
	},
	BHD: {
		code: "BHD",
		alphaCode: "BHD",
		numericCode: "048",
		name: "Bahrain Dinar",
		rate: 0.0045928701650489,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 217.72877613869,
	},
	EGP: {
		code: "EGP",
		alphaCode: "EGP",
		numericCode: "818",
		name: "Egyptian Pound",
		rate: 0.37674174671541,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.6543381738775,
	},
	KRW: {
		code: "KRW",
		alphaCode: "KRW",
		numericCode: "410",
		name: "South Korean Won",
		rate: 16.133055748068,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.061984537561632,
	},
	MRO: {
		code: "MRO",
		alphaCode: "MRO",
		numericCode: "478",
		name: "Mauritanian Ouguiya",
		rate: 0.41823133779782,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.3910212115272,
	},
	COP: {
		code: "COP",
		alphaCode: "COP",
		numericCode: "170",
		name: "Colombian Peso",
		rate: 54.647410389997,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.018299128775973,
	},
	BBD: {
		code: "BBD",
		alphaCode: "BBD",
		numericCode: "052",
		name: "Barbadian Dollar",
		rate: 0.024620911727847,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 40.61588015317,
	},
	DJF: {
		code: "DJF",
		alphaCode: "DJF",
		numericCode: "262",
		name: "Djiboutian franc",
		rate: 2.1712558188882,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.46056295683852,
	},
	HNL: {
		code: "HNL",
		alphaCode: "HNL",
		numericCode: "340",
		name: "Honduran Lempira",
		rate: 0.29996099253684,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 3.3337668059529,
	},
	KES: {
		code: "KES",
		alphaCode: "KES",
		numericCode: "404",
		name: "Kenyan shilling",
		rate: 1.6334649237552,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.61219557607706,
	},
	HKD: {
		code: "HKD",
		alphaCode: "HKD",
		numericCode: "344",
		name: "Hong Kong Dollar",
		rate: 0.095702303415236,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 10.449069294196,
	},
	MAD: {
		code: "MAD",
		alphaCode: "MAD",
		numericCode: "504",
		name: "Moroccan Dirham",
		rate: 0.12452852116493,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 8.030288890009,
	},
	ZAR: {
		code: "ZAR",
		alphaCode: "ZAR",
		numericCode: "710",
		name: "South African Rand",
		rate: 0.22433289394342,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.4576610341069,
	},
	MDL: {
		code: "MDL",
		alphaCode: "MDL",
		numericCode: "498",
		name: "Moldova Lei",
		rate: 0.22061859589678,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.5327094750793,
	},
	PAB: {
		code: "PAB",
		alphaCode: "PAB",
		numericCode: "590",
		name: "Panamanian Balboa",
		rate: 0.012191423759603,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 82.024874183566,
	},
	FJD: {
		code: "FJD",
		alphaCode: "FJD",
		numericCode: "242",
		name: "Fiji Dollar",
		rate: 0.027036982504878,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 36.98637596927,
	},
	CDF: {
		code: "CDF",
		alphaCode: "CDF",
		numericCode: "976",
		name: "Congolese franc",
		rate: 25.426381008005,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.039329230521842,
	},
	MZN: {
		code: "MZN",
		alphaCode: "MZN",
		numericCode: "943",
		name: "Mozambican metical",
		rate: 0.77953934587419,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 1.2828088861616,
	},
	UGX: {
		code: "UGX",
		alphaCode: "UGX",
		numericCode: "800",
		name: "Ugandan shilling",
		rate: 45.478802012572,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.02198826608765,
	},
	KWD: {
		code: "KWD",
		alphaCode: "KWD",
		numericCode: "414",
		name: "Kuwaiti Dinar",
		rate: 0.0037390220015178,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 267.44961639543,
	},
	THB: {
		code: "THB",
		alphaCode: "THB",
		numericCode: "764",
		name: "Thai Baht",
		rate: 0.41704371741579,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.3978301512285,
	},
	TWD: {
		code: "TWD",
		alphaCode: "TWD",
		numericCode: "901",
		name: "New Taiwan Dollar ",
		rate: 0.37178511026036,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 2.6897257915996,
	},
	IRR: {
		code: "IRR",
		alphaCode: "IRR",
		numericCode: "364",
		name: "Iranian rial",
		rate: 511.90231145724,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.0019534977233318,
	},
	BOB: {
		code: "BOB",
		alphaCode: "BOB",
		numericCode: "068",
		name: "Bolivian Boliviano",
		rate: 0.084042684942401,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 11.898715523967,
	},
	LRD: {
		code: "LRD",
		alphaCode: "LRD",
		numericCode: "430",
		name: "Liberian dollar",
		rate: 2.0028164732459,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.49929687185932,
	},
	SDG: {
		code: "SDG",
		alphaCode: "SDG",
		numericCode: "938",
		name: "Sudanese pound",
		rate: 6.9430971072524,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.14402794380557,
	},
	TOP: {
		code: "TOP",
		alphaCode: "TOP",
		numericCode: "776",
		name: "Tongan pa\u02bbanga",
		rate: 0.028960140317221,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 34.530219434239,
	},
	VUV: {
		code: "VUV",
		alphaCode: "VUV",
		numericCode: "548",
		name: "Vanuatu vatu",
		rate: 1.4633888349932,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.68334538031696,
	},
	OMR: {
		code: "OMR",
		alphaCode: "OMR",
		numericCode: "512",
		name: "Omani Rial",
		rate: 0.0046934363865773,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 213.06350350457,
	},
	ILS: {
		code: "ILS",
		alphaCode: "ILS",
		numericCode: "376",
		name: "Israeli New Sheqel",
		rate: 0.044770078167517,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 22.336346973938,
	},
	PEN: {
		code: "PEN",
		alphaCode: "PEN",
		numericCode: "604",
		name: "Peruvian Nuevo Sol",
		rate: 0.046050668324834,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 21.715211448098,
	},
	UZS: {
		code: "UZS",
		alphaCode: "UZS",
		numericCode: "860",
		name: "Uzbekistan Sum",
		rate: 139.37130977707,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.0071750778664528,
	},
	ETB: {
		code: "ETB",
		alphaCode: "ETB",
		numericCode: "230",
		name: "Ethiopian birr",
		rate: 0.66419934061726,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 1.5055721059143,
	},
	TTD: {
		code: "TTD",
		alphaCode: "TTD",
		numericCode: "780",
		name: "Trinidad Tobago Dollar",
		rate: 0.08280049022801,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 12.077223181243,
	},
	PGK: {
		code: "PGK",
		alphaCode: "PGK",
		numericCode: "598",
		name: "Papua New Guinean kina",
		rate: 0.04352129402791,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 22.977257968449,
	},
	BWP: {
		code: "BWP",
		alphaCode: "BWP",
		numericCode: "072",
		name: "Botswana Pula",
		rate: 0.16131731197148,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 6.1989627013917,
	},
	SEK: {
		code: "SEK",
		alphaCode: "SEK",
		numericCode: "752",
		name: "Swedish Krona",
		rate: 0.1261988168899,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 7.9240045560207,
	},
	SGD: {
		code: "SGD",
		alphaCode: "SGD",
		numericCode: "702",
		name: "Singapore Dollar",
		rate: 0.016204383857535,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 61.711695353046,
	},
	HUF: {
		code: "HUF",
		alphaCode: "HUF",
		numericCode: "348",
		name: "Hungarian Forint",
		rate: 4.198843474163,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.23816081884294,
	},
	HRK: {
		code: "HRK",
		alphaCode: "HRK",
		numericCode: "191",
		name: "Croatian Kuna",
		rate: 0.091808385689926,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 10.892251208701,
	},
	TJS: {
		code: "TJS",
		alphaCode: "TJS",
		numericCode: "972",
		name: "Tajikistan Ruble",
		rate: 0.13306521880065,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 7.5151118302232,
	},
	GMD: {
		code: "GMD",
		alphaCode: "GMD",
		numericCode: "270",
		name: "Gambian dalasi",
		rate: 0.77837411516285,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 1.2847292587457,
	},
	CVE: {
		code: "CVE",
		alphaCode: "CVE",
		numericCode: "132",
		name: "Cape Verde escudo",
		rate: 1.2368937839524,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.80847685789525,
	},
	ZMW: {
		code: "ZMW",
		alphaCode: "ZMW",
		numericCode: "967",
		name: "Zambian kwacha",
		rate: 0.23143657024176,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 4.3208383141671,
	},
	KHR: {
		code: "KHR",
		alphaCode: "KHR",
		numericCode: "116",
		name: "Cambodian riel",
		rate: 49.546363752991,
		date: "Wed, 12 Apr 2023 23:55:01 GMT",
		inverseRate: 0.020183115858621,
	},
};
