import { SignUpButton } from "components/Button";
import { forgotpassword } from "queries/authQueries";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo1.png";

export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation(forgotpassword);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log("error");
    }
  };
  if (mutation.isLoading || mutation.isSuccess || mutation.isError) {
    return (
      <div className="dashboard-main-colors flex min-h-full">
        <div className=" text-left max-w-[600px] mx-auto my-auto py-10 px-6 my-20">
          <h1>Reset Password</h1>
          <p>
            If we found an account associated with that username, we've sent
            password reset instructions to the primary email address on the
            account.
          </p>
          <p className="mb-4">
            Still having trouble logging in? Contact Support.
          </p>
          <Link to="/login" className="text-blue-600">
            Login Page
          </Link>
          <br />
          <Link to="/" className="text-blue-600">
            Home Page
          </Link>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="dashboard-main-colors min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={Logo} alt="Workflow" />
            <h2 className="mt-6 mb-6 text-center text-3xl font-extrabold text-gray-900">
              Enter your email
            </h2>
            <p className="text-center">
              We will send an recovery email to your email account
            </p>
          </div>
          {mutation.isLoading && <div>API Processing</div>}
          {/* {mutation.isSuccess && (
            <div className="text-green-500">API Success</div>
          )}
          {mutation.isError && (
            <div className="text-red-500">{mutation.error.message}</div>
          )} */}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="hidden"
              name="remember"
              defaultValue="true"
              className="input"
            />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  className="input"
                  placeholder="Email address"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Enter a valid email",
                    },
                  })}
                />
              </div>
              {errors.email && (
                <span className="text-red-500">{errors.email.message}</span>
              )}
            </div>

            <div>
              <SignUpButton type="submit">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                Submit Email
              </SignUpButton>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                Remember your password?{" "}
                <Link
                  to="/login"
                  className="font-medium text-cyan-700 hover:text-cyan-500"
                >
                  Login Here
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
