import { Input, Textarea } from "components/Input";
import { errorToast } from "helpers/Toast";
import { createMedicine } from "queries/orderQueries";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { Button } from "components/Button";
import { useForm } from "react-hook-form";
export default function MedicineAddItemsInputs() {
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		watch,
	} = useForm();

	const location = useLocation();
	const navigate = useNavigate();
	// console.log("location.state");
	// console.log(location.state);
	const queryClient = useQueryClient();
	const { orderId } = useParams();
	const [selectedItemType, setSelectedItemType] = useState(
		location.state?.itemType
	);

	const resetHandler = () => {
		reset();
		setSelectedItemType("");
	};

	const mutationSuccessHandler = (data) => {
		console.log(data);
		reset();
		setSelectedItemType("");
		queryClient.invalidateQueries("orders");
		queryClient.invalidateQueries("allOrders");
		// setAddItem(false);
		navigate(`/user/medicine/${data._id}`);
	};

	const createMedicineMutation = useMutation(createMedicine, {
		onSuccess: (data) => {
			mutationSuccessHandler(data);
		},
		onError: (error) => {
			console.log(error);
			errorToast(error);
		},
	});

	// let existingOrderData = data && data.filter((item) => !item.isMedicine);
	const onSubmitHandler = (role, formData) => {
		formData["orderType"] = "medicine";
		createMedicineMutation.mutate(formData);
	};

	const onSubmit = async (formData) => {
		console.log("formData");
		console.log(formData);
		// tertiary function to facilitate push and put request based on condition
		formData = { ...formData, itemType: "default" };
		onSubmitHandler(selectedItemType, formData);
	};

	const convertToWeBuyButtonHandler = () => {
		reset();
		setSelectedItemType("weBuy");
	};

	return (
		<div className="container ">
			<h3 className="h3 mb-4 ">Medicine</h3>

			{orderId !== null && orderId !== undefined ? (
				<Outlet />
			) : (
				<div className="flex flex-col justify-center   ">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="bg-teal-100 pl-4 py-8 inline-block">
							<div className="flex flex-wrap">
								<div className="mr-6">
									<Input
										title="Patient Name"
										inputName="name"
										register={register}
										errors={errors}
										required
										maxLength={100}
									/>
								</div>

								<div className="mr-6">
									<Textarea
										title="Description"
										inputName="description"
										register={register}
										errors={errors}
										required
										maxLength={4000}
									/>
								</div>
							</div>
							<div className="flex flex-wrap">
								<div className="flex flex-col mb-4">
									<Input
										title="Prescription"
										inputName="prescriptionImages"
										inputType="file"
										register={register}
										errors={errors}
										multiple
									/>
								</div>

								<div className="flex flex-col mb-4">
									<Input
										title="Passport"
										inputName="passportImage"
										inputType="file"
										register={register}
										errors={errors}
										required
									/>
								</div>

								<div className="flex flex-col mb-4">
									<Input
										title="Visa"
										inputName="visaImage"
										inputType="file"
										register={register}
										errors={errors}
										required
									/>
								</div>
							</div>
							<div className="">
								<i>
									(*Note: Please add front page of passport, image of visa and
									prescription )
								</i>
							</div>
							<div className="flex">
								{/* <CancelButton
            className="bg-gray-700 font-bold py-2 text-white px-6 rounded-xl mb-8 mr-6"
            onClick={() => {
              reset();
              setSelectedItemType("");
              setAddItem(false);
            }}
          >
            cancel
          </CancelButton> */}

								<Button
									type="submit"
									disabled={createMedicineMutation.isLoading}
								>
									{createMedicineMutation.isLoading ? "Processing..." : "Save"}
								</Button>
							</div>
						</div>
					</form>
				</div>
			)}
		</div>
	);
}
