import "@reach/dialog/styles.css";
import { Button } from "components/Button";
import dayjs from "dayjs";
import { Fragment, useState } from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import InnerTable from "./InnerTable";

const OrderTableHeader = ({ hideActions, completed }) => {
	return (
		<thead className="text-xs text-gray-700 uppercase bg-[#115383] dark:bg-[#115383] dark:text-white ">
			<tr className="">
				<th className="px-2"></th>
				<th className="px-2 text-center capitalize text-white py-2 w-12">Id</th>
				<th className="px-2 text-center capitalize text-white py-2 ">
					Services
				</th>
				<th className="px-2 text-center capitalize text-white py-2 ">
					Start Date
				</th>
				{completed ? (
					<>
						<th className="px-2 text-center capitalize text-white py-2 ">
							End Date
						</th>
						<th className="px-2 text-center capitalize text-white py-2 ">
							Total Value
						</th>
					</>
				) : null}
				<th className="px-2 text-center capitalize text-white py-2 ">Status</th>
			</tr>
		</thead>
	);
};

const OrderTableBody = ({
	editOrderNavigator,
	setInnerdetails,
	innerDetails,
	item,
	index,
	completed,
}) => {
	const year = new Date(Date.now()).getFullYear();
	const date = new Date(Date.now()).getDate();
	const month = new Date(Date.now()).getMonth();
	const day = dayjs().format("YYYYMMDDHH");
	return (
		<Fragment key={item._id}>
			<tr className="even:bg-green-100">
				<td className="px-2 text-center capitalize">
					{innerDetails === item._id ? (
						<AiFillCaretUp size={20} onClick={() => setInnerdetails(false)} />
					) : (
						<AiFillCaretDown
							size={20}
							onClick={() => setInnerdetails(item._id)}
						/>
					)}
				</td>

				<td className="px-2 text-center capitalize min-w-16">
					{item.uniqueOrderId || `${day}${index + 1}`}
				</td>
				<td className="px-2 text-center capitalize">
					{item.orderType || "- "}
				</td>
				<td className="px-2 text-center capitalize">
					{new Date(item.createdAt).toLocaleDateString("en-IN")}
				</td>
				{completed ? (
					<>
						<td className="px-2 text-center capitalize">
							{item.completedDate || "-"}
						</td>
						<td className="px-2 text-center capitalize">
							{item.totalValue || "-"}
						</td>
					</>
				) : null}
				<td className="px-2 text-center capitalize">{item.orderStatus}</td>

				<td className="px-2 text-center capitalize ">
					{item.orderStatus === "draft" && (
						// <DraftOrderActionButtons order={item} />
						<Button onClick={() => editOrderNavigator(item)}>Edit Order</Button>
					)}
					{item.orderStatus === "review" &&
						item.isReviewedEmailSent === true && (
							<Link
								to={`/user/actions/${item._id}`}
								className="underline text-blue-500"
							>
								Click to Approve
							</Link>
						)}
					{item.orderStatus === "readyToShip" && (
						<Link
							to={`/user/actions/${item._id}`}
							className="underline text-blue-500"
						>
							Click to Approve
						</Link>
					)}
				</td>
			</tr>
			{innerDetails === item._id && (
				<tr>
					<td colSpan="10">
						<InnerTable data={item} hideActions={true} />
					</td>
				</tr>
			)}
		</Fragment>
	);
};

const OrdersTable = ({ data, hideActions, completed = false }) => {
	const [innerDetails, setInnerdetails] = useState(false);
	console.log("innerdetails ", innerDetails);
	const navigate = useNavigate();
	const editOrderNavigator = (data) => {
		if (data.orderType === "buyYourself") {
			navigate(`/user/buy-yourself/${data._id}`);
		}
		if (data.orderType === "weBuy") {
			navigate(`/user/we-buy-4-u/${data._id}`);
		}
		if (data.orderType === "medicine") {
			navigate(`/user/medicine/${data._id}`);
		}
		if (data.orderType === "customized") {
			navigate(`/user/customized-buy/${data._id}`);
		}
		if (data.orderType === "inhouse") {
			navigate(`/user/inhouse/${data._id}`);
		}
	};
	return (
		<div className="overflow-x-auto">
			<table className="text-sm  text-gray-500 dark:text-gray-500 overflow-x-scroll">
				<OrderTableHeader hideActions={hideActions} completed={completed} />
				<tbody>
					{data.length !== 0 ? (
						data.length >= 1 ? (
							data.map((item, index) => (
								<OrderTableBody
									editOrderNavigator={editOrderNavigator}
									innerDetails={innerDetails}
									setInnerdetails={setInnerdetails}
									item={item}
									index={index}
									completed={completed}
									hideActions={hideActions}
								/>
							))
						) : (
							<OrderTableBody
								editOrderNavigator={editOrderNavigator}
								innerDetails={innerDetails}
								setInnerdetails={setInnerdetails}
								item={data}
								index={0}
								completed={completed}
								hideActions={hideActions}
							/>
						)
					) : (
						<tr>
							<td colSpan={10} className=" px-2 md:text-center">
								No Orders History
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default OrdersTable;
