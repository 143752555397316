import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";

const saveToken = async (accessToken, refreshToken) => {
	localStorage.setItem("accessToken", accessToken);
	localStorage.setItem("refreshToken", refreshToken);
	localStorage.setItem("authState", true);
	useAuthStore.setState({ isLoggedIn: true });
	return null;
};
const saveUser = async (user) => {
	localStorage.setItem("userRole", user.role);
	localStorage.setItem("name", user.name);
	localStorage.setItem("email", user.email);
	localStorage.setItem("user_id", user.id);
	localStorage.setItem("isRegistrationComplete", user.isRegistrationComplete);
	localStorage.setItem("isUserVerified", user.isUserVerified);
	useUserStore.setState({
		id: user.id,
		name: user.name,
		role: user.role,
		email: user.email,
		appliedPosts: user.appliedPosts,
		isRegistrationComplete: user.isRegistrationComplete,
		isUserVerified: user.isUserVerified,
	});
};
const logout = async () => {
	localStorage.removeItem("accessToken");
	localStorage.removeItem("refreshToken");
	localStorage.removeItem("name");
	localStorage.removeItem("email");
	localStorage.removeItem("userRole");
	localStorage.removeItem("user_id");
	localStorage.removeItem("authState");
	localStorage.removeItem("isRegistrationComplete");
	localStorage.removeItem("isUserVerified");
	useAuthStore.setState({ isLoggedIn: false });
};

export { saveToken, saveUser, logout };
