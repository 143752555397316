import { useForm } from "react-hook-form";

import { NavLink, Outlet } from "react-router-dom";
import useUserStore from "stores/UserStore";

export default function OrdersDashboard() {
	const role = useUserStore((state) => state.role);
	const name = useUserStore((state) => state.name);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	return (
		<div className="container overflow-x-none ">
			<h1 className="h3 font-bold  mb-6 capitalize">{name}</h1>
			<div className="flex flex-row flex-wrap justify-start  items-center">
				{localStorage.getItem("userRole") !== "user" && (
					<>
						{/* <NavLink
              to="/user/orders"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-xl mr-4 hover:text-cyan-500 text-blue-700 underline"
                  : "font-bold text-xl mr-4 hover:text-cyan-500"
              }
            >
              Yet to Order
            </NavLink> */}

						<NavLink
							to="/orders/review"
							className={({ isActive }) =>
								isActive
									? "font-bold text-xl mr-4 hover:text-cyan-500 text-blue-700 underline"
									: "font-bold text-xl mr-4 hover:text-cyan-500"
							}
						>
							In Review
						</NavLink>
						<NavLink
							to="/orders/procuring"
							className={({ isActive }) =>
								isActive
									? "font-bold text-xl mr-4 hover:text-cyan-500 text-blue-700 underline"
									: "font-bold text-xl mr-4 hover:text-cyan-500"
							}
						>
							Procuring
						</NavLink>
						<NavLink
							to="/orders/ready-to-be-shipped"
							className={({ isActive }) =>
								isActive
									? "font-bold text-xl mr-4 hover:text-cyan-500 text-blue-700 underline"
									: "font-bold text-xl mr-4 hover:text-cyan-500"
							}
						>
							Ready to be Shipped
						</NavLink>
						<NavLink
							to="/orders/shipped"
							className={({ isActive }) =>
								isActive
									? "font-bold text-xl hover:text-cyan-500 text-blue-700 underline"
									: "font-bold text-xl hover:text-cyan-500"
							}
						>
							Shipped
						</NavLink>
					</>
				)}
			</div>
			<Outlet />
		</div>
	);
}
