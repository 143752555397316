import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import VisuallyHidden from "@reach/visually-hidden";
import AboutImage from "assets/about-us.jpg";
import React from "react";
export default function AboutUs() {
	const [showDialog, setShowDialog] = React.useState(false);
	const open = () => setShowDialog(true);
	const close = () => setShowDialog(false);
	return (
		<section
			className="skew-y-3 about-us-colors mx-auto max-w-[1920px] py-16 -translate-y-14 overflow-hidden z-50"
			id="about"
		>
			<section className="about-us-colors grid grid-cols-3 relative -skew-y-3 z-50">
				<div className="flex flex-col col-span-3 lg:col-span-2 pt-[500px] px-12  lg:py-20 lg:px-32 xl:px-40 2xl:px-48 3xl:px-52">
					<h2 className="about-us-heading h2 ">About Us</h2>
					<p className="mt-12 text-justify">
						4inExpo focuses on making product and services available on the
						international market with keeping cost at its at most low, there by
						maintaining the upmost quality. 4inExpo strives to be Earth friendly
						and have laid out plans to use minimum plastic and use bio degreed
						packaging (Cardboard cases made of Cow-Dung) in its future plans.
						4inExpo aims for one-click shopping, personalised and customable
						requirement from any place (As applicable), with customisable
						services as well.
					</p>
					<button
						className="about-us-read-more mt-6 underline w-fit hover:text-cyan-500"
						onClick={open}
					>
						Read More
					</button>

					<Dialog
						isOpen={showDialog}
						onDismiss={close}
						className="container"
						aria-label="Read More Information"
					>
						<div className="flex">
							<button className="close-button ml-auto" onClick={close}>
								<VisuallyHidden>Close</VisuallyHidden>
								<span aria-hidden>×</span>
							</button>
						</div>
						<p className="py-4 text-justify">
							4inExpo focuses on making product and services available on the
							international market with keeping cost at its at most low, there
							by maintaining the upmost quality. 4inExpo strives to be Earth
							friendly and have laid out plans to use minimum plastic and use
							bio degreed packaging (Cardboard cases made of Cow-Dung) in its
							future plans. 4inExpo aims for one-click shopping, personalised
							and customable requirement from any place (As applicable), with
							customisable services as well.
						</p>
						<p className="py-4 text-justify">
							Our constant aim at 4inExpo is customer’s desire for low pricing
							for goods and services in the international market, better
							selection and convenient services. In addition, we have plans to
							empower our operations with 100% renewable energy in the coming
							years. 4inExpo is a International shopping, shipping &
							consolidation company based out of Different location in India
							like Mumbai, Delhi, Ahmedabad, Chennai, Bangalore, Calicut, to
							helps international shoppers to shop from 1000+ Indian online
							shopping sites without worrying about international shipping, as
							well as helps send couriers/ packages/ documents/ Medicine abroad
							and within India.
							<br /> We provide a Personal Locker with a Virtual Shipping
							Address in Bangalore or Mumbai, India. You can use that to shop
							from Amazon, Flipkart, Myntra & more.
						</p>
						<p className="py-4 text-justify">
							When your purchases arrive at our facility, we will store them in
							your locker and when you make 'Ship Request', we will consolidate
							them into one shipment and send it to your doorsteps wherever it
							is around the world. We help our customers buy locally from
							destinations like Mumbai, Bangalore, Chennai, Delhi, Calicut, and
							Ahmedabad. Under the medicine shipment we have allopathic and
							alternative medicines to ship for our needy customers abroad. Our
							own in-house shop helps you shop Handicraft, Jewellery, and Puja
							Items, at exiting prices and with at most quality maintained. Our
							in-House services has a large variety of services to choose from,
							like On line Indian language learning, On line Yoga classes from
							professionally qualified trainers, Robert making and coding
							classes for Kids, Indian music , Art and Fine arts, Marshal class.
							Etc.
						</p>
					</Dialog>
				</div>
				<div className="bg-[#F0FEFC]  lg:h-[calc(200%)]  w-full absolute top-0 right-0 -mt-24 lg:-mt-16 lg:w-1/3 py-28 flex justify-center lg:py-36">
					<img
						src={AboutImage}
						className="w-[300px] sm:w-[400px] lg:w-[300px] lg:h-[207px] xl:w-[400px]   xl:h-[285px]"
						alt="Container ships"
						width={300}
					/>
				</div>
			</section>
		</section>
	);
}
